import { createBrowserRouter } from "react-router-dom";
import GameTheme from "@/components/game-theme/GameTheme";
import GameTemplate from "@/components/game-template/GameTemplate";
import GameInfo from "@/components/game-info/GameInfo";
import GameSettings from "@/components/game-settings/GameSettings";
import RoomEditor from "@/components/room-editor";
import RoomPreview from "@/components/room-preview/RoomPreview";
import GameCreator from "@/components/game-creator/GameCreator";
import GameSessions from "@/components/game-sessions/GameSessions";
import GameSession from "@/components/game-sessions/GameSession";
import SessionManager from "@/components/session-manager/SessionManager";
import SessionSettings from "@/components/session-settings/SessionSettings";
import Auth from "@/components/sign-in/Auth";
import SignIn from "@/components/sign-in/SignIn";
import { UserInfoProvider } from "@/context/UserInfoContext";
import SignOut from "@/components/sign-in/SignOut";
import SignedOut from "@/components/sign-in/SignedOut";
import Error from "@/components/error/Error";
import Login from "@/components/login/Login";
import Register from "@/components/register/Register";
import Users from "@/components/users/Users";
import Layout from "@/components/app/Layout";
import ProtectedRoute from "@/components/protected-route/ProtectedRoute";
import GameEditor from "@/components/game-editor/GameEditor";
import GameSettings2 from "@/components/game-editor/GameSettings2";
import { ModalProvider } from "../components/game-customisation-template-pages/UnsavedChangesContext";
import ScoreDashboard from "@/components/score-dashboard/ScoreDashboard";
import CreateGameSessionForm from '@/components/game-sessions/CreateGameSessionForm'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout/>,
    children: [
      {
        path: "/",
        element: <GameCreator/>,
      },
      {
        path: "/sessions",
        element: <SessionManager/>,
      },
      {
        path: "/sessions/:sessionId",
        element: <SessionSettings/>,
      },
      {
        path: "/sessions/create",
        element: <CreateGameSessionForm/>,
      },
      {
        path: "games/:gameId/sessions/:sessionId",
        element: <SessionSettings/>,
      },
      {
        path: "games/:gameId/sessions/create",
        element: <CreateGameSessionForm/>,
      },
      {
        path: "games/:gameId",
        element: <GameSettings/>,
      },
      {
        path: "games/create",
        element: <GameTheme/>,
      },
      {
        path: "games/templatesetup",
        element: <GameTemplate/>,
      },
      {
        path: "games/basicinformation",
        element: <GameInfo/>,
      },
      {
        path: "games/:gameId/advancedmode",
        element: <GameSettings/>,
      },
      {
        path: "games/:gameId/edit",
        element: <ModalProvider><GameEditor/></ModalProvider>,
      },
      {
        path: "games/:gameId/settings",
        element: <GameSettings2/>,
      },
      {
        path: "games/:gameId/sessions",
        element: <GameSessions/>,
      },
      {
        path: "games/:gameId/rooms/:roomId",
        element: <RoomEditor/>,
      },
      {
        path: "/users",
        element: <Users/>,
      }
    ]
  },
  {
    path: "games/:gameId/rooms/:roomId/preview",
    element: <RoomPreview />,
  },
  {
    path: "sessions/:sessionId/play",
    element: (
      <UserInfoProvider>
        <GameSession />,
      </UserInfoProvider>
    ),
  },
  {
    path: "sessions/:sessionId/play/scoredashboard",
    element: <ScoreDashboard />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "signin",
    element: <SignIn />,
  },
  {
    path: "authorize",
    element: <Auth />,
  },
  {
    path: "register",
    element: <Register />,
  },

  {
    path: "signout",
    element: <SignOut />,
  },
  {
    path: "signedout",
    element: <SignedOut />,
  },
  {
    path: "error",
    element: <Error />,
  },
]);

export default router;

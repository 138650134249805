import {
  Input,
  Button,
  Column,
  Modal,
  Pagination,
  PaginationRef,
  Search,
  Select,
  Table,
} from "@appkit4/react-components";
import { IGame } from "escape-rooms-types/types/game";
import { IGameSession } from "escape-rooms-types/types/gameSession";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Checkbox } from '@appkit4/react-components/checkbox';
import {
  createGameSession,
  getGameSessions,
  updateGameSession,
} from "@/api/session";
import { getGames } from "@/api/game";
import { deleteUser, getUsers } from "@/api/users";
import { capitalizeFirstLetter, throttle } from "@/utils/helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { inviteUserToPlatform, resendPlatformInvite, InviteUserData } from "@/api/auth";
import { renderNotification } from "../notification/renderFunctions";

const Users = () => {
  const intl = useIntl();
  const paginationRef = useRef<PaginationRef>(null);
  const navigate = useNavigate();

  const [searchString, setSearchString] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [sortBy, setSortBy] = useState<string>("_id");

  const [totalPages, setTotalPages] = useState<number>(1);
  const [users, setUsers] = useState([]);

  const [role, setRole] = useState<string | undefined>(undefined);
  const [registered, setRegistered] = useState<boolean | undefined>(undefined);

  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  // const [showMakeAdminModal, setShowMakeAdminModal] = useState(false);
  // const [showRevokeAdminModal, setShowRevokeAdminModal] = useState(false);
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [targetUserId, setTargetUserId] = useState<string | undefined>(
    undefined
  );
  const [inviteEmail, setInviteEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["getUsers", searchString],
    queryFn: () =>
      getUsers(
        page,
        limit,
        sortBy,
        sortOrder,
        searchString,
        role === "admin" ? role : undefined,
        registered
      ),
    onSuccess: (res) => {
      setUsers(res.data.data);
      const totalPages = Math.max(Math.ceil(res.data.count / limit), 1);
      setTotalPages(totalPages);
      setPage(Math.min(page, totalPages));
      if (paginationRef.current) {
        paginationRef.current.setPageNumber(Math.min(page, totalPages));
      }
    },
  });

  const sendPlayerInvite = useMutation({
    mutationKey: "invitePlayerToPlatform",
    mutationFn: (data: InviteUserData) => inviteUserToPlatform(data),
    onSuccess: (res) => {
      renderNotification("Invitation has been sent to user.", "success");
      refetch();
    },
    onError: (err) => {
      renderNotification(err.response.data.message || err.message, "error");
    },
  });

  const resendPlayerInvite = useMutation({
    mutationKey: "resendPlayerInvite",
    mutationFn: (email: string) => resendPlatformInvite(email),
    onSuccess: (res) => {
      renderNotification("Invitation has been sent to user.", "success");
    },
    onError: (err) => {
      renderNotification(err.response.data.message || err.message, "error");
    },
  });

  const handleDeleteUser = useMutation({
    mutationKey: "deleteUser",
    mutationFn: (userId: string) => deleteUser(userId),
    onSuccess: (res) => {
      renderNotification("User has been deleted from the platform", "success");
      refetch();
    },
    onError: (err) => {
      renderNotification(err.response.data.message || err.message, "error");
    },
  });

  useEffect(() => {
    refetch();
  }, [page, limit, sortOrder, sortBy, role, registered]);

  const resetFilters = useCallback(() => {
    setSearchString("");
    setRole(undefined);
    setRegistered(undefined);
  }, []);

  const renderName = (row) => {
    if (row.firstName == "" && row.lastName == "") {
      return "N/A"
    }
    return `${row.firstName} ${row.lastName}`;
  };

  const renderRole = (row) => {
    if (row.roles.includes("admin")) {
      return (
        <div className="flex gap-1">
          <i
            title="Resend invite email"
            className={`Appkit4-icon icon-check-mark-fill`}
          ></i>
        </div>
      );
    }
  };

  const renderStatus = (row) => {
    return row.registered ? (
      <div className="flex align-center gap-1">
        <div className="circle ap-bg-color-background-success"></div>
        <div>Active</div>
      </div>
    ) : (
      <div className="flex align-center gap-1">
        <div className="circle ap-bg-color-background-warning"></div>
        <div>Awaiting Registration</div>
      </div>
    );
  };

  const renderCreatedAt = (row) => {
    return new Date(row.createdAt).toLocaleDateString();
  };

  const renderActions = (row) => {
    return (
      <div className="flex gap-1">
        <i
          title="Resend invite email"
          className={`Appkit4-icon icon-email-fill btn ${
            row["registered"] === false ? "" : "hidden"
          }`}
          onClick={() => {
            resendPlayerInvite.mutate(row.email);
          }}
        ></i>
        {/* TechTeam: uncomment this out when you want to add admin management */}
        {/* {row.roles.includes("admin") ? (
          <i
            title="Make admin"
            className={`Appkit4-icon icon-cogs-fill btn`}
            onClick={() => {
              setShowMakeAdminModal(true);
              setTargetUserId(row._id);
            }}
          ></i>
        ) : (
          <i
            title="Demote to player"
            className={`Appkit4-icon icon-avatar-fill btn`}
            onClick={() => {
              setShowRevokeAdminModal(true);
              setTargetUserId(row._id);
            }}
          ></i>
        )} */}
        <i
          title="Delete user"
          className="Appkit4-icon icon-delete-fill btn"
          onClick={() => {
            setShowDeleteUserModal(true);
            setTargetUserId(row._id);
          }}
        ></i>
      </div>
    );
  };

  const onSort = (sortKey: string, sortingPhase: number) => {
    setSortBy(sortKey);

    if (sortingPhase == 0) {
      setSortOrder(1);
      return;
    }

    setSortOrder(sortingPhase === 1 ? 1 : -1);
  };

  return (
    <div className="ap-container">
      <div className="row">
        <div className="col-12 flex align-center">
          <div
            className="Appkit4-icon icon-avatar-outline ap-my-spacing-4"
          ></div>
          <h3>
            Users
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12 flex gap-1 align-center ap-my-spacing-5">
          <Search
            className="max-content"
            searchValue={searchString}
            onChange={(e) => {
              setSearchString(e);
            }}
            placeholder="Search name or email"
          />
          <Select
            data={[
              { value: undefined, label: "All" },
              { value: "admin", label: "Admin" },
            ]}
            value={role}
            onSelect={(e) => setRole(e)}
            placeholder="Role"
          />
          <Select
            data={[
              { value: undefined, label: "All" },
              { value: true, label: "Active" },
              { value: false, label: "Awaiting Registation" },
            ]}
            value={registered}
            onSelect={(e) => {
              setRegistered(e);
            }}
            placeholder="Status"
          />
          <Button kind="secondary" className="ml-auto" onClick={resetFilters}>
            Reset filters
          </Button>
        </div>
        <div className="col-12">
          <Table originalData={users} hasTitle className="data-table" onSort={onSort}>
            <Column field="lastName" sortKey="lastName" renderCell={renderName}>
              Name
            </Column>
            <Column field="email" sortKey="email">Email</Column>
            <Column field="registered" sortKey="registered" renderCell={renderStatus}>
              Status
            </Column>
            <Column field="roles" sortKey="roles" renderCell={renderRole}>
              Admin
            </Column>
            <Column field="createdAt" sortKey="createdAt" renderCell={renderCreatedAt}>
              Date created
            </Column>
            <Column field="_id" renderCell={renderActions}>
              Actions
            </Column>
          </Table>
        </div>
        <div className="col-12 ap-my-spacing-4 flex justify-end">
          <Button
            icon="icon-plus-outline"
            onClick={() => {
              setShowInviteUserModal(true);
            }}
          >
            Invite user to platform
          </Button>
        </div>

        <div className="col-12 flex justify-center ap-my-spacing-6">
          <Pagination
            ref={paginationRef}
            current={page}
            onPageChange={(page) => {
              setPage(page);
            }}
            total={totalPages}
          />
        </div>
      </div>
      {/* TechTeam: Uncomment this out when adding admin management */}
      {/* REVOKE ADMIN MODAL */}
      {/* <Modal
        visible={showRevokeAdminModal}
        title={intl.formatMessage({ id: "user.revoke_admin.modal.title" })}
        ariaLabel={intl.formatMessage({ id: "user.revoke_admin.modal.title" })}
        onCancel={() => {
          setShowRevokeAdminModal(false);
          setTargetUserId(undefined);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowRevokeAdminModal(false);
                setTargetUserId(undefined);
              }}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
            <Button
              kind="primary"
              onClick={() => {
                setShowRevokeAdminModal(false);
              }}
            >
              <FormattedMessage id="user.revoke_admin.modal.confirm" />
            </Button>
          </div>
        }
      >
        <p>
          <FormattedMessage id="user.revoke_admin.modal.description" />
        </p>
      </Modal> */}
      {/* MAKE ADMIN MODAL */}
      {/* <Modal
        visible={showMakeAdminModal}
        title={intl.formatMessage({ id: "user.make_admin.modal.title" })}
        ariaLabel={intl.formatMessage({ id: "user.make_admin.modal.title" })}
        onCancel={() => {
          setShowMakeAdminModal(false);
          setTargetUserId(undefined);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowMakeAdminModal(false);
                setTargetUserId(undefined);
              }}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
            <Button
              kind="primary"
              onClick={() => {
                console.log("TODO: make user admin");
                setShowMakeAdminModal(false);
              }}
            >
              <FormattedMessage id="user.make_admin.modal.confirm" />
            </Button>
          </div>
        }
      >
        <p>
          <FormattedMessage id="user.make_admin.modal.description" />
        </p>
      </Modal> */}
      {/* DELETE USER MODAL */}
      <Modal
        visible={showDeleteUserModal}
        title={intl.formatMessage({ id: "user.delete.modal.title" })}
        ariaLabel={intl.formatMessage({ id: "user.delete.modal.title" })}
        onCancel={() => {
          setShowDeleteUserModal(false);
          setTargetUserId(undefined);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowDeleteUserModal(false);
                setTargetUserId(undefined);
              }}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
            <Button
              kind="negative"
              icon="icon-delete-outline"
              onClick={() => {
                handleDeleteUser.mutate(targetUserId!);
                setShowDeleteUserModal(false);
              }}
            >
              <FormattedMessage id="user.delete.modal.confirm" />
            </Button>
          </div>
        }
      >
        <p>
          <FormattedMessage id="user.delete.modal.description" />
        </p>
      </Modal>
      {/* INVITE USER MODAL */}
      <Modal
        visible={showInviteUserModal}
        title={intl.formatMessage({ id: "user.invite.modal.title" })}
        ariaLabel={intl.formatMessage({ id: "user.invite.modal.title" })}
        onCancel={() => {
          setShowInviteUserModal(false);
          setInviteEmail("");
          setIsAdmin(false);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowInviteUserModal(false);
                setInviteEmail("");
                setIsAdmin(false);
              }}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
            <Button
              kind="primary"
              icon="icon-plus-outline"
              onClick={() => {
                sendPlayerInvite.mutate({ email: inviteEmail, isAdmin: isAdmin });
                setShowInviteUserModal(false);
                setInviteEmail("");
                setIsAdmin(false);
              }}
            >
              <FormattedMessage id="user.invite.modal.confirm" />
            </Button>
          </div>
        }
      >
        <div>
          <div className="ap-mb-spacing-6">
            <Input
              type={"text"}
              title={"Enter email address"}
              value={inviteEmail}
              onChange={(e) => {
                setInviteEmail(e);
              }}
            ></Input>
          </div>
          <div className="ap-mb-spacing-6">
            <Checkbox checked={isAdmin} onChange={(e) => {
              setIsAdmin(e);
              }}>Is Admin
            </Checkbox>
          </div>
          <p>
            <FormattedMessage id="user.invite.modal.description" />
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Users;

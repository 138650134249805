import { Loading, Tooltip } from "@appkit4/react-components";
import styles from "./GameEditor.module.scss";
import React, { useState, useEffect, ReactElement } from "react";
import { useGameStore } from "@/store/game-store";
import { useQuery } from "react-query";
import { getGame } from "@/api/game";
import GameEditorSidebar from "@/components/game-editor-sidebar/GameEditorSidebar";
import { durations, menuItems } from "@/constants";
import GameMission from "../game-mission/GameMission";
import Intro from "../game-customisation-template-pages/Intro";
import Puzzle1 from "../game-customisation-template-pages/Puzzle1";
import Puzzle2 from "../game-customisation-template-pages/Puzzle1";
import Puzzle3 from "../game-customisation-template-pages/Puzzle1";
import TrainingQuestion from "../game-customisation-template-pages/TrainingQuestion";
import Outro from "../game-customisation-template-pages/Outro";
import Room1 from "../game-customisation-template-pages/Room1";
import GameHeader from '../game-header/GameHeader'

const GameEditor = () => {
  const rooms = useGameStore((state) => state.game.rooms);
  const { setGame } = useGameStore((state) => state);
  const [menuState, setMenuState] = useState(menuItems);

  const activeRooms = rooms.filter((room) => room.isActive && room.isVisible);

  const loadGame = (gameData: any) => {
    if (gameData == null) {
      return;
    }
    setGame(gameData);
  };
  const gameId = location.pathname.split("/").at(-2);
  const getGameQuery = useQuery({
    queryKey: ["getGame"],
    queryFn: () => getGame(gameId!),
    onSuccess: (res) => {
      loadGame(res.data.data);
    },
  });

  useEffect(() => {
    getGameQuery.refetch();
  }, []);

  // TODO: Replace this placeholder component with the actual Introduction component.
  const [bodyComponent, setBodyComponent] = useState<ReactElement | null>(
    <Intro />
  );
  const [selectedTopLevelIndex, setSelectedTopLevelIndex] = useState(0);
  const [selectedPuzzleIndex, setSelectedPuzzleIndex] = useState<number | null>(
    null
  );
  const [selectedTrainingQuestionIndex, setSelectedTrainingQuestionIndex] =
    useState<number | null>(null);

  const getGameDuration = (numberOfRooms: number) => {
    const duration = durations.find(
      (d) => numberOfRooms == parseInt(d.rooms.split("")[0])
    );
    if (duration) {
      return duration.name;
    }
    return "";
  };

  const getNumberOfPuzzle = (numberOfRooms: number) => {
    const duration = durations.find(
      (d) => numberOfRooms == parseInt(d.rooms.split("")[0])
    );
    if (duration) {
      const { puzzles } = duration;
      return puzzles;
    }
    return "";
  };

  const handleDeleteQuestion = (
    roomIndex: number,
    puzzleIndex: number,
    questionIndex: number
  ) => {
    setMenuState((prevMenu) => {
      const newMenu = [...prevMenu];
      const questions =
        newMenu[roomIndex].children[puzzleIndex].content.trainingQuestions;
      console.log(questions, questions, questionIndex);
      questions.splice(questionIndex, 1);
      return newMenu;
    });
    setSelectedTrainingQuestionIndex(null); // Reset the selected training question index
  };

  const getRoomId =
    menuItems[selectedTopLevelIndex] && menuItems[selectedTopLevelIndex].id
      ? menuItems[selectedTopLevelIndex].id
      : null;

  const getPuzzleId =
    menuItems[selectedTopLevelIndex].children && selectedPuzzleIndex !== null
      ? menuItems[selectedTopLevelIndex].children[selectedPuzzleIndex].content
          .id
      : null;

  const getTrainingQuestionId =
    menuItems[selectedTopLevelIndex].children &&
    selectedPuzzleIndex !== null &&
    selectedTrainingQuestionIndex
      ? menuItems[selectedTopLevelIndex].children[selectedPuzzleIndex].content
          .trainingQuestions[selectedTrainingQuestionIndex - 1].id
      : null;

  // TODO: Replace the placeholder components with the actual page components.
  useEffect(() => {
    if (selectedTopLevelIndex === 0) {
      setBodyComponent(<Intro />);
    } else if (selectedTopLevelIndex === 5) {
      setBodyComponent(<Outro />);
    } else if (selectedTopLevelIndex === 1) {
      setBodyComponent(<GameMission />);
    } else if (
      [2, 3, 4].includes(selectedTopLevelIndex) &&
      selectedPuzzleIndex === null
    ) {
      // Assuming there's a RoomOverviewTest or similar component
      // setBodyComponent(<RoomOverviewTest roomId={getRoomId()} />);
      setBodyComponent(<Room1 />);
    } else if (
      [2, 3, 4].includes(selectedTopLevelIndex) &&
      selectedPuzzleIndex !== null &&
      selectedTrainingQuestionIndex === null
    ) {
      // Assuming there's a PuzzleOverviewTest or similar component
      // setBodyComponent(<PuzzleOverviewTest puzzleId={getPuzzleId()} />);
      if (selectedTopLevelIndex === 2) {
        setBodyComponent(<Puzzle1 />);
      } else if (selectedTopLevelIndex === 3) {
        setBodyComponent(<Puzzle2 />);
      } else if (selectedTopLevelIndex === 4) {
        setBodyComponent(<Puzzle3 />);
      }
    } else if (selectedTrainingQuestionIndex === 0) {
      // Render the component to create a new question
      setBodyComponent(
        <TrainingQuestion
          onDelete={handleDeleteQuestion}
          roomIndex={selectedTopLevelIndex}
          puzzleIndex={selectedPuzzleIndex}
          questionIndex={selectedTrainingQuestionIndex}
          menuState={menuState}
        />
      );
    } else if (
      selectedTrainingQuestionIndex &&
      selectedTrainingQuestionIndex > 0
    ) {
      // Render the component to edit a question
      setBodyComponent(
        <TrainingQuestion
          onDelete={handleDeleteQuestion}
          roomIndex={selectedTopLevelIndex}
          puzzleIndex={selectedPuzzleIndex}
          questionIndex={selectedTrainingQuestionIndex}
          menuState={menuState}
        />
      );
    }
  }, [
    selectedTopLevelIndex,
    selectedPuzzleIndex,
    selectedTrainingQuestionIndex,
    menuState,
  ]);

  return (
    <div className="ap-container">
      <div className="row">
        <GameHeader />
        <div className="ap-my-spacing-5">
          <div className={styles.statusBar}>
            <div className={styles.statusBarBody}>
              <div className={styles.statusBarTitle}>
                <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">
                  Overview
                </h1>
              </div>
              <div className={styles.statusBarItem}>
                <p className={styles.statusBarItemLabel}>
                  Time limit for the game
                </p>
                <div className={styles.statusBarContent}>
                  <div className={styles.statusBarContentItem}>
                    <span className="Appkit4-icon icon-time-outline" />
                    <p className={styles.statusBarInlineTextContent}>
                      {getGameDuration(activeRooms.length).split(" ")[0]}:00
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.statusBarItem}>
                <p className={styles.statusBarItemLabel}>
                  Game Duration Tracker
                  <Tooltip
                    content="Displays estimated playtime based on current game setup and added training questions, comparing it to the actual time available for gameplay."
                    position="top"
                    trigger="hover"
                    className={`w-auto max-w-xl`}
                    style={{ maxWidth: "480px" }}
                  >
                    <span className="Appkit4-icon icon-information-outline ap-ml-spacing-2"></span>
                  </Tooltip>
                </p>
                <div className={styles.statusBarContent}>
                  <div className={styles.statusBarContentItem}>
                    <p className={styles.statusBarInlineTextContent}>
                      28 out of {getGameDuration(activeRooms.length)}
                    </p>
                    <Loading
                      className={styles.loadingBar}
                      loadingType="linear"
                      indeterminate={false}
                      linearWidth={84}
                      stopPercent={
                        (28 /
                          parseInt(
                            getGameDuration(activeRooms.length).split(" ")[0]
                          )) *
                        100
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statusBarItem}>
                <p className={styles.statusBarItemLabel}>Included in game</p>
                <div className={styles.statusBarContent}>
                  <div className={styles.statusBarContentItem}>
                    <span className="Appkit4-icon icon-ask-question-outline" />
                    <p className={styles.statusBarInlineTextContent}>
                      0 training questions
                    </p>
                  </div>
                  <div className={styles.statusBarContentItem}>
                    <span className="Appkit4-icon icon-door-opened-outline" />
                    <p className={styles.statusBarInlineTextContent}>
                      {activeRooms.length === 1
                        ? "1 room"
                        : activeRooms.length + " rooms"}
                    </p>
                  </div>
                  <div className={styles.statusBarContentItem}>
                    <span className="Appkit4-icon icon-gameroom-outline" />
                    <p className={styles.statusBarInlineTextContent}>
                      {getNumberOfPuzzle(activeRooms.length).toString()} puzzles
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.sidebarContainer}>
            <GameEditorSidebar
              menuItems={menuItems}
              setSelectedTopLevelIndex={setSelectedTopLevelIndex}
              setSelectedPuzzleIndex={setSelectedPuzzleIndex}
              setSelectedTrainingQuestionIndex={
                setSelectedTrainingQuestionIndex
              }
            />
          </div>
          <div className={`${styles.contentContainer}`}>
            <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">
              {bodyComponent}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameEditor;

import React from "react";
import {
  Button,
  Tooltip,
  Modal,
  TextArea,
  Upload,
  Switch,
  Input,
  Notification,
  toaster,
  Checkbox,
} from "@appkit4/react-components";
import styles from "../game-mission/GameMission.module.scss";
import { useState, useEffect } from "react";
import { getGame } from "@/api/game";
import { useGameStore } from "@/store/game-store";
import { durations } from "@/constants";

interface TrainingQuestionProps {
  roomIndex: number;
  puzzleIndex: number;
  questionIndex: number;
  onDelete: (
    roomIndex: number,
    puzzleIndex: number,
    questionIndex: number
  ) => void;
  menuState: any;
}

const TrainingQuestion: React.FC<TrainingQuestionProps> = ({
  roomIndex,
  puzzleIndex,
  questionIndex,
  onDelete,
  menuState,
}) => {
  const rooms = useGameStore((state) => state.game.rooms);
  const activeRooms = rooms.filter((room) => room.isActive && room.isVisible);
  const [isIntroActive, setIsIntroActive] = useState(true);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const currentGameDuration = 61;
  const [questionTitleError, setQuestionTitleError] = useState(false);
  const [questionHeaderError, setQuestionHeaderError] = useState(false);
  const [questionBodyError, setQuestionBodyError] = useState(false);
  const [answerError, setAnswerError] = useState(false);
  const [titleValue, setTitleValue] = useState("");
  const [headerValue, setHeaderValue] = useState("");
  const [answerValue, setAnswerValue] = useState("");
  const [bodyValue, setBodyValue] = useState("");
  const [answerRows, setAnswerRows] = useState(3);

  const handleSavedClose = () => {
    console.log("Closed!");
  };

  const handleDeleteConfirmation = () => {
    const puzzle = menuState[roomIndex].children[puzzleIndex];
    const trainingQuestions = puzzle.content.trainingQuestions;
    console.log(trainingQuestions.length);
    // if (trainingQuestions.length === 1) {
    //   hideDeleteModal();
    //   toaster.notify(lastTrainingQuestion, {
    //     position: "topHeader",
    //     onClose: handleSavedClose,
    //   });
    // } else {
    console.log(roomIndex, puzzleIndex, questionIndex);
    onDelete(roomIndex, puzzleIndex, questionIndex);
    hideDeleteModal();
    toaster.notify(deletedTrainingQuestion, {
      position: "topHeader",
      onClose: handleSavedClose,
    });
    // }
  };

  const handleDeleteLastRow = () => {
    if (answerRows > 2) {
      setAnswerRows(answerRows - 1);
    }
  };

  const getGameDuration = (numberOfRooms: number) => {
    const duration = durations.find(
      (d) => numberOfRooms == parseInt(d.rooms.split("")[0])
    );
    if (duration) {
      return duration.name;
    }
    return "";
  };

  const emptyError = (
    <Notification
      title="Error: Missing Information"
      message={
        <div style={{ paddingLeft: "5px" }}>
          Some information is missing. Please fill out all required fields.
        </div>
      }
      status="error"
      onClose={handleSavedClose}
      style={{ paddingLeft: "5px", alignContent: "left" }}
    ></Notification>
  );

  const deletedTrainingQuestion = (
    <Notification
      title=""
      message={<div>Question deleted</div>}
      status="icon-circle-warning-outline"
    ></Notification>
  );

  const lastTrainingQuestion = (
    <Notification
      title="Error: Unable to Delete Training Question"
      message={
        <div>You must have at least one training question per puzzle</div>
      }
      status="error"
    ></Notification>
  );

  const handleDeleteClick = () => {
    setIsDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const onTitleChange = (value: any, event: any) => {
    setTitleValue(value);
  };

  const onHeaderChange = (value: any, event: any) => {
    setHeaderValue(value);
  };

  const onAnswerChange = (value: any, event: any) => {
    setAnswerValue(value);
  };

  const onBodyChange = (value: any, event: any) => {
    setBodyValue(value);
  };

  const onIntroChange = (value: any, event: any) => {};

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedSubIndex, setSelectedSubIndex] = useState(0);

  const handleAddAnswer = () => {
    setAnswerRows((prevRows) => prevRows + 1);
  };

  const data = (
    <div className="textEditorDiv">"Lorem ipsum dolor sit amet"</div>
  );
  const sampleConfig = {
    toolbar: [
      "fontFamily",
      "fontSize",
      "bold",
      "italic",
      "alignment:left",
      "alignment:center",
      "alignment:right",
      "alignment:justify",
    ],
  };

  const onUploadChange = (file: File, fileList: FileList): void => {
    console.log("onChange", file, fileList);
    //   modalContext?.setIsChanged(true);
    //   console.log('game editor is changed: ', modalContext?.isChanged)
  };
  const uploadFiles = (fileList: any) => {
    console.log(fileList);
  };

  const getTooltipContent = () => {
    const estimatedDuration = parseInt(
      getGameDuration(activeRooms.length).split(" ")[0]
    );
    if (currentGameDuration > estimatedDuration) {
      return "Exceeding estimated playtime - players may not finish before the countdown ends. Consider adjusting content or extending available time if possible";
    }
    return "Displays estimated playtime based on current game setup and added training questions, comparing it to the actual time available for gameplay.";
  };

  const onSaveGame = () => {
    const errors = {
      questionTitleError: !titleValue,
      questionHeaderError: !headerValue,
      questionBodyError: !bodyValue,
      answerError: !answerValue,
    };

    const hasErrors = Object.values(errors).some((error) => error);

    if (hasErrors) {
      Object.entries(errors).forEach(([errorType, isError]) => {
        if (isError) {
          switch (errorType) {
            case "questionTitleError":
              setQuestionTitleError(true);
              break;
            case "questionHeaderError":
              setQuestionHeaderError(true);
              break;
            case "questionBodyError":
              setQuestionBodyError(true);
              break;
            case "answerError":
              setAnswerError(true);
              break;
            default:
              break;
          }
        }
      });

      toaster.notify(emptyError, {
        position: "topHeader",
        onClose: handleSavedClose,
      });
    } else {
      setShowGameSavedModal(true);
      console.log("Game saved!");
    }
  };

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={styles.headerContainer}>
          <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">
            Training Question
          </h1>
          <div className={styles.buttonContainer}>
            <Button
              icon="icon-delete-outline"
              onClick={handleDeleteClick}
              kind="secondary"
              className={styles.deleteButton}
            >
              Delete question
            </Button>
            <Button
              onClick={onSaveGame}
              kind="primary"
              className={styles.saveButton}
            >
              Save changes
            </Button>
          </div>
        </div>
        <p className="ap-typography-body ap-mb-spacing-6">
          Write your training questions and answers in this section.
        </p>
        {isIntroActive && (
          <>
            <div className={styles.divider}></div>
            <h3>Question</h3>
            <Input
              type={"text"}
              title={"Title of the question"}
              onClick={() => {
                console.log("you clicked the input");
              }}
              value={titleValue}
              style={{ width: "940px", paddingBottom: "20px" }}
              onChange={onTitleChange}
              error={questionTitleError}
              errorNode={
                <div
                  id="errormessage"
                  aria-live="polite"
                  className="ap-field-email-validation-error"
                >
                  Please enter a question title
                </div>
              }
            ></Input>
            <Input
              type={"text"}
              title={"Header"}
              style={{ width: "940px", paddingBottom: "20px" }}
              autosize={true}
              required={true}
              value={headerValue}
              onChange={onHeaderChange}
              error={questionHeaderError}
              errorNode={
                <div
                  id="errormessage"
                  aria-live="polite"
                  className="ap-field-email-validation-error"
                >
                  Please enter a question header
                </div>
              }
            ></Input>
            <Input
              type={"text"}
              title={"Body"}
              style={{ width: "940px", paddingBottom: "20px" }}
              autosize={true}
              required={true}
              value={bodyValue}
              onChange={onBodyChange}
              error={questionBodyError}
              errorNode={
                <div
                  id="errormessage"
                  aria-live="polite"
                  className="ap-field-email-validation-error"
                >
                  Please enter a question body
                </div>
              }
            ></Input>
            <div className={styles.divider}></div>
            <h3>Add an image or video to your introduction (optional)</h3>
            <p className="ap-typography-body ap-mb-spacing-6">
              You can upload JPG, PNG or MP4 files. The max file size is 10mb.{" "}
              <br />
              Videos extend question completion time. Adjust your game plan
              accordingly.
            </p>
            <Upload
              className="upload"
              onChange={onUploadChange}
              multiple={false}
              autoUpload={true}
              style={{ width: "940px", paddingBottom: "20px" }}
              onUpload={uploadFiles}
              acceptFileType=".JPG,.PNG,.MP4"
              maxFileSize={10 * 1024 * 1024}
              config={{
                trigger: false,
                type: "inline",
                size: true,
              }}
            ></Upload>
          </>
        )}
        <div className={styles.divider}></div>
        <h3>Answers</h3>
        <p className="ap-typography-body ap-mb-spacing-6">
          Add at least 2 possible answers and at least 1 correct answer.
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "10px",
            paddingTop: "20px",
          }}
        >
          <Switch className="ap-typography-body ap-mb-spacing-6">
            {"Randomise Answers"}
          </Switch>

          <p
            className="ap-typography-body ap-mb-spacing-6"
            style={{ paddingRight: "70px" }}
          >
            Correct*
          </p>
        </div>
        <div>
          <div className="ap-typography-body ap-mb-spacing-6">
            {Array.from({ length: answerRows }).map((_, index) => (
              <div key={index} className={styles.answerContainer}>
                <div style={{ width: "844px", paddingRight: "20px" }}>
                  <Input
                    title={"Answer"}
                    value={answerValue}
                    style={{ width: "100%" }} // Full width for the input
                    autosize={true}
                    required={true}
                    onChange={onAnswerChange}
                    error={answerError}
                    errorNode={
                      <div
                        id="errormessage"
                        aria-live="polite"
                        className="ap-field-email-validation-error"
                      >
                        Please enter an answer
                      </div>
                    }
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    style={{
                      width: "25.5px",
                      height: "25.5px",
                      marginRight: "20px",
                    }}
                  />
                  {index === answerRows - 1 && (
                    <Button
                      icon="icon-delete-outline"
                      kind="text"
                      className={styles.deleteButton}
                      style={{ alignItems: "center" }}
                      onClick={handleDeleteLastRow}
                    ></Button>
                  )}
                </div>
              </div>
            ))}
            <Button
              icon="icon-circle-plus-fill"
              onClick={handleAddAnswer}
              kind="text"
              className={styles.deleteButton}
            >
              Add answer
            </Button>
          </div>
        </div>

        {
          <Modal
            visible={false}
            title={"Unsaved changes"}
            ariaLabel={"Unsaved changes"}
            modalStyle={{ width: "33.75rem" }}
            footerStyle={{
              paddingTop: "8px",
              marginTop: "-8px",
              minHeight: "64px",
            }}
            header={""}
            icons={""}
            footer={
              <>
                <Button
                  onClick={() => {
                    hideModal();
                  }}
                  kind="secondary"
                >
                  Discard changes and exit
                </Button>
                <Button
                  onClick={() => {
                    hideModal();
                  }}
                >
                  Save and exit
                </Button>
              </>
            }
            bodyStyle={{ minHeight: "92px" }}
          >
            <p>
              You have unsaved changes in this section.
              <br />
              <br />
              If you move to another section now, those changes will be lost.
              <br />
              <br />
              Would you like to save your changes before leaving?
              <br />
            </p>
          </Modal>
        }
        <Modal
          visible={isDeleteModalVisible}
          title={"Delete question?"}
          ariaLabel={"Delete question?"}
          onCancel={hideDeleteModal}
          modalStyle={{ width: "33.75rem" }}
          footerStyle={{
            paddingTop: "8px",
            marginTop: "-8px",
            minHeight: "64px",
          }}
          header={""}
          icons={""}
          footer={
            <>
              <Button onClick={handleDeleteConfirmation} kind="secondary">
                Delete question
              </Button>
              <Button
                onClick={() => {
                  // navigate(confirmedRoute);
                  hideDeleteModal();
                  // setIsChanged(false);
                }}
              >
                Cancel and keep
              </Button>
            </>
          }
        >
          <p>
            Are you sure you want to delete this question? this action is
            permanent.
          </p>
        </Modal>
        <Modal
          visible={false}
          title={"Potential impact on game completion time."}
          ariaLabel={"Potential impact on game completion time."}
          modalStyle={{ width: "33.75rem" }}
          footerStyle={{
            paddingTop: "8px",
            marginTop: "-8px",
            minHeight: "64px",
          }}
          header={""}
          icons={""}
          footer={
            <>
              <Button
                onClick={() => {
                  hideModal();
                }}
                kind="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  hideModal();
                }}
              >
                Add another question
              </Button>
            </>
          }
          bodyStyle={{ minHeight: "92px" }}
        >
          <p>
            Just a heads up. Adding another training question may extend the
            game beyond the planned duration.
            <br />
            <br />
            While you can add more questions, there's a possibility that players
            might not have enough time to finish the game.
            <br />
            <br />
            Please consider this as you continue to customise your game
            experience.
            <br />
          </p>
        </Modal>
      </div>
    </>
  );
};

export default TrainingQuestion;

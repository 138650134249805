import { useState } from 'react';
import styles from "@/components/score-dashboard/ScoreDashboard.module.scss";
import { ScoreboardData } from '@/models/ScoreboardData';
import { useQuery } from 'react-query';
import { getGameSessionScoreResults } from "@/api/session";
import { useParams } from 'react-router-dom';

const Dashboard = () => {
   const { sessionId } = useParams();

   if (sessionId === null) {
    return <div>Session not found</div>;
  }

  const getGameSessionScoreDashboardResults = async (sessionId: string): Promise<ScoreboardData> => {
    const response = getGameSessionScoreResults(sessionId);
    return (await response).data.data;
  };

  const [data, setData] = useState<ScoreboardData | null>(null);

  const getScoreboardQuery = useQuery({
    queryKey: ['getGameSessionScoreResults', sessionId],
    queryFn: () => getGameSessionScoreDashboardResults(sessionId!),
    onSuccess: (res) => {
      setData(res);
    }
  });

  if (!data) {
    return <div>No data available</div>;
  }

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const renderTeamInfo = (team, index) => {
    const isCurrentTeam = team.teamId === data.sessionTeam.teamId;
    const currentTeamClass = isCurrentTeam ? styles.currentTeam : '';

    return (
      <li key={team.teamId} className={`${styles.team} ${currentTeamClass}`}>
        <span className={`${styles.teamNumber} ${currentTeamClass}`}>{index + 1}</span>
        <span className={`${styles.teamName} ${currentTeamClass}`}>{team.teamName}</span>
        <span className={`${styles.teamPoints} ${currentTeamClass}`}>{team.teamScore} points</span>
        {index < 3 && <img src={`/icons/ribbon${index + 1}.svg`} alt={`ribbon ${index + 1}`} />}
      </li>
    );
  };

  return (
    <div className={styles.dashboard}>
       <img src="/icons/logo.svg" alt="Logo" className={styles.logo} />
    <div className={styles.scoreBoard}>
    <h2 className={styles.heading}>Your team’s score</h2>
      <div className={styles.scoreDetails}>
        <div className={styles.scoreItem}>
          <span className={styles.label}>Time</span>
          <span className={styles.value}>{formatTime(data.sessionTeam.time)}</span>
        </div>
        <div className={styles.scoreItem}>
          <span className={styles.label}>Hints</span>
          <span className={styles.value}>{data.sessionTeam.hints}</span>
        </div>
        <div className={styles.scoreItem}>
          <span className={styles.label}>Puzzles</span>
          <span className={styles.value}>{data.sessionTeam.puzzles}/{data.sessionTeam.maxPuzzles}</span>
        </div>
        <div className={styles.scoreItem}>     
          <div className={styles.pointsContainer}>
          <span className={styles.label}>Points</span>
            <img src="/icons/star.svg" alt="Star" className={styles.star} />
            </div>
            <span className={styles.value}>{data.sessionTeam.teamScore}</span>
        </div>
      </div>
    </div>
    <div className={styles.teamRanking}>
      <h2>Team ranking</h2>
      <ol>
      {data.teams.map((team, index) => renderTeamInfo(team, index))}
      </ol>
    </div>
  </div>
  );
};

export default Dashboard;
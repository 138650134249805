import React from "react";
import {
  Button,
  Tooltip,
  Modal,
  TextArea,
  Upload,
  Switch,
  Input,
  Notification,
  toaster,
} from "@appkit4/react-components";
import styles from "../game-mission/GameMission.module.scss";
import { useState, useEffect, useCallback } from "react";
import { useModal } from "./UnsavedChangesContext";

type Props = {};

const Intro = (props: Props) => {
  const initialHeaderValue = "Welcome to your interactive game!";
  const initialIntroValue =
    "You're about to embark on an exciting journey filled with challenges testing your wits and your knowledge of the training program.\nGet ready to explore, solve puzzles, and collaborate with your team. Let's make this an unforgettable adventure!";

  const [headerValue, setHeaderValue] = useState(initialHeaderValue);
  const [introValue, setIntroValue] = useState(initialIntroValue);
  const [fileUploaded, setFileUploaded] = useState(false);
  const modalContext = useModal();
  const isModalOpen = modalContext?.isModalOpen || false;
  const showModal = modalContext?.showModal || (() => {});
  const hideModal = modalContext?.hideModal || (() => {});

  const [isIntroActive, setIsIntroActive] = useState(true);

  const savedIntroActive = (
    <Notification
      title=""
      message={<div>Your introduction has been added into the game</div>}
      status="success"
    ></Notification>
  );

  const savedIntroInactive = (
    <Notification
      title=""
      message={<div>Your introduction has been removed from the game</div>}
      status="success"
    ></Notification>
  );

  const onUploadChange = (file: File, fileList: FileList): void => {
    console.log("onChange", file, fileList);
    setFileUploaded(true);
    modalContext?.setIsChanged(true);
    console.log("game editor is changed: ", modalContext?.isChanged);
  };
  const uploadFiles = (fileList: any) => {
    console.log(fileList);
  };

  const handleSavedClose = () => {
    console.log("Closed!");
  };

  const handleSaveClick = () => {
    if (isIntroActive) {
      toaster.notify(savedIntroActive, {
        position: "topHeader",
        onClose: handleSavedClose,
      });
    } else {
      toaster.notify(savedIntroInactive, {
        position: "topHeader",
        onClose: handleSavedClose,
      });
    }
    modalContext?.setIsChanged(false);
  };

  const onHeaderChange = (value: any, event: any) => {
    setHeaderValue(value);
    modalContext?.setIsChanged(true);
    console.log("game editor is changed: ", modalContext?.isChanged);
  };

  const onIntroChange = (value: any, event: any) => {
    setIntroValue(value);
    modalContext?.setIsChanged(true);
  };

  const checkIfChanged = useCallback(() => {
    if (
      headerValue === initialHeaderValue &&
      introValue === initialIntroValue &&
      isIntroActive === true &&
      !fileUploaded
    ) {
      modalContext?.setIsChanged(false);
    }
  }, [headerValue, introValue, isIntroActive, fileUploaded]);

  useEffect(() => {
    checkIfChanged();
  }, [headerValue, introValue, isIntroActive, fileUploaded, checkIfChanged]);

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={styles.headerContainer}>
          <h1 className="ap-font-weight-2">Introduction (optional)</h1>
          <div className={styles.buttonContainer}>
            <Button
              className={styles.saveButton}
              onClick={handleSaveClick}
              kind="primary"
              disabled={!modalContext?.isChanged}
            >
              Save changes
            </Button>
          </div>
        </div>
        <Tooltip
          trigger="hover"
          position="top-left"
          distance={4}
          id="tooltipDesc"
          appendAfterTarget={true}
          content="Toggle ON to include an intro for the context; OFF for a direct game start. Intro content is retained, simply hidden from view."
        >
          <Switch
            defaultChecked={isIntroActive}
            onChange={() => {
              setIsIntroActive((prevState) => {
                modalContext?.setIsChanged(true);
                console.log(
                  "game editor is changed: ",
                  modalContext?.isChanged
                );
                return !prevState;
              });
            }}
            style={{ paddingBottom: "10px" }}
          >
            {isIntroActive ? "Active" : "Inactive"}
          </Switch>
        </Tooltip>
        <p className={`ap-typography-body`}>
          Craft a welcoming message or introduction for participants, explaining
          why they're playing.
          <br />
          This section is optional and can be toggled on or off.
        </p>
        {isIntroActive && (
          <>
            <div className={styles.divider}></div>
            <h3>Customise your introduction</h3>
            <Input
              className={`${styles.introInput} ap-typography-body`}
              type={"text"}
              title={"Header"}
              onClick={() => {
                console.log("you clicked the input");
              }}
              value={headerValue}
              onChange={onHeaderChange}
            ></Input>
            <TextArea
              className={`${styles.introInput} ap-typography-body`}
              title={"Your introductory text"}
              maxLength={420}
              value={introValue}
              autosize={true}
              onChange={onIntroChange}
            ></TextArea>
            <div className={styles.divider}></div>
            <h3>Add an image or video to your introduction (optional)</h3>
            <p className={`ap-typography-body`}>
              You can upload JPG, PNG or MP4 files. The max file size is 10mb.{" "}
              <br />
              Videos extend question completion time. Adjust your game plan
              accordingly.
            </p>
            <Upload
              className="upload"
              onChange={onUploadChange}
              multiple={false}
              autoUpload={true}
              style={{ width: "940px" }}
              onUpload={uploadFiles}
              acceptFileType=".JPG,.PNG,.MP4"
              maxFileSize={10 * 1024 * 1024}
              config={{
                trigger: false,
                type: "inline",
                size: true,
              }}
            ></Upload>
          </>
        )}
      </div>

      {isModalOpen && (
        <Modal
          visible={isModalOpen}
          title={"Unsaved changes"}
          ariaLabel={"Unsaved changes"}
          onCancel={hideModal}
          modalStyle={{ width: "33.75rem" }}
          footerStyle={{
            paddingTop: "8px",
            marginTop: "-8px",
            minHeight: "64px",
          }}
          header={""}
          icons={""}
          footer={
            <>
              <Button
                onClick={() => {
                  hideModal();
                }}
                kind="secondary"
              >
                Discard changes and exit
              </Button>
              <Button
                onClick={() => {
                  hideModal();
                }}
              >
                Save and exit
              </Button>
            </>
          }
          bodyStyle={{ minHeight: "92px" }}
        >
          <p>
            You have unsaved changes in this section.
            <br />
            <br />
            If you move to another section now, those changes will be lost.
            <br />
            <br />
            Would you like to save your changes before leaving?
            <br />
          </p>
        </Modal>
      )}
    </>
  );
};

export default Intro;

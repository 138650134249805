import React, { Fragment } from "react";
import {Sidebar, Menu, MenuItem, SubMenu} from 'react-pro-sidebar';
import styles from "./GameEditorSidebar.module.scss";
import { menuItemProps } from '@/constants'

interface GameEditorSidebarProps {
  menuItems: menuItemProps[];
  setSelectedTopLevelIndex: React.Dispatch<React.SetStateAction<number>>;
  setSelectedPuzzleIndex: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedTrainingQuestionIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

const GameEditorSidebar: React.FC<GameEditorSidebarProps> = (
  {
    menuItems,
    setSelectedTopLevelIndex,
    setSelectedPuzzleIndex,
    setSelectedTrainingQuestionIndex
  }) => {
  const [activeIndex, setActiveIndex] = React.useState();

  const MenuItemButton = React.forwardRef(function MenuItemButton(
    {content, disabled, duration, className, suffixIcon, suffixExpandedIcon, prefixIcon, children, onClick, onKeyUp, tabIndex, title, index}
    , ref) {
    const handleClick = () => {
      setActiveIndex(index.toString());
      setSelectedTopLevelIndex(index[0]);
      setSelectedPuzzleIndex(index[1] !== undefined ? index[1] : null);
      setSelectedTrainingQuestionIndex(index[2] !== undefined ? index[2] : null);
      if (onClick) onClick();
    };
    const open = className.includes('ps-open');
    const active = activeIndex === index.toString();
    const nextIndex = index ? parseInt(index) + 1 : 0;

    return (
      <a
        ref={ref}
        className={`${
          active ?
            styles.sidebarMenuButtonActive :
            (nextIndex.toString() === activeIndex ?
              styles.sidebarMenuButtonNextActive :
              styles.sidebarMenuButton)} ${className}`}
        onClick={handleClick}
        {...{onKeyUp, tabIndex, title}}
        key={tabIndex}
      >
        {suffixIcon
          ? <span className={`Appkit4-icon icon-${prefixIcon}-outline`}/>
          : undefined
        }
        {content}
        {children}
        {suffixIcon
          ? <span className={`${styles.suffixIcon} Appkit4-icon icon-${
            open && suffixExpandedIcon
              ? suffixExpandedIcon
              : suffixIcon
          }-outline`}/>
          : undefined
        }
      </a>
    );
  });

  MenuItemButton.displayName = 'MenuItemButton';

  const getNumberOfTrainingQuestions = (item) => {
    return item.children.reduce((accumulator: number, puzzle) => {
      return accumulator + puzzle.content.trainingQuestions.length;
  }, 0);
  }

  const handleAddRoom = (event, id: number) => {
    event.stopPropagation();
    console.log('Add room: ', id);
  }

  const roomItem = (item) => {
    const {id, content, duration, disabled, children} = item;

    return (
      <div className="ap-ml-spacing-3">
        <div className={`${styles.roomTitle} ap-font-weight-2 ap-mb-spacing-2`}>
          <span>{content}</span>
          {disabled && (
            <div>
              <span className="ap-mx-spacing-3"> • </span>
              <button className={styles.addRoomButton} onClick={(e) => handleAddRoom(e, id)}>Add room</button>
            </div>
          )}
        </div>
        <div className={styles.roomMetaData}>
          <span>{children.length} puzzles</span>
          <span> • </span>
          <span>{duration} min</span>
          <span> • </span>
          <span>{getNumberOfTrainingQuestions(item)} training questions</span>
        </div>
      </div>
    )
  }

  const renderRoomNavItem = (item, roomIndex: number) => {
    const {disabled, prefixIcon, suffixIcon, suffixExpandedIcon, children} = item;

    return (
      <Fragment key={roomIndex}>
        <SubMenu
          key={roomIndex}
          disabled={disabled}
          rootStyles={{
            fontWeight: 500
          }}
          component={<MenuItemButton className={styles.sidebarRoomMenuButton} />}
          content={roomItem(item)}
          prefixIcon={prefixIcon}
          suffixIcon={suffixIcon}
          suffixExpandedIcon={suffixExpandedIcon}
          index={roomIndex}
        >
          {
            children && children.map((child, puzzleIndex: number) => {
              return (
                <div key={puzzleIndex} className={styles.puzzleContainer}>
                  <MenuItem
                    className={`${styles.puzzleTitleContainer}`}
                    component={<MenuItemButton/>}
                    index={[...roomIndex, puzzleIndex]}
                  >
                    <span className="Appkit4-icon icon-box-outline ap-mr-spacing-3" />
                    <span>
                      {child.content.puzzleName}
                    </span>
                  </MenuItem>
                  {
                    (child.content.trainingQuestions && child.content.trainingQuestions.length > 0) && (
                      <div className="ap-mb-spacing-4">
                        {child.content.trainingQuestions.map((item, questionIndex: number) => {
                          return (
                            <Fragment key={item.id}>
                              <MenuItem
                                key={questionIndex}
                                className={styles.trainingQuestion}
                                component={<MenuItemButton/>}
                                prefixIcon='ask-question'
                                suffixIcon='pencil'
                                index={[...roomIndex, puzzleIndex, questionIndex + 1]}
                              >
                                <div className={styles.trainingQuestionInner}>
                                  <span className={styles.trainingQuestionTitle}>{item.title}</span>
                                  <div>
                                    <span className="ap-typography-body-xs"> • </span>
                                    <span className="ap-typography-body-xs ap-ml-spacing-3">{item.duration} min</span>
                                  </div>
                                </div>
                              </MenuItem>
                            </Fragment>
                          )
                        })}
                      </div>
                    )
                  }
                  <MenuItem
                    key={puzzleIndex}
                    className={styles.trainingQuestion}
                    component={<MenuItemButton/>}
                    index={[...roomIndex, puzzleIndex, 0]}
                  >
                    <div className={`${styles.addTrainingQuestion} ap-px-spacing-4`}>
                      <span className="Appkit4-icon icon-circle-plus-fill ap-mr-spacing-3" />
                      Add training question
                    </div>
                  </MenuItem>
                </div>
              )
            })
          }
        </SubMenu>
      </Fragment>
    )
  }

// Function to render a single menu item
  function renderMenuItem(item, index: number) {
    const {content, duration, prefixIcon, suffixIcon, children} = item;

    if (children) {
      return renderRoomNavItem(item, index, index);
    } else {
      // Render a regular menu item
      return (
        <MenuItem
          key={index}
          component={<MenuItemButton/>}
          prefixIcon={prefixIcon}
          suffixIcon={suffixIcon}
          index={index}
        >
          <div className="ap-ml-spacing-3">
            <span className="ap-font-weight-2">{content}</span>
            <span className="ap-typography-body-xs ap-ml-spacing-3"> • </span>
            <span className="ap-typography-body-xs ap-ml-spacing-3">{`${duration} min`}</span>
          </div>
        </MenuItem>
      );
    }
  }


// Function to generate the menu from the array of objects
  function generateMenu(menuItems: menuItemProps[]) {
    let count = 0;
    const increment = () => {
      count++
      return count-1
    }
    return (
      <Menu className={styles.sidebarMenu}>
        {menuItems.map(item => renderMenuItem(item, [increment()]))}
      </Menu>
    );
  }

  return (
    <div className={styles.sidebar}>
      <Sidebar className={styles.sidebar}>
        {generateMenu(menuItems)}
      </Sidebar>
    </div>
  )
}

export default GameEditorSidebar

import { TObjectOrderDirection } from "./types/game";
import Player from "./phaser/classes/session/Player";
import { createRandomString } from "./utils/phaser-helpers";

const env = import.meta.env.MODE;

export const API_URL = `${import.meta.env.VITE_API_URL}`;
export const WS_URL = import.meta.env.VITE_WEBSOCKETS_URL;

export const industries = [
  {
    label: "Manufacturing & Construction",
    value: "Manufacturing & Construction",
  },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Education", value: "Education" },
  { label: "Retail and Consumer", value: "Retail and Consumer" },
  {
    label: "Telecommunications (Media & Technology)",
    value: "Telecommunications (Media & Technology)",
  },
  {
    label: "Transport (Civil, Aviation)",
    value: "Transport (Civil, Aviation)",
  },
  {
    label: "Financial Services",
    value: "Financial Services"
  },
  {
    label: "Hotel, Tourism and Catering",
    value: "Hotel, Tourism and Catering",
  },
  {
    label: "Utilities (Oil and Gas, Agriculture)",
    value: "Utilities (Oil and Gas, Agriculture)",
  },
];

export const themes = [
  { name: "Hospitality", icon: "icon-placing-glass-outline" },
  { name: "Financial Services", icon: "icon-wealth-outline" },
  { name: "Government", icon: "icon-document-policies-outline" },
  { name: "Healthcare", icon: "icon-physician-outline" },
  { name: "Utilities", icon: "icon-oilplatform-outline" },
  { name: "I want to mix & match from different themes", icon: "icon-group-outline" },
];

export const questions = [
  {
    name: "1-6 Questions",
    description: "Ideal for quick reviews\nor short training\n sessions.",
  },
  {
    name: "7-10 Questions",
    description: "Great for moderate-\ndepth training, or broad\n topics.",
  },
  {
    name: "11-18 Questions",
    description:
      "Best for comprehensive\n learning and thorough\n topic coverage.",
  },
  { name: "I don't know", description: " " },
];

export const durations = [
  {
    name: "30 min",
    description: "Short and snappy",
    rooms: "1 room",
    puzzles: "3",
    recommendedFor: [1, 2, 3, 4, 5, 6],
  },
  {
    name: "45 min",
    description: "Leisurely and engaging",
    rooms: "2 rooms",
    puzzles: "6",
    recommendedFor: [7, 8, 9, 10],
  },
  {
    name: "60 min",
    description: "Not recommended for this amount of questions",
    rooms: "3 rooms",
    puzzles: "9",
    recommendedFor: [11, 12, 13, 14, 15, 16, 17, 18],
  },
];

// This is placeholder content before we get the data from the backend.
// We need a list of game themes and the corresponding missions.
export const gameMissionsPlaceHolderContent = [
  {
    themeName: "Financial Services",
    mission:
      "Our CFO, {input}, is presently inaccessible due to unforeseen circumstances, and he has safeguarded a crucial {input} report behind a series of his trademark puzzles and riddles. This report is critical for today’s {input} meeting, which is just one hour away. Your mission: decode the puzzles, retrieve the report, and safeguard our company’s integrity. We’re counting on you! Good luck.",
    inputs: ["David Barrett", "financial", "stakeholder"],
    room1: [
      "Open space",
      [
        "Set in an open space, this room has puzzles that unfold in a clear, linear progression. Starting with a cryptic riddle hidden within a notebook, players unlock a series of interconnected challenges to make it to the next step.",
      ],
      [
        "1. The Fiscal Riddle: A riddle leads to a calendar clue, unlocking the TV. 2. Currency Match: A memory game on the TV pairs flags with currencies. 3. The Jigsaw Code: Solving a jigsaw puzzle on the TV reveals a mini-fridge code. 4. The minifridge cans: A note in the mini-fridge help to deciphers a counting puzzle that gives the player the code to access the CFO’s office.",
      ],
    ],
  },
  {
    themeName: "Government",
    mission:
      "A critical system failure {input} requires your immediate attention. The {input} has unexpectedly gone offline following a recent security update, resulting in a total access lockdown. You must urgently regain entry, decipher the security protocols, and reboot the system. Time is of the essence to avert a major service disruption. Good luck.",
    inputs: [
      "at Name of Department, company or Authority",
      "Name of Critical System",
    ],
  },
  {
    themeName: "Healthcare",
    mission:
      "{input}, a key figure at {input}, has vanished, leaving behind the essential blueprints {input}. We know that before disappearing {input} had cleverly concealed them within our facility. It's imperative that you locate and secure these blueprints by deciphering the clues left in the waiting room—our years of work depend on it. Good luck",
    inputs: [
      "Dr. Evelyn Hart",
      "MediTech Solutions",
      "of an advanced patient care interface",
      "he /she / they",
    ],
  },
  {
    themeName: "Utilities",
    mission:
      "The {input} system {input}} has experienced a complete operational shutdown, affecting thousands across our community. Your mission is to navigate through the security protocols, solve the puzzles, and access the control room. Once there, you must initiate a system reboot to restore the essential services upon which our community depends. We depend on your skills and quick thinking to bring our systems back online. Good luck, and remember, every second counts.",
    inputs: ["water/gas/electricity supply", "at Utility Company Name"],
  },
  {
    themeName: "Manufacturing & Construction",
    mission:
      "The blueprint for the new {input} has been hidden by someone trying to disrupt our project. This blueprint is crucial to our project's continuation and timely completion. Explore the construction site, solve the puzzles, each puzzle solved will bring you one step closer to locating the blueprint. Time is of the essence, we are counting on your team's expertise to recover the blueprint swiftly and ensure our project remains on schedule. Good luck, and let’s bring this project home!",
    inputs: ["community centre/school/hospital"],
  },
  {
    themeName: "Education",
    mission:
      "The blueprint for the new {input} has been hidden by someone trying to disrupt our project. This blueprint is crucial to our project's continuation and timely completion. Explore the construction site, solve the puzzles, each puzzle solved will bring you one step closer to locating the blueprint. Time is of the essence, we are counting on your team's expertise to recover the blueprint swiftly and ensure our project remains on schedule. Good luck, and let’s bring this project home!",
    inputs: ["community centre/school/hospital"],
  },
];

// This is placeholder content before we get the data from the backend.
// We need a list of descriptions for the first room of each game theme.
export const Room1PlaceHolderContent = [
  {
    themeName: "Education",
    title: "The Waiting Room",
    Room1Description:
      "The waiting room serves as the entry point to the virtual escape room, designed to immerse players in a healthcare-themed environment that mimics the tension and anticipation of a real hospital waiting area.",
    Room1Bullets: [
      "Magazine Code Crack: Search through magazines for hidden clues to unlock a digital file.",
      "Patient List Reconstruction: Piece together a torn patient list to reveal a hidden message about the mysterious ailment.",
      "Digital Clock Riddle: Decode a time-based riddle on the digital clock to discover the combination to a lockbox containing the key to the examination room.",
    ],
    puzzles: "3",
    minutes: "25",
    tQuestions: "X",
    Puzzle1Title: ["Puzzle 1 - Magazine code crack"],
    Puzzle1ChallengeDescription:
      "The players find a shredded patient list that, when correctly reassembled, reveals a coded message essential for their progress. The challenge lies in piecing together the list and deciphering the code hidden within.",
    Puzzle1Bullets: [
      {
        content: "Notebook:",
        children: [
          "Action Required: Find and interact with the notebook",
          "Clue - riddle: \"As winter fades and spring dawns near,\nA monetary moment looms to steer.\nIn this room, where days are shown,\nThe fiscal year's close is quietly known.\"",
          "Solution: The riddle points to the end of the financial year, which is 5th April."
        ]
      },
      {
        content: "Calendar:",
        children: [
          "Action Required: Based on the riddle's hint, examine the calendar on April 5th.",
          "Clue: On 5th April, there are digits \"645\"."
        ]
      },
      {
        content: "TV:",
        children: [
          "Action Required: Based on the riddle's hint, examine the calendar on April 5th.",
          "Next step: Unlocking the TV reveals leads to the next puzzle."
        ]
      },
    ],
    Puzzle2Title: ["Puzzle 2 - Magazine code crack"],
    Puzzle2ChallengeDescription:
      "The players find a shredded patient list that, when correctly reassembled, reveals a coded message essential for their progress. The challenge lies in piecing together the list and deciphering the code hidden within.",
    Puzzle2Bullets: [
      "Find Pieces: Locate all the torn pieces of the patient list scattered around the room.",
      "Reassemble List: Arrange the pieces to form a complete list. This may involve matching the edges of the paper and aligning the text correctly.",
      "Decode Message: Notice the highlighted letters on the reconstructed list. Using the cipher in the medical journal, translate these letters into a coherent message.",
      "Apply Time Clue: Cross-reference the decoded message with the time shown on the digital clock (e.g., 10:15) to align with a specific entry in the list.",
      "Reveal Diagnosis: The correct alignment reveals the patient's name 'John Doe' and the hidden message 'Inverted Pulse.' This indicates the mysterious ailment is a rare condition known as 'Inverted Pulse Syndrome.'",
    ],
    Puzzle3Title: ["Puzzle 3 - Magazine code crack"],
    Puzzle3ChallengeDescription:
      "The players find a shredded patient list that, when correctly reassembled, reveals a coded message essential for their progress. The challenge lies in piecing together the list and deciphering the code hidden within.",
    Puzzle3Bullets: [
      "Find Pieces: Locate all the torn pieces of the patient list scattered around the room.",
      "Reassemble List: Arrange the pieces to form a complete list. This may involve matching the edges of the paper and aligning the text correctly.",
      "Decode Message: Notice the highlighted letters on the reconstructed list. Using the cipher in the medical journal, translate these letters into a coherent message.",
      "Apply Time Clue: Cross-reference the decoded message with the time shown on the digital clock (e.g., 10:15) to align with a specific entry in the list.",
      "Reveal Diagnosis: The correct alignment reveals the patient's name 'John Doe' and the hidden message 'Inverted Pulse.' This indicates the mysterious ailment is a rare condition known as 'Inverted Pulse Syndrome.'",
    ],
  },
  {
    themeName: "Government",
    title: "The Waiting Room",
    Room1Description:
      "The waiting room serves as the entry point to the virtual escape room, designed to immerse players in a healthcare-themed environment that mimics the tension and anticipation of a real hospital waiting area.",
    Room1Bullets: [
      "Magazine Code Crack: Search through magazines for hidden clues to unlock a digital file.",
      "Patient List Reconstruction: Piece together a torn patient list to reveal a hidden message about the mysterious ailment.",
      "Digital Clock Riddle: Decode a time-based riddle on the digital clock to discover the combination to a lockbox containing the key to the examination room.",
    ],
    puzzles: "3",
    minutes: "25",
    tQuestions: "X",
    Puzzle1Title: ["Puzzle 1 - Magazine code crack"],
    Puzzle1ChallengeDescription:
      "The players find a shredded patient list that, when correctly reassembled, reveals a coded message essential for their progress. The challenge lies in piecing together the list and deciphering the code hidden within.",
    Puzzle1Bullets: [
      "Find Pieces: Locate all the torn pieces of the patient list scattered around the room.",
      "Reassemble List: Arrange the pieces to form a complete list. This may involve matching the edges of the paper and aligning the text correctly.",
      "Decode Message: Notice the highlighted letters on the reconstructed list. Using the cipher in the medical journal, translate these letters into a coherent message.",
      "Apply Time Clue: Cross-reference the decoded message with the time shown on the digital clock (e.g., 10:15) to align with a specific entry in the list.",
      "Reveal Diagnosis: The correct alignment reveals the patient's name 'John Doe' and the hidden message 'Inverted Pulse.' This indicates the mysterious ailment is a rare condition known as 'Inverted Pulse Syndrome.'",
    ],
    Puzzle2Title: ["Puzzle 2 - Magazine code crack"],
    Puzzle2ChallengeDescription:
      "The players find a shredded patient list that, when correctly reassembled, reveals a coded message essential for their progress. The challenge lies in piecing together the list and deciphering the code hidden within.",
    Puzzle2Bullets: [
      "Find Pieces: Locate all the torn pieces of the patient list scattered around the room.",
      "Reassemble List: Arrange the pieces to form a complete list. This may involve matching the edges of the paper and aligning the text correctly.",
      "Decode Message: Notice the highlighted letters on the reconstructed list. Using the cipher in the medical journal, translate these letters into a coherent message.",
      "Apply Time Clue: Cross-reference the decoded message with the time shown on the digital clock (e.g., 10:15) to align with a specific entry in the list.",
      "Reveal Diagnosis: The correct alignment reveals the patient's name 'John Doe' and the hidden message 'Inverted Pulse.' This indicates the mysterious ailment is a rare condition known as 'Inverted Pulse Syndrome.'",
    ],
    Puzzle3Title: ["Puzzle 3 - Magazine code crack"],
    Puzzle3ChallengeDescription:
      "The players find a shredded patient list that, when correctly reassembled, reveals a coded message essential for their progress. The challenge lies in piecing together the list and deciphering the code hidden within.",
    Puzzle3Bullets: [
      "Find Pieces: Locate all the torn pieces of the patient list scattered around the room.",
      "Reassemble List: Arrange the pieces to form a complete list. This may involve matching the edges of the paper and aligning the text correctly.",
      "Decode Message: Notice the highlighted letters on the reconstructed list. Using the cipher in the medical journal, translate these letters into a coherent message.",
      "Apply Time Clue: Cross-reference the decoded message with the time shown on the digital clock (e.g., 10:15) to align with a specific entry in the list.",
      "Reveal Diagnosis: The correct alignment reveals the patient's name 'John Doe' and the hidden message 'Inverted Pulse.' This indicates the mysterious ailment is a rare condition known as 'Inverted Pulse Syndrome.'",
    ],
  },
  {
    themeName: "Financial Services",
    title: "Open Space",
    Room1Description:
      "Set in an open space, this room has puzzles that unfold in a clear, linear progression. Starting with a cryptic riddle hidden within a notebook, players unlock a series of interconnected challenges to make it to the next step.",
    Room1Bullets: [
      "The Fiscal Riddle: A riddle leads to a calendar clue, unlocking the TV.",
      "Currency Match: A memory game on the TV pairs flags with currencies.",
      "The Jigsaw Code: Solving a jigsaw puzzle on the TV reveals a mini-fridge code.",
      "The minifridge cans: A note in the mini-fridge help to deciphers a counting puzzle that gives the player the code to access the CFO’s office.",
    ],
    puzzles: "4",
    minutes: "25",
    tQuestions: "X",
    Puzzle1Title: ["Puzzle 1 - Magazine code crack"],
    Puzzle1ChallengeDescription:
      "The players find a shredded patient list that, when correctly reassembled, reveals a coded message essential for their progress. The challenge lies in piecing together the list and deciphering the code hidden within.",
    Puzzle1Bullets: [
      "Find Pieces: Locate all the torn pieces of the patient list scattered around the room.",
      "Reassemble List: Arrange the pieces to form a complete list. This may involve matching the edges of the paper and aligning the text correctly.",
      "Decode Message: Notice the highlighted letters on the reconstructed list. Using the cipher in the medical journal, translate these letters into a coherent message.",
      "Apply Time Clue: Cross-reference the decoded message with the time shown on the digital clock (e.g., 10:15) to align with a specific entry in the list.",
      "Reveal Diagnosis: The correct alignment reveals the patient's name 'John Doe' and the hidden message 'Inverted Pulse.' This indicates the mysterious ailment is a rare condition known as 'Inverted Pulse Syndrome.'",
    ],
    Puzzle2Title: ["Puzzle 2 - Currency / Country flag match"],
    Puzzle2ChallengeDescription:
      "The players find a shredded patient list that, when correctly reassembled, reveals a coded message essential for their progress. The challenge lies in piecing together the list and deciphering the code hidden within.",
    Puzzle2Bullets: [
      "Find Pieces: Locate all the torn pieces of the patient list scattered around the room.",
      "Reassemble List: Arrange the pieces to form a complete list. This may involve matching the edges of the paper and aligning the text correctly.",
      "Decode Message: Notice the highlighted letters on the reconstructed list. Using the cipher in the medical journal, translate these letters into a coherent message.",
      "Apply Time Clue: Cross-reference the decoded message with the time shown on the digital clock (e.g., 10:15) to align with a specific entry in the list.",
      "Reveal Diagnosis: The correct alignment reveals the patient's name 'John Doe' and the hidden message 'Inverted Pulse.' This indicates the mysterious ailment is a rare condition known as 'Inverted Pulse Syndrome.'",
    ],
    Puzzle3Title: ["Puzzle 3 - Magazine code crack"],
    Puzzle3ChallengeDescription:
      "The players find a shredded patient list that, when correctly reassembled, reveals a coded message essential for their progress. The challenge lies in piecing together the list and deciphering the code hidden within.",
    Puzzle3Bullets: [
      "Find Pieces: Locate all the torn pieces of the patient list scattered around the room.",
      "Reassemble List: Arrange the pieces to form a complete list. This may involve matching the edges of the paper and aligning the text correctly.",
      "Decode Message: Notice the highlighted letters on the reconstructed list. Using the cipher in the medical journal, translate these letters into a coherent message.",
      "Apply Time Clue: Cross-reference the decoded message with the time shown on the digital clock (e.g., 10:15) to align with a specific entry in the list.",
      "Reveal Diagnosis: The correct alignment reveals the patient's name 'John Doe' and the hidden message 'Inverted Pulse.' This indicates the mysterious ailment is a rare condition known as 'Inverted Pulse Syndrome.'",
    ],
  },
  {
    themeName: "Healthcare",
    title: "Waiting Room",
    Room1Description:
      "The game starts off in the patient's waiting area of a hospital. The aim of this room is to find a staff ID and a fob key to progress past the 'Staff Only' door. This a parallel puzzle - the players can find the Staff ID or fob key in either order. However, they must find both items in order to progress.",
    Room1Bullets: [
      "Unlock the TV - Decipher a health poster to find the TV passcode and unlock its content.",
      "Toy - Replicate a pattern from the TV on a toy in the children's play area to unlock a compartment with the fob key.",
      "Hidden code in the magazine rack - Solve a riddle indicating a numeric code in the magazine rack to use on the vending machine.",
      "The toy box - Use the vending machine code to retrieve a key that unlocks the toy box, revealing the staff ID.",
    ],
    puzzles: "4",
    minutes: "25",
    tQuestions: "X",
    Puzzle1Title: ["Puzzle 1 - Unlock the TV"],
    Puzzle1ChallengeDescription:
      "The players must find the passcode for the TV in order to gain access and unlock its content. On the wall there's a health poster which must be deciphered. The passcode for the TV is hidden in the poster's content '1238'.",
    Puzzle1Bullets: [
      {
        content: "Notebook:",
        children: [
          "Action Required: Find and interact with the notebook",
          "Clue - riddle: \"As winter fades and spring dawns near,\nA monetary moment looms to steer.\nIn this room, where days are shown,\nThe fiscal year's close is quietly known.\"",
          "Solution: The riddle points to the end of the financial year, which is 5th April."
        ]
      },
      {
        content: "Calendar:",
        children: [
          "Action Required: Based on the riddle's hint, examine the calendar on April 5th.",
          "Clue: On 5th April, there are digits \"645\"."
        ]
      },
      {
        content: "TV:",
        children: [
          "Action Required: Based on the riddle's hint, examine the calendar on April 5th.",
          "Next step: Unlocking the TV reveals leads to the next puzzle."
        ]
      },
    ],
    Puzzle2Title: ["Puzzle 2 - Toy"],
    Puzzle2ChallengeDescription:
      "Once the players unlock the TV, an image appears with various shapes in a  6 x 6 order, along with an arrow pointing right and ‘x 3’ next to it. If the players click around the room, they’ll come across a toy in the children’s play area. The players need to repeat the pattern on the TV on the children’s toy. This will unlock a secret compartment which holds the fob key.",
    Puzzle2Bullets: [
      "Action Required: After unlocking the TV, the players will see an image of different shapes in a 6 x 6 grid, alongside an arrow pointing right and ‘x 3’. This screen is not interactive.",
      "Action Required: if the players click around the room, they’ll find a children’s toy in the play area. The toy has an empty 6 x 6 grid, and various different shapes lying next to it. There are also left and right buttons under the grid. Players must drag the right shapes into the correct positions on the grid. They must then press the right arrow 3 times. This will spin the grid around 3 times.",
      "Outcome: Following this sequence will unlock a secret compartment in the toy. Inside is a fob key which is added to the player’s inventory and will help them unlock the ‘Staff Only’ door.",
    ],
    Puzzle3Title: ["Puzzle 3 - Hidden code in the magazine rack"],
    Puzzle3ChallengeDescription:
      "The players will see a small key inside the vending machine. This puzzle will give them a numeric code which they can then use on the machine. They will find a riddle on a post-it note, which will indicate that the code is hidden in the magazine rack.",
    Puzzle3Bullets: [
      "Action Required: Find the post-it note on the chair and decipher the riddle.",
      "Riddle: 'Within headlines and titles, numbers hide, Find their sequence, and let them guide. The first is last, and the last will be first, Use the machine that quenches your thirst.'",
      "Outcome: The riddle indicates that the players need to find the last and first titles in the magazine rack to crack the code for the vending machine. Each magazine has a number next to its title. The first and last magazine numbers need to be inputted into the vending machine in reverse order. The code is therefore: 991",
    ],
  },
];

// This is placeholder content before we get the data from the backend.
// We need a list of descriptions for the examination room of each game theme.
export const examinationRoomPlaceHolderContent = {
  examinationRoomDescription:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  examinationRoomBullets: [
    "Puzzle 1: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Puzzle 2: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Puzzle 3: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  ],
  puzzles: "3",
  minutes: "15",
  tQuestions: "0",
};

// This is placeholder content before we get the data from the backend.
// We need a list of descriptions for the examination room of each game theme.
export const Room3PlaceHolderContent = {
  Room3Titles: ["The procedure room"],
  Room3Description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  Room3Bullets: [
    "Puzzle 1: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Puzzle 2: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Puzzle 3: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  ],
  puzzles: "3",
  minutes: "15",
  tQuestions: "0",
};

export const PuzzlePlaceHolderContent = {
  PuzzleTitles: ["Puzzle 1 - Magazine code crack"],
  PuzzleChallengeDescription:
    "The players find a shredded patient list that, when correctly reassembled, reveals a coded message essential for their progress. The challenge lies in piecing together the list and deciphering the code hidden within.",
  PuzzleBullets: [
    "Find Pieces: Locate all the torn pieces of the patient list scattered around the room.",
    "Reassemble List: Arrange the pieces to form a complete list. This may involve matching the edges of the paper and aligning the text correctly.",
    "Decode Message: Notice the highlighted letters on the reconstructed list. Using the cipher in the medical journal, translate these letters into a coherent message.",
    "Apply Time Clue: Cross-reference the decoded message with the time shown on the digital clock (e.g., 10:15) to align with a specific entry in the list.",
    "Reveal Diagnosis: The correct alignment reveals the patient's name 'John Doe' and the hidden message 'Inverted Pulse.' This indicates the mysterious ailment is a rare condition known as 'Inverted Pulse Syndrome.'",
  ],
};

export const OutroPlaceHolderContent = {
  OutroDescription:
    "Once the player successfully ends finishes the game, this message will appear to congratulate the user.",
};

type menuItemChildProps = {
  content: {
    id: integer;
    puzzleName: string;
    trainingQuestions: {
      id: integer;
      title: string;
      duration: integer;
    }[];
  };
  prefixIcon?: string;
};

export type menuItemProps = {
  id?: integer;
  content: string | JSX.Element;
  disabled?: boolean;
  duration: integer;
  prefixIcon?: string;
  suffixIcon?: string;
  suffixExpandedIcon?: string;
  children?: menuItemChildProps[];
};

export // Menu items structure with dummy content
const menuItems: menuItemProps[] = [
  {
    content: "Introduction",
    duration: 2,
    prefixIcon: "comment",
    suffixIcon: "pencil",
  },
  {
    content: "Game mission",
    duration: 2,
    prefixIcon: "comment",
    suffixIcon: "pencil",
  },
  {
    id: 1,
    content: "The waiting room",
    duration: 19,
    prefixIcon: "door-opened",
    suffixExpandedIcon: "up-chevron",
    suffixIcon: "down-chevron",
    children: [
      {
        content: {
          id: 1,
          puzzleName: "Puzzle 1",
          trainingQuestions: [
            {
              id: 1,
              title: "Training question 1 Training question 1",
              duration: 1,
            },
            {
              id: 2,
              title: "Training question 2",
              duration: 1,
            },
          ],
        },
      },
      {
        content: {
          id: 2,
          puzzleName: "Puzzle 2",
          trainingQuestions: [],
        },
        prefixIcon: "particulates",
      },
      {
        content: {
          id: 3,
          puzzleName: "Puzzle 3",
          trainingQuestions: [],
        },
        prefixIcon: "particulates",
      },
    ],
  },
  {
    id: 2,
    content: "The examination room",
    duration: 15,
    prefixIcon: "door-opened",
    suffixExpandedIcon: "up-chevron",
    suffixIcon: "down-chevron",
    children: [
      {
        content: {
          id: 4,
          puzzleName: "Puzzle 1",
          trainingQuestions: [],
        },
        prefixIcon: "particulates",
      },
    ],
  },
  {
    id: 3,
    content: "The procedure room",
    duration: 15,
    disabled: true,
    prefixIcon: "door-opened",
    suffixExpandedIcon: "up-chevron",
    suffixIcon: "down-chevron",
    children: [
      {
        content: {
          id: 5,
          puzzleName: "Puzzle 1",
          trainingQuestions: [],
        },
        prefixIcon: "particulates",
      },
    ],
  },
  {
    content: "Outro",
    duration: 2,
    prefixIcon: "comment",
    suffixIcon: "pencil",
  },
];

// Phaser Config
export const SCREEN_WIDTH = 1440;
export const SCREEN_HEIGHT = 864;
export const HEADER_HEIGHT = 54;
export const SCREEN_CENTER_X = SCREEN_WIDTH / 2;
export const SCREEN_CENTER_Y =
  HEADER_HEIGHT + (SCREEN_HEIGHT - HEADER_HEIGHT) / 2;

export const Directions: {
  FORWARD: TObjectOrderDirection;
  BACKWARD: TObjectOrderDirection;
  FRONT: TObjectOrderDirection;
  BACK: TObjectOrderDirection;
} = {
  FORWARD: "forward",
  BACKWARD: "backward",
  FRONT: "front",
  BACK: "back",
};

export const modalTextChoices: Array<string> = [
  "All rather quiet over here",
  "Nothing to see here",
  "Keep going, you're doing great",
  "Not quite, try again",
  "Have a look at…",
  "Have you considered…",
  "Oops, that's not right",
  "You're getting warmer",
];

export const imagePopupChoices: Array<string> = [
  "calendar",
  "cans",
  "fs3-note",
  "note-london",
  "note-newyork",
  "notebook",
  "paintings-default",
  "paintings-hint",
  "post-it-mug",
  "security-team",
];

export const unlockImageChoices: Array<string> = [
  "fs-cfo-door",
  "fs-checkerboard",
  "fs-mini-fridge",
  "fs-safebox",
  "fs-shelf",
  "fs-tv",
  "fs-vault",
  "fs-word-search"
]


export const puzzleChoices: Array<string> = [
  "Financial Services Checkers",
  "Financial Services Clocks",
  "Financial Services Door Handle",
  "Financial Services Jigsaw",
  "Financial Services Keyhooks",
  "Financial Services Memo Game",
  "Financial Services Mini Fridge Keypad",
  "Financial Services Safebox",
  "Financial Services TV Code",
  "Financial Service Vault Keypad",
  "Financial Services Word Search",
];

export const avatarColors: Array<number> = [
  0x1a2a5a, 0xc52a1a, 0x4b4226, 0xd04a02, 0x6b2cda, 0x203570, 0x822720,
  0x786124, 0xe86153, 0x4b20ab,
];

export const emailPlaceholders: Array<Partial<Player>> = [
  {
    id: "1",
    clientId: "1",
    firstName: "Mary",
    lastName: "Poppins",
    color: avatarColors[Math.ceil(Math.random() * avatarColors.length)],
  },
  {
    id: "2",
    clientId: "2",
    firstName: "Joe",
    lastName: "Smithson",
    color: avatarColors[Math.ceil(Math.random() * avatarColors.length)],
  },
  {
    id: "3",
    clientId: "3",
    firstName: "Stephen",
    lastName: "Guarriquez",
    color: avatarColors[Math.ceil(Math.random() * avatarColors.length)],
  },
];

export const generateFakePlayers = (count: number): Array<Player> => {
  const players: Array<Player> = [];
  for (let i = 0; i < count; i++) {
    players.push(
      new Player({
        id: `${i}`,
        clientId: `${i}`,
        firstName: `${createRandomString(4)}`,
        lastName: `${createRandomString(4)}`,
        color: avatarColors[Math.ceil(Math.random() * avatarColors.length)],
        score: 0,
        inactive: false,
      })
    );
  }
  return players;
};

export const autosaveInterval = 60000;

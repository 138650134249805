import { IRoomObject } from "escape-rooms-types/types/game";
import EventBridge from "@/utils/EventBridge";
import BaseRoomObject from "./BaseRoomObject";
import PreviewScene from "@/phaser/scenes/PreviewScene";
import { TextModalPayload } from "../modals/ModalPayloads";
import PreviewAction from "../actions/PreviewAction";
import { areArraysEqual } from "@/utils/helpers";
import { HEADER_HEIGHT } from "@/constants";

export default class PreviewRoomObject extends BaseRoomObject {
  public scene: PreviewScene;
  public actions: PreviewAction[];

  constructor(
    scene: PreviewScene,
    xPos: number,
    yPos: number,
    gameObject: IRoomObject
  ) {
    yPos = yPos + HEADER_HEIGHT;

    super(scene, xPos, yPos, gameObject);
    this.scene = scene;
    this.actions = [];
    this.loadActions();
    this.addEventListeners();
  }

  public addToScene(x: number, y: number) {
    this.sprite = this.scene.add.sprite(
      x,
      y,
      this.getTextureFromState(this.currentState)
    );
    this.sprite.setName(this.gameObject.ref);
    this.setVisible(this.gameObject.isVisible);
    return this.sprite;
  }

  public getTextureFromState(state: string) {
    if (
      areArraysEqual(
        this.gameObject.colorPalette,
        this.gameObject.asset.defaultColorPalette
      )
    ) {
      return `${this.gameObject.asset.states[state].textureUrl}_scene`;
    }

    const textureKey =
      `${this.ref}_${this.gameObject.asset.states[state].name}_scene`.toLowerCase();
    return textureKey;
  }

  public getThumbnailFromState(state: string) {
    if (
      areArraysEqual(
        this.gameObject.colorPalette,
        this.gameObject.asset.defaultColorPalette
      )
    ) {
      return `${this.gameObject.asset.states[state].textureUrl}_thumbnail`;
    }

    const textureKey =
      `${this.ref}_${this.gameObject.asset.states[state].name}_thumbnail`.toLowerCase();
    return textureKey;
  }

  public executeAction() {
    this.actions[this.currentAction].executeAction();
  }

  public setVisible(isVisible: boolean) {
    if (isVisible) {
      this.isVisible = true;
      this.sprite.alpha = 1;
    }
    if (!isVisible) {
      this.isVisible = false;
      this.sprite.alpha = 0;
    }

    EventBridge.emit("minimap.redraw");
  }

  public setIsDisabled(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.sprite.disableInteractive();
    }
  }

  public focus() {
    // when selected
  }

  public blur() {
    // when deselected
  }

  public completeAction(executeNextAction: boolean = true) {
    this.currentAction += 1;
   
    if (this.actions[this.currentAction] == null) {
      // this.sprite.disableInteractive();
      return;
    }

    if (this.actions[this.currentAction].trigger === "afterPrevious" && executeNextAction) {
      this.executeAction();
    }

    return;
  }

  public incrementProgress() {
    EventBridge.emit("preview.incrementActionsDone");
  }

  private loadActions() {
    if (this.gameObject.actions == null || this.gameObject.actions.length === 0) {
      this.sprite.disableInteractive();
      return;
    }

    this.sprite.setInteractive();
    this.gameObject.actions.forEach((action) =>
      this.actions.push(
        new PreviewAction(
          this.scene,
          this,
          action.score,
          action._id!,
          action.ref,
          action.trigger!,
          action.type!,
          action.changeObjectPayload,
          action.modalPayload,
          action.questionnaire,
          action.triggerItemRef,
          action.mediaPayload,
          action.repeatable,
          action.stateBasedPayload
        )
      )
    );
  }

  private addEventListeners() {
    this.sprite.on("pointerover", () => this.glowIfInteractive());
    this.sprite.on("pointerout", () => this.removeGlow());
    this.sprite.on("pointerdown", () => {
      this.removeGlow();
      const action = this.actions[this.currentAction];

      if (
        this.scene.itemBeingUsed != null &&
        this.scene.itemBeingUsed.gameObject.ref != action?.triggerItemRef
      ) {
        EventBridge.emit("ui.openMessagePopup", {
          text: "This doesn't seem to do anything",
          sourceObject: this,
        } satisfies TextModalPayload);

        this.scene.itemBeingUsed = undefined;
        EventBridge.emit("ui.stopUsingItem");
        return;
      }

      if (action == null) {
        console.log(`No actions remaining on ${this.ref}`);
        return;
      }

      if (
        action.trigger === "onItemUse" &&
        this.scene.itemBeingUsed != null &&
        this.scene.itemBeingUsed.ref === action.triggerItemRef
      ) {
        this.executeAction();
        EventBridge.emit("ui.removeFromInventory", action.triggerItemRef);
      }

      if (action.trigger === "onClick") {
        this.executeAction();
        return;
      }

      // This code makes sure that the an object's action chain doesn't get stuck if
      // a player exits a modal.
      if (action.trigger === "afterPrevious") {
        this.executeAction();
        return;
      }
    });
  }

  public hintUsed() {
    const glowEffect = setInterval(() => {
      setTimeout(() => {
        this.addGlow();
      }, 500);
      this.removeGlow();
    }, 1000);

    setTimeout(() => {
      clearInterval(glowEffect);

      setTimeout(() => {
        this.removeGlow();
      }, 1000);
    }, 3000);
  }

  private removeEventListeners() {}

  public setUsingItem() {
    EventBridge.emit("preview.setUsingItem", this);
  }

  public destroy(): void {
    super.destroy();
    this.removeEventListeners();
  }
}

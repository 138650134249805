import {
  Button,
  Modal,
  Tag,
  Loading,
  Notification,
  Notice,
  toaster,
  Tooltip,
} from "@appkit4/react-components";
import { useNavigate } from "react-router-dom";
import styles from "../game-mission/GameMission.module.scss";
import { useState, useEffect } from "react";
import { useGameStore } from "@/store/game-store";
import { useQuery } from "react-query";
import { getGame } from "@/api/game";
import GameEditorSidebar from "@/components/game-editor-sidebar/GameEditorSidebar";
import GameTabs from "../game-tabs/GameTabs";
import { durations, OutroPlaceHolderContent } from "@/constants";

const Outro = () => {
  const navigate = useNavigate();
  const name = useGameStore((state) => state.game.name);
  const status = useGameStore((state) => state.game.status);
  const rooms = useGameStore((state) => state.game.rooms);
  const { setGame } = useGameStore((state) => state);
  const [isIntroActive, setIsIntroActive] = useState(true);

  const handleSavedClose = () => {
    console.log("Closed!");
  };

  const handleNoticeClose = () => {
    console.log("Closed!");
  };

  const savedIntroActive = (
    <Notification
      title=""
      message={<div>Your introduction has been added into the game</div>}
      status="success"
    ></Notification>
  );

  const savedIntroInactive = (
    <Notification
      title=""
      message={<div>Your introduction has been removed from the game</div>}
      status="success"
    ></Notification>
  );

  const roomNotice = (
    <Notice
      title="Note"
      message="You can only add this room if you have already added room 2"
      status="icon-information-outline"
      onClose={handleNoticeClose}
      style={{ width: "916px", height: "64px" }}
    ></Notice>
  );

  const roomGameTimeNotice = (
    <Notice
      title="Note before adding this room"
      message="Adding this room will extend your game time by 15 minutes."
      status="icon-information-outline"
      onClose={handleNoticeClose}
      style={{ width: "916px", height: "64px" }}
    ></Notice>
  );

  const formatListItem = (text) => {
    const [boldPart, ...rest] = text.split(":");
    return (
      <>
        <strong>{boldPart}:</strong>
        {rest.join(":")}
      </>
    );
  };

  const handleSaveClick = () => {
    if (isIntroActive) {
      toaster.notify(savedIntroActive, {
        position: "topHeader",
        onClose: handleSavedClose,
      });
    } else {
      toaster.notify(savedIntroInactive, {
        position: "topHeader",
        onClose: handleSavedClose,
      });
    }
  };

  const activeRooms = rooms.filter((room) => room.isActive && room.isVisible);

  const loadGame = (gameData: any) => {
    if (gameData == null) {
      return;
    }
    setGame(gameData);
  };
  const gameId = location.pathname.split("/").at(-2);
  const getGameQuery = useQuery({
    queryKey: ["getGame"],
    queryFn: () => getGame(gameId!),
    onSuccess: (res) => {
      loadGame(res.data.data);
    },
  });

  useEffect(() => {
    getGameQuery.refetch();
  }, []);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedSubIndex, setSelectedSubIndex] = useState(0);

  const getGameDuration = (numberOfRooms: number) => {
    const duration = durations.find(
      (d) => numberOfRooms == parseInt(d.rooms.split("")[0])
    );
    if (duration) {
      return duration.name;
    }
    return "";
  };

  const getNumberOfPuzzle = (numberOfRooms: number) => {
    const duration = durations.find(
      (d) => numberOfRooms == parseInt(d.rooms.split("")[0])
    );
    if (duration) {
      const { puzzles } = duration;
      return puzzles;
    }
    return "";
  };

  const data = (
    <div className="textEditorDiv">"Lorem ipsum dolor sit amet"</div>
  );
  const sampleConfig = {
    toolbar: [
      "fontFamily",
      "fontSize",
      "bold",
      "italic",
      "alignment:left",
      "alignment:center",
      "alignment:right",
      "alignment:justify",
    ],
  };

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={styles.headerContainer}>
          <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">
            Outro
          </h1>
        </div>
        <div className={styles.inlineSpans}></div>
        <p className={`ap-typography-body`} style={{ marginBottom: "20px" }}>
          {OutroPlaceHolderContent.OutroDescription}
        </p>
        <div className={styles.divider}></div>
      </div>

      <Modal
        visible={false}
        title={"Remove room?"}
        ariaLabel={"Remove room?"}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
          marginTop: "-8px",
          minHeight: "64px",
        }}
        header={""}
        icons={""}
        footer={
          <>
            <Button onClick={() => {}} kind="secondary">
              Cancel
            </Button>
            <Button onClick={() => {}}>Remove room</Button>
          </>
        }
        bodyStyle={{ minHeight: "92px" }}
      >
        <p>
          This room will be hidden from players upon removal. Rest assured, any
          training questions added will be safely stored.
          <br />
          <br />
          Please move content to other rooms before disabling if needed.
          <br />
          <br />
          Re-enabling later will restore all content as you left it, ready for
          edits or use.
          <br />
        </p>
      </Modal>
    </>
  );
};

export default Outro;

import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import CloseButton from "../../ui/CloseButton";
import PopupBackground from "../../ui/PopupBackground";
import HintBar from "../../ui/HintBar";
import { SCREEN_CENTER_X, SCREEN_CENTER_Y } from "@/constants";
import PreviewRoomObject from "../../RoomObject/PreviewRoomObject";
import GameRoomObject from "../../RoomObject/GameRoomObject";
import EventBridge from "@/utils/EventBridge";


export default class WordSearch extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly callback: () => void;
  private readonly popupBackground: PopupBackground;
  private readonly buttonTextControls: Array<Phaser.GameObjects.Text> = [];
  private readonly characterTextControls: Array<Phaser.GameObjects.Text> = [];
  private readonly buttonGraphics: Array<Phaser.GameObjects.Graphics> = [];
  private readonly borderGraphics: Array<Phaser.GameObjects.Graphics> = [];
  private readonly correctAnswerIcons: Array<Phaser.GameObjects.Image> = [];
  private readonly incorrectAnswerIcons: Array<Phaser.GameObjects.Image> = [];
  private readonly closeButton: CloseButton;
  private readonly hintBar: HintBar;
  private readonly timerEvents: Phaser.Time.TimerEvent[] = [];

  private readonly INITIAL_BUTTON_COLOR = 0x000000;
  private readonly ALTERNATE_BUTTON_COLOR = 0x7288a2;
  private readonly HINT_MESSAGE: string = "Find the 2 words in the grid. They should spell out a 5 word city and 4 letter number.";

  private currentIndex: number = 0;

  private readonly ANSWERS: Array<string> = ["PARIS", "FOUR"];
  private readonly VALID_ANSWER = this.ANSWERS[0] + this.ANSWERS[1];
  private readonly answerDetails = [
    {
      row: 2, 
      column: 6, 
      orientation: "vertical"
    },
    {
      row: 4,
      column: 1,
      orientation: "horizontal"
    }
  ];

  private readonly WIDTH = 640;
  private readonly HEIGHT = 698;
  private readonly PADDING = 33;

  private readonly rows: Array<string> = [
    'osirnnstq',
    'zcjhhcomc',
    'cpvhsppyz',
    'uclvdmabq',
    'zfourermi',
    'lptmjdiib',
    'rmuxraspv',
  ];

  private readonly gridConfiguration = {
    x: 0,
    y: 0,
    paddingX: 0,
    paddingY: 3,
    cardWidth: 64, 
    cardHeight: 64,
    numberOfColumns: 9,
    buttonRadius: 0,
    includeZero: true,
    buttonTextFontStyle: {
        fontFamily: "Arial",
        fontSize: "24px",
        color: "#ffffff",
        align: "center",
        fontStyle: "bold",
    },
    buttonTextAlternateColor: "#000000"
  };

  private readonly numbersDisplayConfiguration = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    radius: 4,
    backgroundColor: {
      default: 0xffffff,
      correct: "green",
      incorrect: "red"
    },
    fontStyle: {
      fontFamily: "Arial",
      fontSize: "48px",
      color: "#202942",
      align: "left",
      fontStyle: "bold",
    },
    letterSpacing: 15 
  };

  constructor(
    scene: UIScene,
    text: string,
    sourceObject: BaseRoomObject,
    callback: () => void = () => {}
  ) {
    super(scene, 0, 0);
    this.scene = scene;
    this.sourceObject = sourceObject;
    this.callback = callback;

    this.popupBackground = new PopupBackground(scene);
    this.add(this.popupBackground);

    this.hintBar = new HintBar(scene, this.HINT_MESSAGE);
    this.add(this.hintBar);

    this.closeButton = new CloseButton(this.scene, () => this.closeModal());
    this.add(this.closeButton);

    this.gridConfiguration.x = SCREEN_CENTER_X - this.WIDTH / 2 + this.PADDING;
    this.gridConfiguration.y = SCREEN_CENTER_Y - this.HEIGHT / 2 + this.PADDING;

    this.numbersDisplayConfiguration.x = this.gridConfiguration.x;
    this.numbersDisplayConfiguration.y = this.gridConfiguration.y;
    this.numbersDisplayConfiguration.width = 208;
    this.numbersDisplayConfiguration.height = 80;

    const keypadBackground = new Phaser.GameObjects.Graphics(this.scene);
    keypadBackground.fillStyle(0x000000, 1); 
    keypadBackground.fillRoundedRect(
      this.gridConfiguration.x - this.PADDING, 
      this.gridConfiguration.y - this.PADDING,
      this.WIDTH , 
      this.HEIGHT,
      0);
    
    this.add(keypadBackground);

    // const image = new Phaser.GameObjects.Image(scene, SCREEN_CENTER_X, SCREEN_CENTER_Y, "crossword");
    // this.add(image);

    this.initializeButtons();
    this.initializeTextArea();

    this.scene.input.keyboard?.on("keydown", this.keydownCallback);

    this.scene.add.existing(this);
  }

  private updateCodeText(number: string) {
    if (this.currentIndex < this.characterTextControls.length) {
      this.characterTextControls[this.currentIndex++].text = number;
      this.checkIfComplete();
    }
  }

  private initializeTextArea() {
    this.initializeTextAreaForAnswer(0, 0);
    this.initializeTextAreaForAnswer(1, 80);

    
    for (const charTextBox of this.characterTextControls) {
      charTextBox.text = '';
    }
  }

  private initializeTextAreaForAnswer(answerNumber: number, yPadding: number) {
    for (let i = 0; i < this.ANSWERS[answerNumber].length; i++) {
      const [x, y] = this.getCharacterXY(i, this.ANSWERS[answerNumber].length);
      
      const characterText = new Phaser.GameObjects.Text(
        this.scene, 
        x, 
        y + yPadding,
        this.ANSWERS[answerNumber].charAt(i), 
        this.numbersDisplayConfiguration.fontStyle
      ).setOrigin(0.5, 0);

      this.characterTextControls.push(characterText);

      const borderPadding = 30;
      const center = characterText.getCenter();

      const backgroundX = center.x! - borderPadding;
      const backgroundY = center.y! - borderPadding;
      const width = 2 * borderPadding;
      const height = 2 * borderPadding;

      const characterBackgroudGraphics = new Phaser.GameObjects.Graphics(this.scene);
      characterBackgroudGraphics.fillStyle(0xffffff, 1);
      characterBackgroudGraphics.fillRoundedRect(backgroundX, backgroundY, width, height, 5);
      
      this.add(characterBackgroudGraphics);
      this.add(characterText);      
    }

    const lastTextBox = this.characterTextControls[this.characterTextControls.length - 1];

    const answerIconCorrect = new Phaser.GameObjects.Image(
      this.scene, 
      lastTextBox.getRightCenter().x! + 50,
      lastTextBox.getRightCenter().y!,
      "answer-correct").setOrigin(0.5);
    
    answerIconCorrect.visible = false;
    this.add(answerIconCorrect);
    this.correctAnswerIcons.push(answerIconCorrect);

    const answerIconWrong = new Phaser.GameObjects.Image(
      this.scene, 
      lastTextBox.getRightCenter().x! + 50,
      lastTextBox.getRightCenter().y!,
      "answer-wrong").setOrigin(0.5);

    answerIconWrong.visible = false;
    this.add(answerIconWrong);
    this.incorrectAnswerIcons.push(answerIconWrong);

    this.initializeBorderForAnswer(answerNumber);
  }

  private initializeBorderForAnswer(answerNumber: number) {
    const answerDetails = this.answerDetails[answerNumber];
    const answerLenght = this.ANSWERS[answerNumber].length;
    
    const firstLetterTextBoxIndex = answerDetails.row * this.gridConfiguration.numberOfColumns + answerDetails.column;
    const lastLetterTextBoxIndex = answerDetails.orientation === "horizontal" 
      ? firstLetterTextBoxIndex + answerLenght - 1
      : firstLetterTextBoxIndex + (answerLenght - 1) * this.gridConfiguration.numberOfColumns;
    const topLeft = this.buttonTextControls[firstLetterTextBoxIndex].getTopLeft();
    const bottomRight = this.buttonTextControls[lastLetterTextBoxIndex].getBottomRight();

    const borderPadding = 20;

    const x = topLeft.x! - borderPadding;
    const y = topLeft.y! - borderPadding;
    const width = bottomRight.x! - topLeft.x! + 2 * borderPadding;
    const height = bottomRight.y! - topLeft.y! + 2 * borderPadding;
      
    const borderGraphics = new Phaser.GameObjects.Graphics(this.scene);
    borderGraphics.lineStyle(5, 0x21812d);
    borderGraphics.strokeRect(x, y, width, height);
    borderGraphics.setVisible(false);
    this.borderGraphics.push(borderGraphics);
    
    this.add(borderGraphics);
  }

  private getCharacterXY = (index: number, answerLength: number) => {
    const MARGIN = 80;
    const centerX = answerLength % 2 == 0 ? SCREEN_CENTER_X + MARGIN / 2 : SCREEN_CENTER_X;
    const middleLetter = Math.floor(answerLength / 2);
    const tempIndex = middleLetter - index;
    
    const x = centerX - tempIndex * 80;
    const y = this.gridConfiguration.y + 504;
    return [x, y];
  };

  private initializeButtons() {
    for (let i = 0; i < this.gridConfiguration.numberOfColumns * this.rows.length; i++) {
      const x = this.gridConfiguration.x + (this.gridConfiguration.cardWidth + this.gridConfiguration.paddingX) * (i % this.gridConfiguration.numberOfColumns);
      const y = this.gridConfiguration.y + (this.gridConfiguration.cardHeight + this.gridConfiguration.paddingY) * Math.floor(i / this.gridConfiguration.numberOfColumns);
      
      const rowNumber = Math.floor(i / this.gridConfiguration.numberOfColumns);
      const colNumber = i % this.rows[rowNumber].length;
      const currentCharacter = this.rows[rowNumber].charAt(colNumber).toUpperCase();

      const buttonBackground = new Phaser.GameObjects.Graphics(this.scene);
      buttonBackground.fillStyle(this.INITIAL_BUTTON_COLOR);
      buttonBackground.fillRoundedRect(x, y, this.gridConfiguration.cardWidth, this.gridConfiguration.cardHeight, 0);
      buttonBackground.setInteractive(new Phaser.Geom.Rectangle(x, y, this.gridConfiguration.cardWidth, this.gridConfiguration.cardHeight), Phaser.Geom.Rectangle.Contains);      
      this.buttonGraphics.push(buttonBackground);

      const buttonText = new Phaser.GameObjects.Text(
        this.scene, 
        x + this.gridConfiguration.cardWidth / 2, 
        y + this.gridConfiguration.cardHeight / 2,
        currentCharacter.toString(),
        this.gridConfiguration.buttonTextFontStyle
       )
        .setOrigin(0.5)
        .setName(currentCharacter.toString())
        .setInteractive();

      this.buttonTextControls.push(buttonText);

      buttonText.on(Phaser.Input.Events.POINTER_DOWN, () => {
        this.updateCodeText(buttonText.name);
      });

      buttonBackground.on(Phaser.Input.Events.POINTER_DOWN, () => {
        this.updateCodeText(buttonText.name);
      });

      buttonText.on(Phaser.Input.Events.POINTER_OVER, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.ALTERNATE_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(x, y, this.gridConfiguration.cardWidth, this.gridConfiguration.cardHeight, this.gridConfiguration.buttonRadius);
        buttonText.setColor(this.gridConfiguration.buttonTextAlternateColor);
      });

      buttonText.on(Phaser.Input.Events.POINTER_OUT, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.INITIAL_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(x, y, this.gridConfiguration.cardWidth, this.gridConfiguration.cardHeight, this.gridConfiguration.buttonRadius);
        buttonText.setColor(this.gridConfiguration.buttonTextFontStyle.color);
      });

      buttonBackground.on(Phaser.Input.Events.POINTER_OVER, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.ALTERNATE_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(x, y, this.gridConfiguration.cardWidth, this.gridConfiguration.cardHeight, this.gridConfiguration.buttonRadius); 
        buttonText.setColor(this.gridConfiguration.buttonTextAlternateColor);
      });

      buttonBackground.on(Phaser.Input.Events.POINTER_OUT, () => {
        buttonBackground.clear();
        buttonBackground.fillStyle(this.INITIAL_BUTTON_COLOR);
        buttonBackground.fillRoundedRect(x, y, this.gridConfiguration.cardWidth, this.gridConfiguration.cardHeight, this.gridConfiguration.buttonRadius);
        buttonText.setColor(this.gridConfiguration.buttonTextFontStyle.color);
      });
    }

    this.add(this.buttonGraphics);
    this.add(this.buttonTextControls);
  }

  private checkIfComplete() {
    let currentCode = "";

    for (let i = 0; i < this.characterTextControls.length; i++) {
      currentCode += this.characterTextControls[i].text;
    }

    if (currentCode.length >= this.ANSWERS[0].length && currentCode.length < this.VALID_ANSWER.length) {  
      const answerIndex = 0;
      const answer1 = currentCode.substring(0, this.ANSWERS[answerIndex].length);

      if (answer1 === this.ANSWERS[answerIndex]) {
        this.correctAnswerIcons[answerIndex].setVisible(true);
        this.borderGraphics[answerIndex].setVisible(true);
      }
      else {
        this.incorrectAnswerIcons[answerIndex].setVisible(true);
      }
    } 
    else if (currentCode.length === this.VALID_ANSWER.length) {
      const answerIndex = 1;
      const answer2 = currentCode.substring(this.ANSWERS[0].length, this.VALID_ANSWER.length);

      if (answer2 === this.ANSWERS[answerIndex]) {
        this.correctAnswerIcons[answerIndex].setVisible(true);
        this.borderGraphics[answerIndex].setVisible(true);
      }
      else {
        this.incorrectAnswerIcons[answerIndex].setVisible(true);
      }
    }

    const isComplete = currentCode === this.VALID_ANSWER;

    if (isComplete) {
      this.closeButton.visible = false;
      this.complete();
    }
  }

  private keydownCallback = (e: KeyboardEvent) => {
    const value = e.key.toLowerCase();
    const isInRows = this.rows.some(row => row.includes(value));

    if (isInRows) {
      this.updateCodeText(e.key.toUpperCase());
    }
  }

  private complete() {
    this.scene.hud.setHintButtonVisible(false);
    const timerEvent = this.scene.time.delayedCall(2000, this.completeAction);
    this.timerEvents.push(timerEvent);
  }

  private completeAction = () => {
    if (this.scene.env === "preview") {
      (this.sourceObject as PreviewRoomObject).incrementProgress();
      (this.sourceObject as PreviewRoomObject).completeAction();
    } else {
      EventBridge.emit("game.puzzleEnded");
      //(this.sourceObject as GameRoomObject).incrementProgress();
      (this.sourceObject as GameRoomObject).completeAction(this.scene.sourceScene.user.id!);
    }

    this.closeModal(false);
  }

  private closeModal(hideHintButton: boolean = true) {
    if (hideHintButton) {
      this.scene.hud.setHintButtonVisible(false);
    }

    this.scene.time.removeEvent(this.timerEvents);
    this.scene.input.keyboard?.removeListener("keydown", this.keydownCallback);
    this.scene.makeGameSceneInteractive();
    this.destroy();
  }
}

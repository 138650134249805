import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../../RoomObject/BaseRoomObject";
import { IQuestion } from "escape-rooms-types/types/game";
import AnswerChoice from "./AnswerChoice";
import { ILayoutConfiguraion, QuestionLayoutConfigurationFactory, QuestionLayoutType } from "./QuestionLayout";


export default class Question extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly correctAnswerSelectedCallback: () => void;
  private readonly incorrectAnswerSelectedCallback: () => void;

  private readonly questionImage: Phaser.GameObjects.Image;
  private readonly questionHeader: Phaser.GameObjects.Text;
  private readonly questionTitle: Phaser.GameObjects.Text;
  private readonly answers: AnswerChoice[] = [];
  private readonly layoutFactory: QuestionLayoutConfigurationFactory;

  private questionIndex!: number;
  private question: IQuestion;

  constructor(
    scene: UIScene,
    sourceObject: BaseRoomObject,
    correctAnswerSelectedCallback: () => void,
    incorrectAnswerSelectedCallback: () => void
  ) {
    super(scene, 0, 0);

    this.scene = scene;
    this.sourceObject = sourceObject;
    this.correctAnswerSelectedCallback = correctAnswerSelectedCallback;
    this.incorrectAnswerSelectedCallback = incorrectAnswerSelectedCallback;
    this.layoutFactory = new QuestionLayoutConfigurationFactory();

    this.questionHeader = new Phaser.GameObjects.Text(
      this.scene,
      0,
      0,
      `Question`,
      {
        fontFamily: "Arial",
        fontSize: "20px",
        color: "#979797",
        align: "center",
      }
    ).setOrigin(0);

    this.add(this.questionHeader);

    this.questionTitle = new Phaser.GameObjects.Text(
      this.scene,
      0,
      0,
      "", 
      {
        fontFamily: "Arial",
        fontSize: "20px",
        color: "#ffffff",
        align: "left",
      }
    ).setOrigin(0);

    this.questionImage = new Phaser.GameObjects.Image(this.scene, 0, 0, "");
    this.questionImage.setOrigin(0).setVisible(false);
    this.add(this.questionImage);
    
    this.add(this.questionTitle);
  }

  setQuestion(question: IQuestion, questionIndex: number, numberOfAllQuestions: number) {
    const random = Math.random();
    const displayImage =  (question.title.length > 140) ? false : random >= 0.5;
    const layoutType = displayImage ? QuestionLayoutType.WithImage : QuestionLayoutType.WithoutImage;

    const layoutConfiguration = this.layoutFactory.getLayoutConfiguration(layoutType);

    this.question = question;
    this.questionIndex = questionIndex;

    this.questionHeader.text = (numberOfAllQuestions !== 1) ? `Question ${questionIndex + 1} / ${numberOfAllQuestions}` : ""; 
    this.questionTitle.text = question.title;
    this.questionTitle.setWordWrapWidth(layoutConfiguration.titleWidth);

    this.initializeAnswers(layoutConfiguration);

    if (displayImage) {
      this.applyLayoutWithImage(layoutConfiguration);
    }
    else {
      this.applyLayoutWithoutImage(layoutConfiguration);
    }
  }

  disableInteractive(): this {
    this.answers.forEach(a => a.disableInteractive())
    return this;
  }

  private applyLayoutWithImage(layoutConfiguration: ILayoutConfiguraion) {
    this.questionHeader.setPosition(layoutConfiguration.headerX, layoutConfiguration.headerY);
    this.questionTitle.setPosition(this.questionHeader.x, this.questionHeader.y + layoutConfiguration.titlePadding);
    this.questionImage.visible = true;
    this.questionImage.setPosition(580, layoutConfiguration.headerY + layoutConfiguration.titlePadding);

    // const imageTexture = Math.random() >= 0.5 ? "questionnare-img1" : "questionnare-img2";
    const imageTexture = "questionnare-img2";
    this.questionImage.setTexture(imageTexture);

    let previousAnswerY = this.questionTitle.getCenter().y! + layoutConfiguration.paddingY + 15;
    
    for (let i = 0; i < this.answers.length; i++) {
      const answer = this.answers[i];
      const yPos = previousAnswerY + layoutConfiguration.paddingY;
      answer.setPosition(this.questionTitle.x, yPos);
      previousAnswerY += this.answers[i].height + layoutConfiguration.paddingY;
    }
  }

  private applyLayoutWithoutImage(layoutConfiguration: ILayoutConfiguraion) {
    this.questionHeader.setPosition(layoutConfiguration.headerX, layoutConfiguration.headerY);
    this.questionTitle.setPosition(this.questionHeader.x, this.questionHeader.y + layoutConfiguration.titlePadding);
    this.questionImage.visible = false;

    let previousAnswerY = this.questionTitle.getCenter().y! + layoutConfiguration.paddingY + 2;
    
    for (let i = 0; i < this.answers.length; i++) {
      const answer = this.answers[i];
      let xPos = this.questionTitle.x;
      let yPos = previousAnswerY;

      yPos += layoutConfiguration.paddingY
      
      if (i % 2 === 1) {
        xPos += answer.width + layoutConfiguration.paddingX;
      }
      
      answer.setPosition(xPos, yPos);      
      answer.setDisplaySize(answer.width, 84);

      if (i % 2 === 1) {
        previousAnswerY += answer.height + layoutConfiguration.paddingY;
      }
    }
  }

  private initializeAnswers(layoutConfiguration: ILayoutConfiguraion) {
    this.answers.forEach(a => a.destroy());
    this.answers.length = 0;

    const answerWidth = layoutConfiguration.width;

    this.randomizeQuestions();

    for (let answerIndex = 0; answerIndex < this.question.choices.answers.length; answerIndex++) {
      const answerChoice = new AnswerChoice(
        this.scene,
        this.sourceObject,
        this.question.choices.answers[answerIndex],
        answerWidth,
        answerIndex,
        this.question.choices.correctAnswer == answerIndex,
        (answerIndex: number) => this.onAnswerSelected(answerIndex)
      );
      
      this.add(answerChoice);
      this.answers.push(answerChoice);
    }
  }
  
  private randomizeQuestions() {
    const correctAnswer = this.question.choices.answers[this.question.choices.correctAnswer];
    this.question.choices.answers.sort(() => Math.random() - 0.5);
    this.question.choices.correctAnswer = this.question.choices.answers.indexOf(correctAnswer);
  }
  
  private onAnswerSelected(answerIndex: number) {
    this.answers[answerIndex].disableInteractive();

    if (this.question.choices.correctAnswer == answerIndex) {
      this.correctAnswerSelectedCallback();
    }
    else {
      this.incorrectAnswerSelectedCallback();
    }
  }
}
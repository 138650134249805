import {useEffect, useState} from "react";
import {useLocation, useNavigate, Link} from "react-router-dom";
import {Header, HeaderOptionItem} from "@appkit4/react-components/header";
import {
  Avatar,
  Button,
  Modal,
  DropdownButton,
} from "@appkit4/react-components";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import {FormattedMessage, useIntl} from "react-intl";
import styles from "./AppHeader.module.scss";
import {useGameStore} from "@/store/game-store";
import {useMutation, useQueryClient} from "react-query";
import {deleteGame, duplicateGame, updateGame} from "@/api/game";
import {deleteGameSession, updateGameSession} from "@/api/session";
import {renderNotification} from "../notification/renderFunctions";
import {jwtDecode} from "jwt-decode";

const AppHeader = () => {
  const intl = useIntl();
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const currentRoomIndex = useGameStore((state) => state.currentRoomIndex);
  const game = useGameStore((state) => state.game);
  const session = useGameStore((state) => state.session);
  const rooms = useGameStore((state) => state.game.rooms);
  const currentPath = location.pathname;
  const routes = currentPath.split("/");

  const [showDeleteSessionModal, setShowDeleteSessionModal] =
    useState<boolean>(false);
  const [showDeleteGameModal, setShowDeleteGameModal] =
    useState<boolean>(false);
  const [showDuplicateGameModal, setShowDuplicateGameModal] =
    useState<boolean>(false);
  const [initials, setInitials] = useState("N/A");

  const saveGameMutation = useMutation({
    mutationKey: ["saveGame", game._id],
    mutationFn: () => updateGame(game._id, game),
    onSuccess: (res) => {
      renderNotification("Game has been saved.", "success");
    },
    onError: (err) => {
      renderNotification(err.response.data.message, "error");
    },
  });

  const duplicateGameMutation = useMutation({
    mutationKey: "duplicateGame",
    mutationFn: () => duplicateGame({
      ...game,
      name: `${game.name} copy`
    }),
    onSuccess: (res) => {
      return navigate("/");
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const deleteGameMutation = useMutation({
    mutationKey: "deleteGame",
    mutationFn: () => deleteGame(game._id),
    onSuccess: (res) => {
      return navigate("/");
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const saveGameSessionMutation = useMutation({
    mutationKey: "saveGameSession",
    mutationFn: () => updateGameSession(session?._id!, session!),
    onSuccess: (res) => {
      return queryClient.invalidateQueries({queryKey: ["getGameSession"]});
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const deleteSessionMutation = useMutation({
    mutationKey: "deleteSession",
    mutationFn: () => deleteGameSession(session?._id!),
    onSuccess: (res) => {
      return navigate("/sessions");
    },
    onError: (err) => {
      console.error(err);
    },
  });

  useEffect(() => {
    const token = localStorage.getItem("id_token");
    if (token == null) {
      return;
    }
    const decoded = jwtDecode(token);
    if (decoded == null) {
      return;
    }

    // ORIGINAL CODE
    // expecting 'name' claim to contain both first and last name, e.g. "John Smith"
    // const names = decoded.name.split(" ");
    // setInitials(`${names[0][0]}${names[1][0]}`.toUpperCase());

    // WORKAROUND
    // in case name claim is only an email, use different claims

    const names = decoded.name.split(" ");
    let initials;
    if (names.length > 1) {
      initials = `${names[0][0]}${names[1][0]}`;
    } else {
      initials = "";

      if (decoded.given_name) {
        initials += decoded.given_name[0];
      }

      if (decoded.family_name) {
        initials += decoded.family_name[0];
      }
    }

    setInitials(initials.toUpperCase());
  }, []);

  const saveGame = () => {
    return saveGameMutation.mutate();
  };

  const saveGameSession = () => {
    return saveGameSessionMutation.mutate();
  };

  const launchPreview = () => {
    localStorage.setItem(
      "roomPreview",
      JSON.stringify(game.rooms[currentRoomIndex])
    );
    window.open(`${currentPath}/preview`, "_blank");
  };

  const generateTitle = () => {
    let title = intl.formatMessage({id: "location.games.list"});

    if (routes[1] === "" || routes[1] === "games") {
      title = intl.formatMessage({id: "location.game.manager"});
      if (routes[3] === "rooms") {
        title = game.rooms[currentRoomIndex]?.name ?? "ROOM";
      }
    }

    if (routes[1] === "sessions") {
      title = intl.formatMessage({id: "location.session.manager"});
    }

    if (routes[1] === "users") {
      title = intl.formatMessage({id: "location.users"});
    }

    return title;
  };

  const generateNavigationButtons = () => {
    const buttons: Array<JSX.Element> = [];

    if (routes[1] === "games") {
      if (routes.length === 4) {
        buttons.push(
          <>
            <Button
              onClick={() => {
                setShowDuplicateGameModal(true);
              }}
              icon="icon-copy-outline"
              className={styles.max_content}
              kind="tertiary"
            >
              <FormattedMessage id="game.button.duplicate_game"/>
            </Button>
            <Button
              onClick={() => {
                setShowDeleteGameModal(true);
              }}
              icon="icon-delete-outline"
              className={styles.max_content}
              kind="tertiary"
            >
              <FormattedMessage id="game.button.delete_game"/>
            </Button>
            <Button
              onClick={saveGame}
              icon="icon-save-outline"
              className={styles.max_content}
            >
              <FormattedMessage id="game.button.save_game"/>
            </Button>
          </>
        );
      }
      if (routes[3] === "rooms") {
        buttons.push(
          <>
            <Button
              onClick={launchPreview}
              icon="icon-play-outline"
              className={styles.max_content}
              kind="tertiary"
            >
              <FormattedMessage id="game.button.preview_room"/>
            </Button>
            <Button
              icon="icon-save-outline"
              className={styles.max_content}
              onClick={() => {
                saveGame();
              }}
            >
              <FormattedMessage id="button.save"/>
            </Button>
          </>
        );
      }
    }
    if (routes[1] === "sessions" && routes.length === 3) {
      buttons.push(
        <>
          <Button
            onClick={() => {
              setShowDeleteSessionModal(true);
            }}
            icon="icon-delete-outline"
            className={styles.max_content}
            kind="tertiary"
          >
            <FormattedMessage id="session.button.delete_session"/>
          </Button>
          <Button
            icon="icon-save-outline"
            className={styles.max_content}
            onClick={() => {
              saveGameSession();
            }}
          >
            <FormattedMessage id="session.button.save_session"/>
          </Button>
        </>
      );
    }

    return buttons;
  };

  const generateActionBar = () => {
    const isGamesPage = routes[1] === "games";
    const isRoomsPage = routes[3] === "rooms";
    const isSessionsPage = routes[1] === "sessions" && routes.length === 3;
    const isAdvancedMode = routes[3] === "advancedmode";

    if ((isGamesPage || isRoomsPage || isSessionsPage) && isAdvancedMode) {
      return (
        <>
          <div className="ap-bg-color-text-heading ap-text-color-background-alt ap-py-spacing-1">
            <div className="ap-container">
              <div className={`row ${styles.flex}`}>
                <span
                  className={`${styles.max_content} ${styles.title} ap-py-spacing-4`}
                >
                  {generateTitle()}
                </span>
                <span className={styles.navButtons}>
                  {generateNavigationButtons()}
                </span>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const generateBreadcrumb = () => {
    const isGamesPage = routes[1] === "games";
    const isAdvancedMode = routes[3] === "advancedmode";

    if (isGamesPage && isAdvancedMode) {
      return (
        <div className="ap-container">
          <Breadcrumb/>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div>
        <Header
          className="ap-bg-color-background-alt"
          titleTemplate={() => (
            <div className="flex gap-8 align-center">
              <FormattedMessage id="navbar.title"/>
            </div>
          )}
          contentTemplate={() => (
            <>
              <HeaderOptionItem
                iconName="door-opened-outline"
                label="Games"
                onClick={() => navigate("/")}
                className={
                  currentPath === "/"
                  || currentPath.includes("/games")
                    ? styles.selected : ""
                }
              ></HeaderOptionItem>
              <HeaderOptionItem
                iconName="calendar-outline"
                label="Sessions"
                onClick={() => navigate("/sessions")}
                className={
                  (currentPath.includes("/sessions") && !(currentPath.includes("/games/"))) ?
                    styles.selected : ""
                }
              ></HeaderOptionItem>
              <HeaderOptionItem
                iconName="avatar-outline"
                label="Users"
                onClick={() => navigate("/users")}
                className={currentPath.includes("/users") ? styles.selected : ""}
              ></HeaderOptionItem>
            </>
          )}
          userTemplate={() => (
            <DropdownButton
              customTriggerNode={true}
              customTriggerClassName="custom-node"
              data={[
                {
                  label: "Log out",
                  iconName: "log-out-outline",
                  destination: "/signout",
                },
              ]}
              prefixTemplate={(item) => (
                <span className={`Appkit4-icon icon-${item.iconName}`}></span>
              )}
              onSelect={(value, item, event) => {
                navigate(item.destination);
              }}
            >
              <Avatar
                disabled={false}
                label={initials}
                ariaLabel="settings menu"
              />
            </DropdownButton>
          )}
        ></Header>
        {generateActionBar()}
        {generateBreadcrumb()}
      </div>
      <Modal
        visible={showDeleteGameModal}
        title={intl.formatMessage({id: "game.delete.modal.title"})}
        ariaLabel={intl.formatMessage({id: "game.delete.modal.title"})}
        onCancel={() => {
          setShowDeleteGameModal(false);
        }}
        modalStyle={{width: "33.75rem"}}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowDeleteGameModal(false);
              }}
            >
              <FormattedMessage id="button.cancel"/>
            </Button>
            <Button
              kind="negative"
              icon="icon-delete-outline"
              onClick={() => {
                deleteGameMutation.mutate();
                setShowDeleteGameModal(false);
              }}
            >
              <FormattedMessage id="game.delete.modal.delete"/>
            </Button>
          </div>
        }
      >
        <p>
          <FormattedMessage id="delete.sure"/>
        </p>
      </Modal>

      <Modal
        visible={showDuplicateGameModal}
        title={intl.formatMessage({id: "game.duplicate.modal.title"})}
        ariaLabel={intl.formatMessage({id: "game.duplicate.modal.title"})}
        onCancel={() => {
          setShowDuplicateGameModal(false);
        }}
        modalStyle={{width: "33.75rem"}}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowDuplicateGameModal(false);
              }}
            >
              <FormattedMessage id="button.cancel"/>
            </Button>
            <Button
              onClick={() => {
                duplicateGameMutation.mutate();
                setShowDuplicateGameModal(false);
              }}
              icon="icon-copy-outline"
            >
              <FormattedMessage id="game.duplicate.modal.duplicate"/>
            </Button>
          </div>
        }
      >
        <p>
          <FormattedMessage id="game.duplicate.modal.text"/>
        </p>
      </Modal>

      <Modal
        visible={showDeleteSessionModal}
        title={intl.formatMessage({id: "session.delete.modal.title"})}
        ariaLabel={intl.formatMessage({id: "session.delete.modal.title"})}
        onCancel={() => {
          setShowDeleteGameModal(false);
        }}
        modalStyle={{width: "33.75rem"}}
        footerStyle={{
          paddingTop: "8px",
        }}
        icons={""}
        footer={
          <div className="modal-buttons">
            <Button
              kind="secondary"
              onClick={() => {
                setShowDeleteSessionModal(false);
              }}
            >
              <FormattedMessage id="button.cancel"/>
            </Button>
            <Button
              kind="negative"
              icon="icon-delete-outline"
              onClick={() => {
                deleteSessionMutation.mutate();
                setShowDeleteSessionModal(false);
              }}
            >
              <FormattedMessage id="session.delete.modal.delete"/>
            </Button>
          </div>
        }
      >
        <p>
          <FormattedMessage id="delete.sure"/>
        </p>
      </Modal>
    </>
  );
};

export default AppHeader;

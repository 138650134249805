import UIScene from "@/phaser/scenes/UIScene";
import IconButton from "./buttons/IconButton";

export default class CloseButton extends Phaser.GameObjects.Container {
    private closeButton: IconButton;

    constructor(
        scene: UIScene,
        callback: () => void
      ) {
        super(scene, 0, 0);

        this.closeButton = new IconButton(
            scene,
            scene.renderer.width - 94,
            104,
            "close-image",
            callback
          ).setOrigin(0);

        this.add(this.closeButton);
    }
}
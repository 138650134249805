import UIScene from "@/phaser/scenes/UIScene";
import WelcomeScreen from "./WelcomeScreen";
import { SCREEN_WIDTH } from "@/constants";
import GameIntroduction from "./GameIntroduction";
import GameInstructions from "./GameInstructions";
// import GameMeetingDetails from "./GameMeetingDetails";
import CountdownScreen from "./CountdownScreen";
import GameMission from "./GameMission";
import TeamName from "./TeamName";
import OnboardingScreenBase from "./OnboardingScreenBase";
import GameScene from "@/phaser/scenes/GameScene";

export default class OnboardingMainScreen extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly callback: () => void;
  private readonly blackBackground: Phaser.GameObjects.Graphics;
  private readonly companyLogoImage: Phaser.GameObjects.Image;
  private readonly welcomeScreen: WelcomeScreen;
  private readonly gameIntroduction: GameIntroduction;
  private readonly gameInstructions: GameInstructions;
  // private readonly gameMeetingDetails: GameMeetingDetails;
  private readonly gameMission: GameMission;
  private readonly countdownScreen: CountdownScreen
  private readonly teamName: TeamName;

  private containers: OnboardingScreenBase[] = [];
  private activeContainerIndex: number = 0;
  
  public static readonly SCREEN_WIDTH = SCREEN_WIDTH;
  public static readonly SCREEN_HEIGHT = 1024;
  public static readonly STYLE_H1 = 'font-weight: 500; font-size: 48px; font-family: Arial; color: #ffffff; align: left; letter-spacing: 0.2px';

  private readonly MARGIN_LEFT = 150;
  
  constructor(
    scene: UIScene,
    callback: () => void = () => {}
  ) {
    super(scene, 0, 0);

    this.scene = scene;
    this.callback = callback;

    this.setDepth(10);
    
    this.blackBackground = new Phaser.GameObjects.Graphics(this.scene);
    this.blackBackground.fillStyle(0x000000, 1);
    this.blackBackground.fillRect(0, 0, OnboardingMainScreen.SCREEN_WIDTH, OnboardingMainScreen.SCREEN_HEIGHT);
    this.blackBackground.setInteractive();
    this.blackBackground.on(Phaser.Input.Events.POINTER_DOWN, function(pointer: Phaser.Input.Pointer) {
      pointer.event.stopPropagation();
    });

    this.add(this.blackBackground);

    this.welcomeScreen = new WelcomeScreen(this.scene, this.MARGIN_LEFT, this.displayNext);
    this.gameIntroduction = new GameIntroduction(this.scene, this.MARGIN_LEFT, this.displayNext).setVisible(false);
    this.gameInstructions = new GameInstructions(this.scene, this.MARGIN_LEFT, this.displayNext).setVisible(false);
    // this.gameMeetingDetails = new GameMeetingDetails(this.scene, this.MARGIN_LEFT, this.displayNext).setVisible(false);
    this.teamName = new TeamName(this.scene, this.MARGIN_LEFT, this.displayNext).setVisible(false);
    this.gameMission = new GameMission(this.scene, this.MARGIN_LEFT, this.displayNext).setVisible(false);
    this.countdownScreen = new CountdownScreen(this.scene, this.displayNext).setVisible(false);

    this.containers.push(this.welcomeScreen);
    this.containers.push(this.gameIntroduction);
    this.containers.push(this.gameInstructions);
    // this.containers.push(this.gameMeetingDetails);
    this.containers.push(this.teamName);
    this.containers.push(this.gameMission);
    this.containers.push(this.countdownScreen);
    
    this.companyLogoImage = new Phaser.GameObjects.Image(this.scene, 1264, 32, "pwc-logo").setOrigin(0, 0);
    this.add(this.companyLogoImage);
    
    scene.add.existing(this);
  }

  public activate() {
    this.add(this.welcomeScreen);

    const gameScene = this.scene.sourceScene as GameScene;
    if (gameScene.session?.teamName !== "") {
      this.callback();
      return;
    }
    
    this.welcomeScreen.activate();
  }

  private displayNext = () => {
    const deactivatedContainer = this.containers[this.activeContainerIndex];
    deactivatedContainer.destroy();

    this.activeContainerIndex = this.activeContainerIndex + 1

    if (this.activeContainerIndex == this.containers.length) {
      this.callback();
      return;
    }
    
    if (this.activeContainerIndex < this.containers.length) {
      const activatedContainer = this.containers[this.activeContainerIndex];
      this.add(activatedContainer);
      activatedContainer.activate();
      activatedContainer.setVisible(true);
    }
  }
}

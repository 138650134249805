import UIScene from "@/phaser/scenes/UIScene";
import GameScene from "@/phaser/scenes/GameScene";
import { IGameSession } from "escape-rooms-types/types/gameSession";
import { HEADER_HEIGHT, SCREEN_CENTER_X, SCREEN_HEIGHT, SCREEN_WIDTH } from "@/constants";
import OnboardingMainScreen from "../onboarding/OnboardingMainScreen";

export default class GameOutroScreen extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly gameScene: GameScene;
  private readonly session: IGameSession;
  private readonly blackBackground: Phaser.GameObjects.Graphics;
  private readonly headerForm: Phaser.GameObjects.DOMElement;
  private readonly contentForm: Phaser.GameObjects.DOMElement;
  private readonly continueButtonText: Phaser.GameObjects.Text;
  private readonly continueButtonBackground: Phaser.GameObjects.Graphics;
  // private readonly imageForm: Phaser.GameObjects.DOMElement;
  private readonly image: Phaser.GameObjects.Image;

  constructor(scene: UIScene, session: IGameSession) {
    super(scene);
    this.scene = scene;
    this.gameScene = scene.sourceScene as GameScene;
    this.session = session;

    this.setDepth(10);

    this.blackBackground = new Phaser.GameObjects.Graphics(this.scene);
    this.blackBackground.fillStyle(0x000000, 1);
    this.blackBackground.fillRect(0, HEADER_HEIGHT, SCREEN_WIDTH, SCREEN_HEIGHT);
    this.blackBackground.setInteractive();
    this.blackBackground.on(Phaser.Input.Events.POINTER_DOWN, function(pointer: Phaser.Input.Pointer) {
      pointer.event.stopPropagation();
    });

    this.add(this.blackBackground);
    
    this.headerForm = new Phaser.GameObjects.DOMElement(this.scene, SCREEN_CENTER_X, 180, "div", OnboardingMainScreen.STYLE_H1, "Congratulations - you’ve escaped the room!").setOrigin(0.5);
    this.add(this.headerForm);

    this.contentForm = new Phaser.GameObjects.DOMElement(this.scene, SCREEN_CENTER_X, 240).setOrigin(0.5, 0);
    this.add(this.contentForm);

    const contentText = "You've found the file. Excellent work, team!<br/>Good luck with the next steps on your journey as a transformative leader.";
    // const game = this.gameScene.session?.game;
    // contentText = game.outroSuccessfulMessage;
    const html = `<div style="font: 400 24px Arial; color: #ffffff; text-align: center; width: 1010px">${contentText}</div>`;
    this.contentForm.createFromHTML(html);

    this.continueButtonText = new Phaser.GameObjects.Text(this.scene, SCREEN_CENTER_X, 350, "Go to leaderboard", {
      font: "500 16px Arial",
        color: "#ffffff",
        align: "center",
    }).setOrigin(0.5, 0);

    const paddingX = 40;
    const paddingY = 15;
    
    this.continueButtonBackground = new Phaser.GameObjects.Graphics(this.scene);
    this.continueButtonBackground.fillStyle(0x000000, 1);
    this.continueButtonBackground.lineStyle(1, 0xffffff, 1);
    this.continueButtonBackground.strokeRoundedRect(
      this.continueButtonText.getTopLeft().x! - paddingX,
      this.continueButtonText.getTopLeft().y! - paddingY,
      this.continueButtonText.width + 2 * paddingX,
      this.continueButtonText.height + 2 * paddingY,
      5 
    );
    this.add(this.continueButtonBackground);
    this.add(this.continueButtonText);

    this.continueButtonText.setInteractive().on(Phaser.Input.Events.POINTER_DOWN, this.onContinue);
    this.continueButtonBackground.setInteractive(
      new Phaser.Geom.Rectangle(
        this.continueButtonText.getTopLeft().x! - paddingX, 
        this.continueButtonText.getTopLeft().y! - paddingY, 
        this.continueButtonText.width + 2 * paddingX,this.continueButtonText.height + 2 * paddingY), Phaser.Geom.Rectangle.Contains
    ).on(Phaser.Input.Events.POINTER_DOWN, this.onContinue);

    // this.imageForm = new Phaser.GameObjects.DOMElement(this.scene, SCREEN_CENTER_X, 500);
    // this.imageForm.setOrigin(0.5);
    // this.add(this.imageForm);
    // const imageHtml = `<img src="/phaser-assets/Finance-report.png" />`;
    // this.imageForm.createFromHTML(imageHtml);

    this.image = new Phaser.GameObjects.Image(this.scene, SCREEN_CENTER_X, 550, "finance-report");
    this.image.setOrigin(0.5);
    this.add(this.image);

    this.scene.add.existing(this);
  }

  private onContinue = () => {
    const url = `/sessions/${this.session._id}/play/scoredashboard`;
    window.location.replace(url);
  }
}

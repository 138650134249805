import AppHeader from "@/components/app-header";
import {Outlet} from "react-router-dom";
import {UserInfoProvider} from "@/context/UserInfoContext";
import AppFooter from "@/components/app-footer";
import ProtectedRoute from "@/components/protected-route/ProtectedRoute";
import { ModalProvider } from "../game-customisation-template-pages/UnsavedChangesContext";

const Layout = () => (
    <>
    <ModalProvider>
        <ProtectedRoute>
            <AppHeader/>
            <Outlet/>
            <AppFooter/>
        </ProtectedRoute>
    </ModalProvider>
    </>
);

export default Layout;

export interface ILayoutConfiguraion {
    headerX: number,
    headerY: number,
    width: number,
    paddingX: number,
    paddingY: number,
    titlePadding: number,
    titleWidth: number | undefined
}
  
export class QuestionLayoutConfigurationFactory {
    getLayoutConfiguration(layoutType: QuestionLayoutType) {
      if (layoutType === QuestionLayoutType.WithoutImage) {
        return new QuestionWithoutImageLayoutConfiguration();
      }
  
      return new QuestionWithImageLayoutConfiguration();
    }
}

export abstract class QuestionLayoutConfigurationBase implements ILayoutConfiguraion {
    abstract headerX: number;
    abstract headerY: number;
    abstract width: number;
    abstract titleWidth: number | undefined;
    paddingX = 40;
    paddingY = 16;
    titlePadding = 38;
}
  
export class QuestionWithImageLayoutConfiguration extends QuestionLayoutConfigurationBase {
    headerX: number = 41;
    headerY: number = 127;
    width = 500;
    titleWidth = 540;
}
  
export class QuestionWithoutImageLayoutConfiguration extends QuestionLayoutConfigurationBase {
    headerX: number = 41;
    headerY: number = 210;
    width = 582;
    titleWidth = 1200;
}
  
export enum QuestionLayoutType {
    WithoutImage, 
    WithImage
  }
  
import Player from "./session/Player";
import UIScene from "../scenes/UIScene";

export default class PlayerAvatar extends Phaser.GameObjects.Container {
  public player: Player;
  
  private readonly initialsText: Phaser.GameObjects.Text;
  private readonly circle: Phaser.GameObjects.Graphics;
  private readonly color: number = 0xEBEBEB;
  
  private readonly onClick?: () => void;
  private readonly size: number;
  private readonly image: Phaser.GameObjects.Image;

  constructor(
    scene: UIScene,
    x: number,
    y: number,
    player: Player,
    size: number,
    onClick?: () => void
  ) {
    super(scene, x, y);
    
    this.player = player;
    this.size = size;

    this.circle = new Phaser.GameObjects.Graphics(scene);
    this.add(this.circle);
    
    const intials =
      player.firstName == ""
        ? "N/A"
        : `${player.firstName[0].toUpperCase()}${player.lastName[0].toUpperCase()}`;
    
    this.initialsText = new Phaser.GameObjects.Text(scene, this.size / 2, this.size / 2, intials, {
      fontFamily: "Arial",
      fontSize: "20px",
      color: "#000000"
    }).setOrigin(0.5);
    this.add(this.initialsText);

    this.image = new Phaser.GameObjects.Image(scene, this.size / 2, this.size / 2, "player-complete-check");
    this.add(this.image);

    this.markAsUnfinished();
  }

  public markAsFinished() {
    this.circle.setAlpha(0.5);
    this.initialsText.setAlpha(0.5);
    this.image.setVisible(true);
  }

  public markAsUnfinished() {
    const circle = new Phaser.Geom.Circle(this.size / 2, this.size / 2, this.size / 2);
    this.circle.clear();
    this.circle.fillStyle(this.color, 1);
    this.circle.lineStyle(1, this.color);
    this.circle.fillCircleShape(circle);
    this.circle.strokeCircleShape(circle);
    
    this.initialsText.setAlpha(1);
    this.image.setVisible(false);
  }
}

import BaseScene from "@/phaser/scenes/BaseScene";
import UIScene from "@/phaser/scenes/UIScene";
import ProgressBar from "../ProgressBar";
import { HEADER_HEIGHT } from "@/constants";
import EventBridge from "@/utils/EventBridge";
import Inventory from "../inventory/Inventory";
import Clock from "./Clock";
import HintButton from "./HintButton";

export default class Hud extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceScene: BaseScene;
  private readonly background: Phaser.GameObjects.Rectangle;
  private readonly progressBar: ProgressBar;
  private readonly progressBarLabel: Phaser.GameObjects.Text;
  private readonly scoreText: Phaser.GameObjects.Text;
  private readonly awardRibbonImage: Phaser.GameObjects.Image;
  private readonly hintButton: HintButton;
  private readonly clock: Clock;
  private currentScore: number;

  constructor(
    scene: UIScene,
    sourceScene: BaseScene,
    gameCompleted: boolean = false,
    inventory: Inventory
  ) {
    super(scene);
    this.scene = scene;
    this.sourceScene = sourceScene;
    this.setDepth(3);

    this.background = new Phaser.GameObjects.Rectangle(scene, 0, 0)
      .setOrigin(0, 0)
      .setSize(scene.renderer.width, HEADER_HEIGHT)
      .setFillStyle(0x415385, 0.9);
    this.add(this.background);
    
    const borderBottom = new Phaser.GameObjects.Rectangle(scene, 0, HEADER_HEIGHT - 1)
      .setOrigin(0, 0)
      .setSize(scene.renderer.width, 1)
      .setFillStyle(0x252525);
    this.add(borderBottom);

    // const image = new Phaser.GameObjects.Image(
    //   scene,
    //   0,
    //   0,
    //   "game-header"
    // )
    //   .setDisplaySize(1440, 55)
    //   .setOrigin(0)
    //   .setSize(1440, 55);

    // this.add(image);

    this.clock = new Clock(this.scene, 30 * 60);
    this.clock.setPosition(27, 10);
    this.add(this.clock);

    const PROGRESS_BAR_HEIGHT = 4;
    
    this.progressBarLabel = new Phaser.GameObjects.Text(
      scene,
      180,
      HEADER_HEIGHT / 2,
      "Room progress",
      { color: "white", fontSize: "15px", fontFamily: "Arial", fontStyle: "bold" }
    ).setOrigin(0, 0.5)
    .setLetterSpacing(0);
    this.add(this.progressBarLabel);

    this.progressBar = new ProgressBar(
      scene, 
      148, 
      PROGRESS_BAR_HEIGHT
    ).setPosition(this.progressBarLabel.getRightCenter().x! + 20, HEADER_HEIGHT / 2 - PROGRESS_BAR_HEIGHT / 2);
    
    this.progressBar.setProgress(0);
    this.add(this.progressBar);

    this.hintButton = new HintButton(this.scene);
    this.add(this.hintButton);
    this.hintButton.setVisible(false);

    this.awardRibbonImage = new Phaser.GameObjects.Image(
      scene, 
      scene.renderer.width - 24, 
      HEADER_HEIGHT / 2, 
      "star"
    )
      .setOrigin(1, 0.5)
      .setInteractive()
    this.add(this.awardRibbonImage);

    if(this.scene.env === "preview")
    {
      this.currentScore = 100;      
    }
    else
    {
      this.currentScore = scene.game.session.teamScore;
    }

    this.scoreText = new Phaser.GameObjects.Text(
      scene,
      this.awardRibbonImage.getLeftCenter().x! - 4,
      this.awardRibbonImage.y,
      `${this.currentScore} points`,
      {
        font: "500 16px Arial",
        color: "white",
        align: "right"
      }
    ).setOrigin(1, 0.5);
    this.add(this.scoreText);

    this.add(inventory);

    scene.add.existing(this);

    if (!gameCompleted) {
      this.addEventListeners();
    } else {
      this.progressBar.setVisible(false);
      this.progressBarLabel.setVisible(false);
      this.hintButton.setVisible(false);
      this.awardRibbonImage.setVisible(false);
      this.scoreText.setVisible(false);
    }
  }

  startClock() {
    this.clock.start();
  }

  stopClock() {
    this.clock.stop();
  }

  setHintButtonVisible(value: boolean) {
    this.hintButton.setVisible(value);
  }

  private addEventListeners() {
    EventBridge.on("ui.setProgress", (progress: number) => {
      this.progressBar.setProgress(progress);
    });

    EventBridge.on("ui.addToTeamScore", (score: number) => {
      this.currentScore += score;     
      this.scoreText.setText(`${this.currentScore} points`);
    });

    EventBridge.on("ui.setScore", (teamScore: number) => {   
      if (teamScore !== undefined) {
        this.currentScore = teamScore;
        this.scoreText.setText(`${this.currentScore} points`);
      }
    });
  }

  private removeEventListeners() {
    EventBridge.remove("ui.setProgress");
    EventBridge.remove("ui.addToTeamScore");   
    EventBridge.remove("ui.setScore");
  }

  destroy(fromScene?: boolean | undefined): void {
    super.destroy(fromScene);
    this.removeEventListeners();
  }
}

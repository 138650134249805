import { useGameStore } from "@/store/game-store";
import {
  Accordion,
  AccordionItem,
  Badge,
  Button,
  Input,
  Notice,
  Panel,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextArea,
} from "@appkit4/react-components";
import { IAction, IQuestion } from "escape-rooms-types/types/game";
import { FormattedMessage, useIntl } from "react-intl";
import ActionChangeOther from "./actions/ActionChangeOther";
import ActionChangeSelf from "./actions/ActionChangeSelf";
import ActionDisplayImage from "./actions/ActionDisplayImage";
import ActionDisplayImageByState from "./actions/ActionDisplayImageByState";
import ActionDisplayMessage from "./actions/ActionDisplayMessage";
import ActionDisplayQuestionnaire from "./actions/ActionDisplayQuestionnaire";
import styles from "./ObjectConfigAction.module.scss";
import { getObjectNameFromRef } from "@/utils/react-helpers";
import ActionDisplayChallenge from "./actions/ActionDisplayChallenge";
import ActionDisplayMedia from "./actions/ActionDisplayMedia";
import { useCallback, useState } from "react";
import XPField from "./shared/XPField";
import ActionDisplayPuzzle from "./actions/ActionDisplayPuzzle";

interface IObjectConfigActionProps {
  index: number;
  onDelete: () => void;
}

const getTriggers = (index: number) => {
  const triggers = [
    { label: "Click (default)", value: "onClick" },
    { label: "On item use", value: "onItemUse" },
  ];

  if (index !== 0) {
    triggers.push({ label: "After previous", value: "afterPrevious" });
  }

  return triggers;
};

const ObjectConfigAction = ({ index, onDelete }: IObjectConfigActionProps) => {
  const intl = useIntl();
  const currentRoomIndex = useGameStore((state) => state.currentRoomIndex);
  const selectedObjectIndex = useGameStore(
    (state) => state.selectedObjectIndex
  );

  const objects = useGameStore(
    (state) => state.game.rooms[currentRoomIndex].objects
  );

  const items = useGameStore(
    (state) => state.game.rooms[currentRoomIndex].items
  );

  const selected = useGameStore(
    (state) => state.game.rooms[currentRoomIndex].objects[selectedObjectIndex!]
  );

  const action = selected.actions[index];
  const updateObject = useGameStore((state) => state.updateObject);
  
  const addObjectToItemList = useGameStore(
    (state) => state.addObjectToItemList
  );

  const removeObjectFromItemList = useGameStore(
    (state) => state.removeObjectFromItemList
  );

  const [activeKeys, setActiveKeys] = useState<string[]>(["action"]);

  const toggleAccordian = () => {
    if (activeKeys.includes("action")) {
      setActiveKeys([]);
    } else {
      setActiveKeys(["action"]);
    }
  }

  const getActionTitle = () => {
    let title = "Action";
    let badge = "Action";
    let badge2 = undefined;

    let target =
      action?.changeObjectPayload?.targetObjectRef.split("_")[0] || "";

    if (action?.type === "changeSelf") {
      title =
        intl.formatMessage(
          { id: "action.title.change_self" },
          { subject: selected?.name }
        ) || "";
      badge = intl.formatMessage({ id: "action.badge.change_self" });
    }

    else if (action?.type === "changeOther") {
      title =
        intl.formatMessage(
          { id: "action.title.change_other" },
          { subject: selected?.name, target: target || "..." }
        ) || "";
      badge = intl.formatMessage({ id: "action.badge.change_other" });
    }

    else if (action?.type === "displayMessage" || action?.type === "displayMessageTooltip") {
      title =
        intl.formatMessage(
          { id: "action.title.message" },
          { subject: selected?.name }
        ) || "";
      badge = intl.formatMessage(
        { id: "action.badge.message" },
        { subject: selected?.name }
      );
    }

    else if (action?.type === "displayQuestionnaire") {
      title =
        `"${selected?.name}" launches questionnaire - ${
          action.questionnaire?.questions?.length || 0
        } questions` || "";
      badge = "Action - Questionnaire";
    }

    else if (action?.type === "displayChallenge") {
      title = `"${selected?.name}" launches individual challenge` || "";
      badge = "Action - Individual challenge";
    }

    else if (action?.type === "displayMedia") {
      title = `"${selected?.name}" displays media`;
      badge = "Action - Display media";
    }

    else if (action?.type === "addToInventory") {
      title =
        intl.formatMessage(
          { id: "action.title.add_to_inventory" },
          { subject: selected?.name }
        ) || "";
      badge = intl.formatMessage(
        { id: "action.badge.add_to_inventory" },
        { subject: selected?.name }
      );
    }

    if (action?.trigger === "onItemUse") {
      badge2 = intl.formatMessage({ id: "action.badge.on_item_use" });
      title =
        intl.formatMessage(
          { id: "action.title.on_item_use" },
          {
            subject: selected?.name,
            item: getObjectNameFromRef(action?.triggerItemRef),
          }
        ) || "";
    }

    return (
      <div className={styles.actionPanelTitle}>
        <Badge value={badge} type={getActionType()} />
        {badge2 && <Badge value={badge2} type="warning" />}
        <span className="ap-typography-4 ap-font-weight-2">{title}</span>
      </div>
    );
  };

  const getActionType = () => {
    if (action?.type === "displayMessage") return "info";
    if (
      ["displayQuestionnaire", "displayChallenge"].includes(action?.type || "")
    )
      return "success";
    return "primary";
  };

  const updateActionProperties = (properties: Partial<IAction>) => {
    if (!selected) return;
    
    if (action == null) {
      console.error("error");
      return;
    }

    const newAction = { ...action, ...properties };
    const newActions = selected.actions.map((action, actionIndex) =>
      actionIndex === index ? newAction : action
    );
    updateObject({
      ...selected,
      actions: newActions,
    });

    if (newActions.some((action) => action.type === "addToInventory")) {
      addObjectToItemList(selected.ref);
    } else {
      removeObjectFromItemList(selected.ref);
    }
    // updateAction(newAction);
  };

  const toggleItemModal = () => {
    if (action?.modalPayload == null) {
      updateActionProperties({
        modalPayload: {
          ref: "",
          title: "",
          message: "",
        },
      });
      return;
    }

    updateActionProperties({
      modalPayload: undefined,
    });
  };

  const [warning, setWarning] = useState<boolean>(false);

  if (!selected || !action) return <></>;

  const invalidItemUse = useCallback(() => {
    let invalid = false;
    if (action.trigger === "onItemUse") {
      if (action.triggerItemRef == null) {
        invalid = true;
      } else if (
        items.findIndex((item) => item === action.triggerItemRef) === -1
      ) {
        invalid = true;
      }
      if (action.triggerItemRef === selected.ref) {
        invalid = true;
      }
    }

    return invalid;
  }, [action, selected]);

  return (
    <Accordion className={`ap-my-spacing-6 ${styles.actionPanel}`} onClick={() => toggleAccordian()} activeKeys={activeKeys}>
      <AccordionItem itemKey="action" templateHeader={getActionTitle}>
        <>
          <div className="row ap-my-spacing-6">
            <div className="col-4">
              <span className="input-label">Current State</span>
              <Input placeholder="" disabled value="TODO" />
            </div>
            <div className="col-4">
              <span className="input-label">When does the action begin?</span>
              <Select className={styles["select-dropdown-2"]}
                data={getTriggers(index)}
                value={action.trigger}
                onSelect={(e) => {
                  updateActionProperties({
                    trigger: e,
                  });
                }}
                placeholder=""
              />
            </div>
            <div className="col-4">
              <span className="input-label">What is the action?</span>
              <Select className={styles["select-dropdown-2"]}
                data={
                  action.trigger === undefined
                    ? undefined
                    : [
                        { label: "Change Itself", value: "changeSelf" },
                        {
                          label: "Change Something Else",
                          value: "changeOther",
                        },
                        { label: "Display Image", value: "displayImage" },
                        { label: "Display Image by State", value: "displayImageByState" },
                        { label: "Display Message", value: "displayMessage" },
                        { label: "Display Message Toolitp", value: "displayMessageTooltip" },
                        { label: "Display Media", value: "displayMedia" },
                        { label: "Display Puzzle", value: "displayPuzzle" },
                        { label: "Display Unlock Screen", value: "displayUnlockScreen" },
                        { label: "Add to Inventory", value: "addToInventory" },
                        {
                          label: "Display Questionnaire",
                          value: "displayQuestionnaire",
                        },
                        {
                          label: "Display Individual Challenge",
                          value: "displayChallenge",
                        },
                        {
                          label: "Complete Room",
                          value: "endRoom",
                        },
                        {
                          label: "Complete Game",
                          value: "endGame",
                        },
                      ]
                }
                disabled={action.trigger === undefined}
                value={action.type || undefined}
                onSelect={(e) => {
                  updateActionProperties({
                    type: e,
                    triggerItemRef: undefined,
                    changeObjectPayload: undefined,
                    questionnaire: undefined,
                    modalPayload: undefined,
                    score: 0,
                  });
                }}
                placeholder=""
              />
            </div>
          </div>
          <div className="row ap-my-spacing-6">
            {action.trigger === "onItemUse" && (
              <div className="col-4">
                <span className="input-label">What item is used?</span>
                <Select className={styles["select-dropdown"]}
                  placeholder=""
                  searchable
                  data={items
                    .filter((itemRef) => itemRef !== selected.ref)
                    .map((itemRef) => {
                      const object = objects.find(
                        (object) => object.ref === itemRef
                      );
                      return {
                        label: object?.name,
                        value: object?.ref,
                      };
                    })}
                  value={action.triggerItemRef}
                  onSelect={(e) => {
                    updateActionProperties({
                      triggerItemRef: e,
                    });
                  }}
                />
              </div>
            )}
            {action.type === "changeSelf" && (
              <ActionChangeSelf
                action={action}
                selected={selected}
                updateActionProperties={(payload) =>
                  updateActionProperties(payload)
                }
              />
            )}
            {action?.type === "changeOther" && (
              <ActionChangeOther
                action={action}
                objects={objects}
                selected={selected}
                updateActionProperties={(payload) =>
                  updateActionProperties(payload)
                }
              />
            )}
            {(action.type === "displayImage" || action.type === "displayUnlockScreen") && (
              <ActionDisplayImage
                action={action}
                selected={selected}
                type={action.type}
                updateActionProperties={(payload) =>
                  updateActionProperties(payload)
                }
              />
            )}
            {(action.type === "displayImageByState") && (
              <ActionDisplayImageByState
                action={action}
                selected={selected}
                stateName=""
                updateActionProperties={(payload) =>
                  updateActionProperties(payload)
                }
              />
            )}
            {action.type === "displayPuzzle" && (
              <ActionDisplayPuzzle
                action={action}
                selected={selected}
                updateActionProperties={(payload) =>
                  updateActionProperties(payload)
                }
              />
            )}
            {(action.type === "displayMessage" || action.type === "displayMessageTooltip") && (
              <ActionDisplayMessage
                action={action}
                selected={selected}
                updateActionProperties={(payload) =>
                  updateActionProperties(payload)
                }
              />
            )}
            {action.type === "displayQuestionnaire" && (
              <ActionDisplayQuestionnaire
                action={action}
                selected={selected}
                updateActionProperties={(payload) =>
                  updateActionProperties(payload)
                }
                setWarning={setWarning}
              />
            )}
            {action.type === "displayChallenge" && (
              <ActionDisplayChallenge
                action={action}
                selected={selected}
                updateActionProperties={(payload) =>
                  updateActionProperties(payload)
                }
                setWarning={setWarning}
              />
            )}
            {action.type === "displayMedia" && (
              <ActionDisplayMedia
                action={action}
                selected={selected}
                updateActionProperties={(payload) =>
                  updateActionProperties(payload)
                }
              />
            )}
            {action.type === "addToInventory" && (
              <>
                <XPField
                  action={action}
                  updateActionProperties={(payload) =>
                    updateActionProperties(payload)
                  }
                />
                <div className="row ap-my-spacing-6">
                  <Switch
                    onChange={toggleItemModal}
                    checked={!!action?.modalPayload}
                  >
                    Allow item to be inspected before adding to inventory
                    (creates a modal)
                  </Switch>
                </div>
                {action.modalPayload && (
                  <div className="row ap-my-spacing-4">
                    <TextArea
                      maxLength={300}
                      value={action.modalPayload?.message}
                      onChange={(e) => {
                        updateActionProperties({
                          modalPayload: {
                            ref: "",
                            message: e,
                          },
                        });
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </>
        {(warning || invalidItemUse()) && (
          <div>
            <Notice
              className={styles.warning}
              status="warning"
              closeable={false}
              message={intl.formatMessage({ id: "action.warning.message" })}
            />
          </div>
        )}
        <div className={`${styles.deleteButtonContainer}`}>
          <Button onClick={onDelete} kind="negative" icon="icon-delete-outline">
            Delete action
          </Button>
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default ObjectConfigAction;

import React, { useEffect, useRef, useState } from "react";
import styles from "./GameMission.module.scss";
import { Button } from "@appkit4/react-components";
import { useGameStore } from "@/store/game-store";

const GameMission = () => {
  const theme = useGameStore((state) => state.game.industry);
  const gameMission = useGameStore((state) => state.game.gameMission);
  const gameMissionReplacementStrings = useGameStore((state) => state.game.gameMissionReplacementStrings);
  const [inputs, setInputs] = useState<string[]>(gameMissionReplacementStrings ? gameMissionReplacementStrings : []);
  const initialInputsRef = useRef(inputs);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    if (inputRefs.current) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = getComputedStyle(document.body).font;

      inputRefs.current.forEach((ref, index) => {
        const textWidth = context.measureText(inputs[index]).width;
        ref.style.width = `${textWidth + 10}px`;
      });
    }
  }, [inputs]);

  useEffect(() => {
    if (gameMissionReplacementStrings) {
      setInputs(gameMissionReplacementStrings);
    }
  }, [gameMissionReplacementStrings])

  const handleInputChangeWrapper = (e, index: number) => {
    console.log(e.target)
    const newInputs = [...inputs];
    newInputs[index] = e.target ? e.target.value : '';
    setInputs(newInputs);
    initialInputsRef.current = newInputs;
  };

  const renderMission = (mission: string, inputs: string[]) => {
    const parts = mission.split(/(\{\d+\})/g);
    return parts.map((part, index) => {
      const match = part.match(/\{(\d+)\}/);
      if (match) {
        const inputIndex = parseInt(match[1]);
        return (
          <input
            key={index}
            className={styles.missionTextInput}
            defaultValue={inputs[inputIndex]}
            maxLength={50}
            onChange={(e) => handleInputChangeWrapper(e, inputIndex)}
            ref={(el) => (inputRefs.current[inputIndex] = el)}
          />
        );
      }
      return part;
    });
  };

  const getFinalisedText = (mission: string) => {
    for (let i = 0; i < inputs.length; i++) {
      mission = mission.replace('{input}', inputs[i]);
    }
    return mission;
  }

  const handleSave = () => {
    console.log('Save game mission: ', getFinalisedText(gameMission ? gameMission.mission : ''));
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <h1 className="ap-font-weight-2">Game mission</h1>
          <div className={styles.buttonContainer}>
            <Button
              className={styles.saveButton}
              kind="primary"
              onClick={() => handleSave()}
              disabled={initialInputsRef.current != inputs}
            >
              Save changes
            </Button>
          </div>
        </div>
        <p className={`${styles.introText} ap-typography-body`}>
          In this section, we present the mission of the game to get the players
          excited. Feel free to edit the details to make the mission more
          tailored to you!
        </p>
      </div>
      <div className={styles.divider} />
      <div className={styles.container}>
        <h3 className="ap-typography-body ap-font-weight-2">
          Customise the game mission
        </h3>
        <p className="ap-typography-body ap-mb-spacing-6">
          You can amend the highlighted text with the copy of your choice
        </p>
        <div className={styles.missionTextContainer}>
          <p className="ap-typography-body ap-font-weight-2">Game mission:</p>
          <p className="ap-typography-body">
            {/* {thisGameMission ? renderMission(thisGameMission.mission, inputs) : "Our CFO, {input}, is presently inaccessible due to unforeseen circumstances, and he has safeguarded a crucial {input} report behind a series of his trademark puzzles and riddles. This report is critical for today’s {input} meeting, which is just one hour away. Your mission: decode the puzzles, retrieve the report, and safeguard our company’s integrity. We’re counting on you! Good luck."} */}
            {renderMission(
              gameMission ? gameMission : "", gameMissionReplacementStrings
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default GameMission;

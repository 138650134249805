import { Button, Tag } from '@appkit4/react-components'
import React from 'react'
import styles from "./GameHeader.module.scss";
import GameTabs from '../game-tabs/GameTabs'
import { useNavigate } from 'react-router-dom'
import { useGameStore } from '@/store/game-store'

type Props = {
  className?: string;
}

const GameHeader = ({ className = "" }: Props) => {
  const navigate = useNavigate();
  const name = useGameStore((state) => state.game.name);
  const status = useGameStore((state) => state.game.status);

  return (
    <div className={`${className} w-full`}>
      <div className="ap-my-spacing-5">
        <Button
          className={styles.backButton}
          kind="text"
          icon="icon-left-chevron-outline"
          onClick={() => navigate("/")}
        >
          <span className="ap-font-16">Back to games list</span>
        </Button>
      </div>
      <div className={styles.title_container}>
        <h1 className="ap-font-42 ap-font-weight-2 ap-mr-spacing-5">{name}</h1>
        {(() => {
          if (status === "draft") {
            return <Tag className="ap-bg-color-background-warning ap-text-color-text-body" closable={false}>Draft</Tag>
          } else if (status === "published") {
            return <Tag className="ap-bg-color-background-success" closable={false}>Published</Tag>
          } else if (status === "new") {
            return <Tag className="ap-bg-color-background-danger" closable={false}>New</Tag>
          }
        })()}
      </div>
      <GameTabs />
    </div>
  )
}

export default GameHeader
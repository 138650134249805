import { Footer } from "@appkit4/react-components/footer";
import styles from "./AppFooter.module.scss";

const AppFooter = () => {
  const footerContent =
    "© 2024 PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.";
  const footerType = "links";
  const footerLinks = [
    {
      name: "Privacy Statement",
      href: "https://www.pwc.co.uk/who-we-are/privacy-statement.html",
      target: "_blank",
    },
    {
      name: "Cookie Information",
      href: "https://www.pwc.co.uk/who-we-are/cookies.html",
      target: "_blank",
    },
    {
      name: "Terms and conditions",
      href: "https://www.pwc.co.uk/who-we-are/terms-and-conditions.html",
      target: "_blank",
    },
  ];

  return (
    <Footer
      className={styles.footer}
      content={footerContent}
      type={footerType}
      links={footerLinks}
    ></Footer>
  );
};

export default AppFooter;

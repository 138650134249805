import UIScene from "@/phaser/scenes/UIScene";
import OnboardingMainScreen from "./OnboardingMainScreen";
import OnboardingScreenBase from "./OnboardingScreenBase";
import GameScene from "@/phaser/scenes/GameScene";

export default class TeamName extends OnboardingScreenBase {
  private readonly marginLeft: number;
  private readonly formHeaderText: Phaser.GameObjects.Text;
  private readonly formTeamName: Phaser.GameObjects.DOMElement;
  private readonly headerForm: Phaser.GameObjects.DOMElement;

  constructor(
    scene: UIScene,
    marginLeft: number,
    callback: () => void = () => {}
  ) {
    super(scene, callback);
    
    this.marginLeft = marginLeft;

    this.headerForm = new Phaser.GameObjects.DOMElement(this.scene, this.marginLeft, 350, "div", OnboardingMainScreen.STYLE_H1, "Decide on a team name").setOrigin(0);
    this.add(this.headerForm);

    this.formHeaderText = new Phaser.GameObjects.Text(
      this.scene, 
      this.marginLeft, 
      460,
      "Give your team a name, this will be visible to other teams",
      {
        font: "400 24px Arial",
        color: "#ffffff",
        align: "left",
    }
    ).setOrigin(0).setLetterSpacing(-0.4);
    this.add(this.formHeaderText);

    this.formTeamName = new Phaser.GameObjects.DOMElement(this.scene, this.marginLeft, 510, "h1", "", "").setOrigin(0);
    this.add(this.formTeamName);
  }

  public activate(): void {
    this.formTeamName.createFromCache('team-name-form');

    const button = this.formTeamName.getChildByName('submitButton')!;
    button.addEventListener('click', this.onContinue);
  }

  private onContinue = (event: Event) => {
    event.preventDefault();
      const teamNameInput = this.formTeamName.getChildByName('teamName')!;

      // Validate the input fields
      if (!teamNameInput.checkValidity()) {
        teamNameInput.reportValidity(); // Show validation message
        return;
      }

      const teamName = teamNameInput?.value;

      const gameScene = this.scene.sourceScene as GameScene;
      const currentPlayer = gameScene.session!.players.find(p => p.id === gameScene.user!.id);

      const updateSessionPayload = {
        sessionId: gameScene.game.session._id,
        firstName: currentPlayer.firstName,
        lastName: currentPlayer.lastName,
        teamName: teamName,
        playerId: gameScene.user.id,
      };
      
      gameScene.game.socketEmit("updateSession", updateSessionPayload);

      this.onCloseCallback();
  }
}

import UIScene from "@/phaser/scenes/UIScene";
import OnboardingMainScreen from "./OnboardingMainScreen";
import GameScene from "@/phaser/scenes/GameScene";
import OnboardingScreenBase from "./OnboardingScreenBase";


export default class GameMission extends OnboardingScreenBase {
  private readonly marginLeft: number;
  private readonly continueButtonText: Phaser.GameObjects.Text;
  private readonly continueButtonBackground: Phaser.GameObjects.Graphics;
  private readonly contentForm: Phaser.GameObjects.DOMElement;
  private readonly headerForm: Phaser.GameObjects.DOMElement;
  private readonly imageForm: Phaser.GameObjects.DOMElement;

  constructor(
    scene: UIScene,
    marginLeft: number,
    callback: () => void = () => {}
  ) {
    super(scene, callback);

    this.marginLeft = marginLeft;

    this.headerForm = new Phaser.GameObjects.DOMElement(this.scene, this.marginLeft, 170, "div", OnboardingMainScreen.STYLE_H1, "Your mission:").setOrigin(0);
    this.add(this.headerForm);

    this.contentForm = new Phaser.GameObjects.DOMElement(this.scene, this.marginLeft, 280).setOrigin(0);
    this.add(this.contentForm);

    this.imageForm = new Phaser.GameObjects.DOMElement(this.scene, 1000, 310);
    this.add(this.imageForm);

    const paddingX = 24;
    const paddingY = 8;

    this.continueButtonText = new Phaser.GameObjects.Text(this.scene, this.marginLeft + paddingX, 660, "Start game", {
      font: "500 16px Arial",
        color: "#000000",
        align: "center",
    }).setOrigin(0);
    
    this.continueButtonBackground = new Phaser.GameObjects.Graphics(this.scene);
    this.continueButtonBackground.fillStyle(0xEBEBEB, 1);
    this.continueButtonBackground.fillRoundedRect(
      this.continueButtonText.getTopLeft().x! - paddingX,
      this.continueButtonText.getTopLeft().y! - paddingY,
      this.continueButtonText.width + 2 * paddingX,
      this.continueButtonText.height + 2 * paddingY,
      5 
    );
    this.add(this.continueButtonBackground);
    this.add(this.continueButtonText);

    this.continueButtonText.setInteractive().on(Phaser.Input.Events.POINTER_DOWN, this.onContinue);
    this.continueButtonBackground.setInteractive(
      new Phaser.Geom.Rectangle(
        this.continueButtonText.getTopLeft().x! - paddingX, 
        this.continueButtonText.getTopLeft().y! - paddingY, 
        this.continueButtonText.width + 2 * paddingX,this.continueButtonText.height + 2 * paddingY), Phaser.Geom.Rectangle.Contains
    ).on(Phaser.Input.Events.POINTER_DOWN, this.onContinue);
  }

  public activate(): void {
    const imageHtml = `<img src="/phaser-assets/Finance-report.png" />`;
    this.imageForm.createFromHTML(imageHtml);
    
    let contentText = `Our CFO, David Barrett, has been unexpectedly incapacitated, leaving a crucial financial report hidden behind his notorious puzzles.<br/>
    This report is critical for today’s stakeholder meeting, which is just one hour away.<br/>
    Your mission is to solve these puzzles to retrieve the report and maintain our professional standing.<br/>
    The outcome of today's meeting, rests on your shoulders... good luck.`;

    if (this.scene.sourceScene instanceof GameScene) {
      contentText = (this.scene.sourceScene as GameScene).session?.game.gameMission;
    }
    const html = `<div style="font: 400 24px Arial; color: #ffffff; text-align: left; width: 760px;">${contentText}</div>`;
    this.contentForm.createFromHTML(html);
    
  }

  private onContinue = () => {
    this.onCloseCallback();
  }
}

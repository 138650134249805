import React from "react";
import {
  Button,
  Tooltip,
  Modal,
  TextArea,
  Upload,
  Switch,
  Input,
  Notification,
  toaster,
} from "@appkit4/react-components";
import styles from "../game-mission/GameMission.module.scss";
import { useState, useEffect } from "react";
import { useModal } from "./UnsavedChangesContext";
import { Room1PlaceHolderContent } from "@/constants";
import { useGameStore } from "@/store/game-store";

type Props = {};

const Puzzle1 = (props: Props) => {
  const theme = useGameStore((state) => state.game.industry);
  console.log("game state theme", theme);
  // This is game mission content from the constants file. Once ready, use the gameMissionContent (the line above).
  const thisRoom1 = Room1PlaceHolderContent.find(
    (content) => content.themeName === theme
  );
  const [headerValue, setHeaderValue] = useState(
    "Welcome to your interactive game!"
  );
  const [introValue, setIntroValue] = useState(
    "You're about to embark on an exciting journey filled with challenges testing your wits and your knowledge of the training program.\nGet ready to explore, solve puzzles, and collaborate with your team. Let's make this an unforgettable adventure!"
  );
  const modalContext = useModal();
  const isModalOpen = modalContext?.isModalOpen || false;
  const showModal = modalContext?.showModal || (() => {});
  const hideModal = modalContext?.hideModal || (() => {});
  const [isIntroActive, setIsIntroActive] = useState(true);

  const formatListItem = (content) => {
    const childPoints = content.children.map((child) => {
      const [childPointHeading, ...childRest] = child.split(":");

      return (
        <ul>
          <li>
            <strong>{childPointHeading}:</strong>
            {childRest.join(":")}
          </li>
        </ul>
      );
    });
    return (
      <>
        <div className="ap-mb-spacing-4 ap-font-weight-3">{content.content}</div>
        <div>
          {childPoints}
        </div>
      </>
    );
  };

  const savedIntroActive = (
    <Notification
      title=""
      message={<div>Your introduction has been added into the game</div>}
      status="success"
    ></Notification>
  );

  const savedIntroInactive = (
    <Notification
      title=""
      message={<div>Your introduction has been removed from the game</div>}
      status="success"
    ></Notification>
  );

  const onUploadChange = (file: File, fileList: FileList): void => {
    console.log("onChange", file, fileList);
    modalContext?.setIsChanged(true);
    console.log("game editor is changed: ", modalContext?.isChanged);
  };
  const uploadFiles = (fileList: any) => {
    console.log(fileList);
  };

  const handleSavedClose = () => {
    console.log("Closed!");
  };

  const handleSaveClick = () => {
    if (isIntroActive) {
      toaster.notify(savedIntroActive, {
        position: "topHeader",
        onClose: handleSavedClose,
      });
    } else {
      toaster.notify(savedIntroInactive, {
        position: "topHeader",
        onClose: handleSavedClose,
      });
    }
  };

  const onHeaderChange = (value: any, event: any) => {
    setHeaderValue(value);
    modalContext?.setIsChanged(true);
    console.log("game editor is changed: ", modalContext?.isChanged);
  };

  const onIntroChange = (value: any, event: any) => {
    setIntroValue(value);
    modalContext?.setIsChanged(true);
  };

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={styles.headerContainer}>
          <h1 className="ap-typography ap-font-weight-2">
            {thisRoom1.Puzzle1Title}
          </h1>
        </div>
        <div className={styles.inlineSpans} />
        <Tooltip
          trigger="hover"
          position="top-left"
          distance={4}
          id="tooltipDesc"
          appendAfterTarget={true}
          content="Toggle ON to add the room. Toggle OFF to remove. Any added content remains when the toggle is off and can be reinstated by turning it back ON."
        >
          <Switch
            defaultChecked={isIntroActive}
            onChange={() => {
              setIsIntroActive((prevState) => {
                // modalContext?.setIsChanged(true);
                // console.log('game editor is changed: ', modalContext?.isChanged)
                return !prevState;
              });
            }}
            style={{ paddingBottom: "10px", paddingTop: "20px" }}
          >
            {isIntroActive ? "Add Room" : "Room Removed"}
          </Switch>
        </Tooltip>
        <div className={styles.divider}></div>
        <h3 className="ap-font-weight-2">Challenge</h3>
        <p
          className="ap-typography-body"
          style={{ marginBottom: "20px", width: "940px" }}
        >
          {thisRoom1?.Puzzle1ChallengeDescription}
        </p>
        <h3 className="ap-font-weight-2">Solution</h3>
        <ol className="ap-typography-body" style={{ width: "940px" }}>
          {thisRoom1?.Puzzle1Bullets.map((bullet: any, index: any) => (
            <li key={index} className="ap-mb-spacing-4">{formatListItem(bullet)}</li>
          ))}
        </ol>
        <Tooltip
          trigger="hover"
          position="top-left"
          distance={4}
          id="tooltipDesc"
          appendAfterTarget={true}
          content="Toggle ON to include an intro for the context; OFF for a direct game start. Intro content is retained, simply hidden from view."
        ></Tooltip>
      </div>
      <Modal
        visible={false}
        title={"Remove room?"}
        ariaLabel={"Remove room?"}
        // onCancel={hideModal}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
          marginTop: "-8px",
          minHeight: "64px",
        }}
        header={""}
        icons={""}
        footer={
          <>
            <Button onClick={() => {}} kind="secondary">
              Cancel
            </Button>
            <Button onClick={() => {}}>Remove room</Button>
          </>
        }
        bodyStyle={{ minHeight: "92px" }}
      >
        <p>
          This room will be hidden from players upon removal. Rest assured, any
          training questions added will be safely stored.
          <br />
          <br />
          Please move content to other rooms before disabling if needed.
          <br />
          <br />
          Re-enabling later will restore all content as you left it, ready for
          edits or use.
          <br />
        </p>
      </Modal>
    </>
  );
};

export default Puzzle1;

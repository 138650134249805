import { HEADER_HEIGHT } from "@/constants";
import UIScene from "@/phaser/scenes/UIScene";

export default class Clock extends Phaser.GameObjects.Container {
    public readonly scene: UIScene;
    private timeLeftInSeconds: number;
    private timeOverInSeconds: number;
    private readonly minutesText: Phaser.GameObjects.Text;
    private readonly secondsText: Phaser.GameObjects.Text;
    private readonly countdownImage: Phaser.GameObjects.Image;
    private started: boolean = false;
    private currentTimerEvent: Phaser.Time.TimerEvent;
    
    constructor(
        scene: UIScene,
        time: number
      ) {
        super(scene);

        this.scene = scene;
        this.timeLeftInSeconds = time;
        this.timeOverInSeconds = 0;

        this.countdownImage = new Phaser.GameObjects.Image(
            scene, 
            -5,
            HEADER_HEIGHT / 2 - 8, 
            "countdown"
          )
            .setOrigin(0, 0.5)
            .setInteractive()
          this.add(this.countdownImage);

        const letterSpacing = 9;

        this.minutesText = new Phaser.GameObjects.Text(
            this.scene,
            0, 
            0,
            "",
            {
                font: "500 32px Arial",
                color: "#ffffff",
                align: "center",
            }
        ).setLetterSpacing(letterSpacing);
        this.add(this.minutesText);

        this.secondsText = new Phaser.GameObjects.Text(
            this.scene,
            72, 
            0,
            "",
            {
                font: "500 32px Arial",
                color: "#ffffff",
                align: "center",
            }
        ).setLetterSpacing(letterSpacing);
        this.add(this.secondsText);

        this.updateDisplayedTime(this.timeLeftInSeconds);
    }

    private formatTime(seconds: number) : [string, string] {
        const minutes = Math.floor(seconds / 60);
        const partInSeconds = seconds % 60;
        
        return [ this.getFormattedTimePart(minutes), this.getFormattedTimePart(partInSeconds)];
    }

    private getFormattedTimePart(value: number) : string {
        return value.toString().padStart(2, "0");
    }

    start() : void {
        this.started = true;
        this.currentTimerEvent = this.scene.time.addEvent({ 
            delay: 1000, 
            callback: this.onEvent, 
            callbackScope: this, 
            loop: true 
        });
    }

    stop() : void {
        if (this.currentTimerEvent) {
            this.scene.time.removeEvent(this.currentTimerEvent);
        }
    }

    getRemainingTime() : number {
        if (this.timeLeftInSeconds > 0) {
            return this.timeLeftInSeconds;
        }

        return this.timeOverInSeconds * -1;
    }

    private onEvent () {
        if (!this.started) {
            return;
        }

        let displayedTime; 
        
        if (this.timeLeftInSeconds == 0) {
            const color = "#c52a1a";
            this.minutesText.setColor(color);
            this.secondsText.setColor(color);
            this.countdownImage.setTexture("countdown-over");
        }
        
        if (this.timeLeftInSeconds > 0) {
            this.timeLeftInSeconds -= 1;
            displayedTime = this.timeLeftInSeconds;
            
        } else {
            this.timeOverInSeconds += 1;
            displayedTime = this.timeOverInSeconds;
        }

        this.updateDisplayedTime(displayedTime);
    }

    private updateDisplayedTime(timeInSeconds: number) {
        const [minutes, seconds] = this.formatTime(timeInSeconds);

        this.minutesText.setText(minutes);
        this.secondsText.setText(seconds);
    }
}
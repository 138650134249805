import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import CloseButton from "../../ui/CloseButton";
import PreviewRoomObject from "../../RoomObject/PreviewRoomObject";
import GameRoomObject from "../../RoomObject/GameRoomObject";
import PopupBackground from "../../ui/PopupBackground";
import HintBar from "../../ui/HintBar";
import EventBridge from "@/utils/EventBridge";

const COLOR_BLACK = "black";
const COLOR_WHITE = "white";
const BORDER_PADDING = 10;

export default class Checkers extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly callback: () => void;
  private readonly popupBackground: PopupBackground;
  private readonly buttonTextControls: Array<Phaser.GameObjects.Text> = [];
  private readonly buttonGraphics: Array<Phaser.GameObjects.Graphics> = [];
  private readonly checkersImages: Array<Phaser.GameObjects.Image> = [];
  private readonly zones:  Array<Phaser.GameObjects.Zone> = [];
  private readonly closeButton: CloseButton;
  private readonly hintBar: HintBar;
  private readonly border: Phaser.GameObjects.Rectangle;
  private readonly timerEvents: Phaser.Time.TimerEvent[] = [];

  private readonly INITIAL_BUTTON_COLOR = 0xffb600;
  private readonly ALTERNATE_BUTTON_COLOR = 0x415385;
  private readonly HINT_MESSAGE: string = "Might want to have a look under the mug for instructions";
  private readonly gameStatus: Array<number> = new Array(65).fill(0);

  private readonly gridConfiguration = {
    x: 0,
    y: 0,
    paddingX: 0,
    paddingY: 0,
    cardWidth: 87,
    cardHeight: 87,
    numberOfColumns: 8,
    buttonRadius: 0,
    includeZero: true,
    buttonTextFontStyle: {
      fontFamily: "Arial",
      fontSize: "10px",
      color: "#ffffff",
      align: "center",
      fontStyle: "bold",
    },
    buttonTextAlternateColor: "#000000"
  };

  constructor(
    scene: UIScene,
    text: string,
    sourceObject: BaseRoomObject,
    callback: () => void = () => {}
  ) {
    super(scene, 0, 0);
    this.scene = scene;
    this.sourceObject = sourceObject;
    this.callback = callback;

    this.popupBackground = new PopupBackground(scene);
    this.add(this.popupBackground);

    this.hintBar = new HintBar(scene, this.HINT_MESSAGE);
    this.add(this.hintBar);

    this.closeButton = new CloseButton(this.scene, () => this.closeModal());
    this.add(this.closeButton);

    this.gridConfiguration.x = this.scene.scale.width / 2 - (this.gridConfiguration.cardWidth * 4);
    this.gridConfiguration.y = 100;

    this.border = new Phaser.GameObjects.Rectangle(
      this.scene, 
      this.gridConfiguration.x - BORDER_PADDING,
      this.gridConfiguration.y - BORDER_PADDING,
      this.gridConfiguration.cardWidth * this.gridConfiguration.numberOfColumns + 2 * BORDER_PADDING,
      this.gridConfiguration.cardHeight * this.gridConfiguration.numberOfColumns + 2 * BORDER_PADDING,
      0x7f5b00,
      1
    ).setOrigin(0);
    this.add(this.border);

    this.initializeCheckers();

    this.scene.add.existing(this);
  }

  private isFieldBlue(fieldNumber: number): boolean {
    if (fieldNumber % 16 > 8 || fieldNumber % 16 == 0) {
      return fieldNumber % 2 === 1;
    }

    return fieldNumber % 2 === 0;
  }

  private initializeCheckers() {
    const NUMBER_OF_BUTTONS = 64;
    
    for (let i = 0; i < NUMBER_OF_BUTTONS; i++) {
      const x = this.gridConfiguration.x + (this.gridConfiguration.cardWidth + this.gridConfiguration.paddingX) * (i % this.gridConfiguration.numberOfColumns);
      const y = this.gridConfiguration.y + (this.gridConfiguration.cardHeight + this.gridConfiguration.paddingY) * Math.floor(i / this.gridConfiguration.numberOfColumns);
      const fieldNumber = i + 1;

      const isCurrentFieldBlue = this.isFieldBlue(fieldNumber);

      // const helpText = new Phaser.GameObjects.Text(this.scene, x, y, fieldNumber.toString(), this.gridConfiguration.buttonTextFontStyle);
      // helpText.setDepth(10);
      // this.buttonTextControls.push(helpText)

      const buttonBackground = new Phaser.GameObjects.Graphics(this.scene);
      buttonBackground.fillStyle(isCurrentFieldBlue ? this.ALTERNATE_BUTTON_COLOR : this.INITIAL_BUTTON_COLOR);
      buttonBackground.fillRoundedRect(x, y, this.gridConfiguration.cardWidth, this.gridConfiguration.cardHeight, this.gridConfiguration.buttonRadius);
      buttonBackground.setInteractive(new Phaser.Geom.Rectangle(x, y, this.gridConfiguration.cardWidth, this.gridConfiguration.cardHeight), Phaser.Geom.Rectangle.Contains);
      buttonBackground.setName(fieldNumber.toString());
      this.buttonGraphics.push(buttonBackground);

      const zone = new Phaser.GameObjects.Zone(
        this.scene,
        x + this.gridConfiguration.cardWidth / 2,
        y + this.gridConfiguration.cardHeight / 2,
        this.gridConfiguration.cardWidth,
        this.gridConfiguration.cardHeight
      ).setOrigin(0.5);

      zone.setRectangleDropZone(this.gridConfiguration.cardWidth, this.gridConfiguration.cardHeight);
      zone.setName(fieldNumber.toString());
      this.zones.push(zone);
      this.add(zone);

      this.gameStatus[fieldNumber] = 0;

      if (isCurrentFieldBlue) {
        const buttonText = new Phaser.GameObjects.Text(
          this.scene, 
          x + this.gridConfiguration.cardWidth - 10, 
          y + 10,
          (Math.ceil(fieldNumber / 2)).toString(),
          this.gridConfiguration.buttonTextFontStyle
          )
          .setOrigin(0.5)
          .setName(fieldNumber.toString())
    
        this.buttonTextControls.push(buttonText);

        if (fieldNumber <= 24 || fieldNumber >= 41) {
          const texture = "checker-" + (fieldNumber <= 24 ? COLOR_WHITE : COLOR_BLACK);
  
          const checkerImage: Phaser.GameObjects.Image = new Phaser.GameObjects.Image(this.scene, x + this.gridConfiguration.cardWidth / 2, y + this.gridConfiguration.cardHeight / 2, texture);
          checkerImage.setName(fieldNumber.toString());
          checkerImage.setData("texture", texture);
          checkerImage.setInteractive();
          
          this.checkersImages.push(checkerImage);
          this.scene.input.setDraggable(checkerImage);
          this.gameStatus[fieldNumber] = fieldNumber;
        }
      }
    }

    this.scene.input.on(Phaser.Input.Events.DRAG_END, this.onDragEnd);
    this.scene.input.on(Phaser.Input.Events.DRAG, this.onDrag);
    this.scene.input.on(Phaser.Input.Events.DROP, this.onDrop);

    // this.displayZones();

    this.add(this.buttonGraphics);
    this.add(this.buttonTextControls);
    this.add(this.checkersImages);
  }

  private onDragEnd = (pointer: Phaser.Input.Pointer, checkerGameObject: Phaser.GameObjects.Image, dragged: boolean) => {
    if (!dragged) {

      const checkerNumber = parseInt(checkerGameObject.name);
      const currentFieldNumber = this.gameStatus.indexOf(checkerNumber);
      
      // abort move
      const currentZone = this.zones.find(z => z.name === currentFieldNumber.toString());
        
      if (currentZone) {
        checkerGameObject.x = currentZone.x ;
        checkerGameObject.y = currentZone.y ;
      }

      return;
    }
  }

  private onDrag = (pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Image, dragX: number, dragY: number) => {
    // Calculate the boundaries of the checkerboard
    const boardLeft = this.border.getTopLeft().x! + BORDER_PADDING;
    const boardRight = this.border.getTopRight().x! - BORDER_PADDING;
    const boardTop = this.border.getTopCenter().y! + BORDER_PADDING;
    const boardBottom = this.border.getBottomCenter().y! - BORDER_PADDING;

    const minX = boardLeft + this.gridConfiguration.cardWidth / 2;
    const maxX = boardRight - this.gridConfiguration.cardWidth / 2;
    const minY = boardTop + this.gridConfiguration.cardHeight / 2;
    const maxY = boardBottom - this.gridConfiguration.cardHeight / 2;
      
    const clampedX = Phaser.Math.Clamp(dragX, minX, maxX);
    const clampedY = Phaser.Math.Clamp(dragY, minY, maxY);

    gameObject.x = clampedX;
    gameObject.y = clampedY;
  }

  private onDrop = (pointer: Phaser.Input.Pointer, checkerGameObject: Phaser.GameObjects.Image, dropZone: Phaser.GameObjects.Zone) => {
    const checkerNumber = parseInt(checkerGameObject.name);
    const currentFieldNumber = this.gameStatus.indexOf(checkerNumber);
    const newFieldNumber = parseInt(dropZone.name);

    const isDestinationFieldBlue = this.isFieldBlue(newFieldNumber);

    if (isDestinationFieldBlue === false || this.gameStatus[newFieldNumber] !== 0) {
      // abort move
      const currentZone = this.zones.find(z => z.name === currentFieldNumber.toString());
      
      if (currentZone) {
        checkerGameObject.x = currentZone.x;
        checkerGameObject.y = currentZone.y;
      }

      return;
    }

    this.gameStatus[currentFieldNumber] = 0;
    this.gameStatus[newFieldNumber] = checkerNumber;

    checkerGameObject.x = dropZone.x ;
    checkerGameObject.y = dropZone.y ;

    this.checkIfComplete();
  }

  private checkIfComplete() {
    const expectedFieldNumbersWithBlackCheckers = [25, 29];
    const expectedFieldNumbersWithWhiteCheckers = [27, 31];

    if (!this.areCheckersDoneInColor(COLOR_BLACK, expectedFieldNumbersWithBlackCheckers)) {
      return;
    }

    if (!this.areCheckersDoneInColor(COLOR_WHITE, expectedFieldNumbersWithWhiteCheckers)) {
      return;
    }

    this.scene.makeGameSceneUninteractive();
    this.closeButton.setVisible(false);
    this.scene.input.setDraggable(this.checkersImages, false);
    this.checkersImages.forEach(img => img.disableInteractive());

    this.complete();
  }

  private areCheckersDoneInColor(colorName: string, expectedFieldNumbers: Array<number>) {
    for (const fieldNumber of expectedFieldNumbers) {
      const checkerNumber = this.gameStatus[fieldNumber];

      if (checkerNumber == 0) {
        return false;
      }

      const checkerImage = this.checkersImages.find(checker => checker.name === checkerNumber.toString());
      if (!checkerImage) {
        return false;
      }
      
      const texture = checkerImage.getData("texture");
      if (!texture.includes(colorName)) {
        return false;
      }
    }

    return true;
  }

  private displayZones() {
    this.zones.forEach(zone => {
      const graphics = new Phaser.GameObjects.Graphics(this.scene);
      graphics.lineStyle(2, 0xffff00);
      graphics.strokeRect(
        zone.x - zone.input?.hitArea.width / 2, 
        zone.y - zone.input?.hitArea.height / 2, 
        zone.input?.hitArea.width, 
        zone.input?.hitArea.height);
      this.add(graphics);
    });
  }

  private complete() {
    this.scene.hud.setHintButtonVisible(false);
    const timerEvent = this.scene.time.delayedCall(2000, this.completeAction);
    this.timerEvents.push(timerEvent);
  }

  private completeAction = () => {
    if (this.scene.env === "preview") {
      (this.sourceObject as PreviewRoomObject).incrementProgress();
      (this.sourceObject as PreviewRoomObject).completeAction();
    } else {
      //(this.sourceObject as GameRoomObject).incrementProgress();
      EventBridge.emit("game.puzzleEnded");
      (this.sourceObject as GameRoomObject).completeAction(this.scene.sourceScene.user.id!);
    }

    this.closeModal(false);
  }

  private closeModal(hideHintButton: boolean = true) {
    if (hideHintButton) {
      this.scene.hud.setHintButtonVisible(false);
    }

    this.scene.time.removeEvent(this.timerEvents);
    this.scene.input.off(Phaser.Input.Events.DRAG, this.onDrag);
    this.scene.input.off(Phaser.Input.Events.DRAG_END, this.onDragEnd);
    this.scene.input.off(Phaser.Input.Events.DROP, this.onDrop);
    this.scene.makeGameSceneInteractive();
    this.destroy();
  }
}

import UIScene from "@/phaser/scenes/UIScene";
import OnboardingMainScreen from "./OnboardingMainScreen";
import OnboardingScreenBase from "./OnboardingScreenBase";
import GameScene from "@/phaser/scenes/GameScene";

export default class WelcomeScreen extends OnboardingScreenBase {
  private readonly marginLeft: number;
  private readonly formHeaderText: Phaser.GameObjects.Text;
  private readonly formPlayerDetails: Phaser.GameObjects.DOMElement;
  private readonly headerForm: Phaser.GameObjects.DOMElement;

  constructor(
    scene: UIScene,
    marginLeft: number,
    callback: () => void = () => {}
  ) {
    super(scene, callback);

    this.marginLeft = marginLeft;

    this.headerForm = new Phaser.GameObjects.DOMElement(this.scene, this.marginLeft, 290, "div", OnboardingMainScreen.STYLE_H1, "Welcome! Let's get you set up").setOrigin(0);
    this.add(this.headerForm);

    this.formHeaderText = new Phaser.GameObjects.Text(
      this.scene, 
      this.marginLeft, 
      406,
      "Please enter your first and last name to continue",
      {
        font: "400 24px Arial",
        color: "#ffffff",
        align: "left",
    }
    ).setOrigin(0).setLetterSpacing(-0.4);
    this.add(this.formHeaderText);

    this.formPlayerDetails = new Phaser.GameObjects.DOMElement(this.scene, this.marginLeft, 470).setOrigin(0);
    this.add(this.formPlayerDetails);
  }

  public activate(): void {
    this.formPlayerDetails.createFromCache('player-details-form');

    const button = this.formPlayerDetails.getChildByName('submitButton')!;
    button.addEventListener('click', this.onContinue);
  }

  private onContinue = (event: Event) => {
    event.preventDefault();
      const firstNameInput = this.formPlayerDetails.getChildByName('firstName');
      const lastNameInput = this.formPlayerDetails.getChildByName('lastName');

      // Validate the input fields
      if (!firstNameInput.checkValidity()) {
        firstNameInput.reportValidity();
        return;
      }
      
      if (!lastNameInput.checkValidity()) {
        lastNameInput.reportValidity();
        return;
      }

      const gameScene = this.scene.sourceScene as GameScene;
      const currentPlayer = gameScene.session!.players.find(p => p.id === gameScene.user!.id);

      const firstName = firstNameInput?.value;
      const lastName = lastNameInput?.value;

      currentPlayer.firstName = firstName;
      currentPlayer.lastName = lastName;

      this.onCloseCallback();
  }
}

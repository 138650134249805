import { HEADER_HEIGHT } from "@/constants";
import UIScene from "@/phaser/scenes/UIScene";
import EventBridge from "@/utils/EventBridge";

const HINT_HEIGHT = 40;

export default class HintBar extends Phaser.GameObjects.Container {
  private readonly hintBackground: Phaser.GameObjects.Rectangle;
  private readonly hintText: Phaser.GameObjects.Text;
  private readonly displayHintEventHandler = () => this.displayHint();

  constructor(
    scene: UIScene,
    hintMessage: string
  ) {
    super(scene, 0, 0);

    this.hintBackground = new Phaser.GameObjects.Rectangle(
      this.scene,
      0,
      HEADER_HEIGHT
    )
    .setSize(this.scene.scale.width, HINT_HEIGHT)
    .setFillStyle(0xffffff, 1)
    .setOrigin(0)
    .setVisible(false);
  
    this.add(this.hintBackground);
  
    this.hintText = new Phaser.GameObjects.Text(
      this.scene,
      this.scene.scale.width / 2,
      HEADER_HEIGHT + HINT_HEIGHT / 2,
      hintMessage, 
      {
        fontFamily: "Arial",
        fontSize: "20px",
        color: "#000000",
        align: "center",
      }
    ).setOrigin(0.5)
    .setVisible(false);

    this.add(this.hintText);

    EventBridge.on("ui.hintUsed", this.displayHintEventHandler);
  }

  displayMessage(message: string) {
    this.hintText.setText(message);
    this.displayHint();
  }

  hideMessage() {
    this.hintText.setAlpha(0).setVisible(true);
    this.hintBackground.setAlpha(0).setVisible(true);
  }

  setMessage(message: string) {
    this.hintText.setText(message);
  }
    
  destroy(): void {
    EventBridge.remove("ui.hintUsed");
  }
    
  private displayHint() {
    this.hintText.setAlpha(0).setVisible(true);
    this.hintBackground.setAlpha(0).setVisible(true);

    this.scene.tweens.add({
      targets: this.hintText,
      alpha: 1,
      duration: 1000,
      ease: 'Linear',
    });

    this.scene.tweens.add({
      targets: this.hintBackground,
      alpha: 1, 
      duration: 1000,
      ease: 'Linear',
    });
  }
}
import { Input, Select } from "@appkit4/react-components";
import { IAction, IRoomObject } from "escape-rooms-types/types/game";

import { imagePopupChoices, unlockImageChoices } from "@/constants";
import styles from "./../ObjectConfigAction.module.scss";

interface IActionDisplayImageProps {
  action: IAction;
  selected: IRoomObject;
  updateActionProperties: (payload: Partial<IAction>) => void;
  type:
  | "displayImage"
  | "displayUnlockScreen";
}

const labels = {
  "displayImage": {
    selectLabel: "What's the image?",
    textLabel: "Hint message",
    dataSource: imagePopupChoices
  },
  "displayUnlockScreen": {
    selectLabel: "What's the image?",
    textLabel: "Message",
    dataSource: unlockImageChoices
  }
}

const ActionDisplayImage = ({
  action,
  selected,
  updateActionProperties,
  type = "displayImage"
}: IActionDisplayImageProps, 
) => {
  const selectLabel = labels[type].selectLabel;
  const textLabel = labels[type].textLabel;
  
  const updateModalPayload = (payload: Partial<IAction>) => {
    updateActionProperties({
      modalPayload: {
        ref: payload.modalPayload.ref || "",
        message: payload.modalPayload.message || action.modalPayload.message,
        title: payload.modalPayload.title ,
      },
    });
  };

  return (
    <>
      <div className="col-4">
        <span className="input-label">{selectLabel}</span>
        <Select className={styles["select-dropdown"]}
          placeholder=""
          data={[
            ...labels[type].dataSource.map((choice) => ({
              label: choice,
              value: choice,
            })),
          ]}
          value={action?.modalPayload?.message}
          onSelect={(e) => {
            updateModalPayload({
              modalPayload: {
                ref: action?.modalPayload?.ref || "",
                message: e,
              },
            });
          }}
        />
      </div>
      <div className="col-8">
        <span className="input-label">{textLabel}</span>
        <Input 
          maxLength={500}
          value={action?.modalPayload?.title}
          onChange={(e) => {
            updateModalPayload({
              modalPayload: {
                title: e,
              },
            });
          }}
        />
      </div>
    </>
  );
};

export default ActionDisplayImage;

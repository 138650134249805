import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import CloseButton from "../../ui/CloseButton";
import PopupBackground from "../../ui/PopupBackground";
import HintBar from "../../ui/HintBar";
import Clock from "../controls/Clock";
import { SCREEN_CENTER_X, SCREEN_CENTER_Y } from "@/constants";
import PreviewRoomObject from "../../RoomObject/PreviewRoomObject";
import GameRoomObject from "../../RoomObject/GameRoomObject";
import EventBridge from "@/utils/EventBridge";

const KEY_EXPECTED_HOUR = "hour";

export default class Clocks extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly callback: () => void;
  private readonly popupBackground: PopupBackground;
  private readonly closeButton: CloseButton;
  private readonly hintBar: HintBar;
  private readonly timerEvents: Phaser.Time.TimerEvent[] = [];
  private readonly clocks: Clock[] = [];

  private readonly HINT_MESSAGE: string = "Can you use the notes you'll find across the rooms to set the correct time?";
  private readonly clockLondon: Clock;
  private readonly clockParis: Clock;
  private readonly clockNewYork: Clock;

  constructor(
    scene: UIScene,
    text: string,
    sourceObject: BaseRoomObject,
    callback: () => void = () => {}
  ) {
    super(scene, 0, 0);

    this.scene = scene;
    this.sourceObject = sourceObject;
    this.callback = callback;

    this.popupBackground = new PopupBackground(scene);
    this.add(this.popupBackground);

    this.hintBar = new HintBar(scene, this.HINT_MESSAGE);
    this.add(this.hintBar);

    this.closeButton = new CloseButton(this.scene, () => this.closeModal());
    this.add(this.closeButton);

    const clocksPadding = 400;

    this.clockLondon = new Clock(scene, SCREEN_CENTER_X - clocksPadding, SCREEN_CENTER_Y, "LONDON", this.sourceObject, this.checkIfComplete);
    this.clockLondon.setData(KEY_EXPECTED_HOUR, 1);
    this.add(this.clockLondon);

    this.clocks.push(this.clockLondon);

    this.clockParis = new Clock(scene, SCREEN_CENTER_X, SCREEN_CENTER_Y, "PARIS", this.sourceObject, this.checkIfComplete);
    this.clockParis.setData(KEY_EXPECTED_HOUR, 4);
    this.add(this.clockParis);
    this.clocks.push(this.clockParis);

    this.clockNewYork = new Clock(scene, SCREEN_CENTER_X + clocksPadding, SCREEN_CENTER_Y, "NEW YORK", this.sourceObject, this.checkIfComplete);
    this.clockNewYork.setData(KEY_EXPECTED_HOUR, 2);
    this.add(this.clockNewYork);
    this.clocks.push(this.clockNewYork);

    this.scene.add.existing(this);
  }

  private checkIfComplete = () => {
    let isDone = true;
    
    this.clocks.forEach((clock: Clock) => {
      const selectedHour = clock.getSelectedHour();
      const selectedMinute = clock.getSelectedMinute();
      const expectedHour = clock.getData(KEY_EXPECTED_HOUR);

      if (selectedMinute !== 0 || selectedHour !== expectedHour) {
        isDone = false;
        return;
      }
    });

    if (isDone) {
      this.clocks.forEach((clock: Clock) => {
        clock.markAsDone();
      });

      this.complete();
    }
  }

  private complete() {
    this.scene.hud.setHintButtonVisible(false);
    const timerEvent = this.scene.time.delayedCall(2000, this.completeAction);
    this.timerEvents.push(timerEvent);
  }

  private completeAction = () => {
    if (this.scene.env === "preview") {
      (this.sourceObject as PreviewRoomObject).incrementProgress();
      (this.sourceObject as PreviewRoomObject).completeAction();
    } else {
      EventBridge.emit("game.puzzleEnded");
      //(this.sourceObject as GameRoomObject).incrementProgress();
      (this.sourceObject as GameRoomObject).completeAction(this.scene.sourceScene.user.id!);
    }

    this.closeModal(false);
  }

  private closeModal(hideHintButton: boolean = true) {
    if (hideHintButton) {
      this.scene.hud.setHintButtonVisible(false);
    }

    this.scene.time.removeEvent(this.timerEvents);
    this.scene.makeGameSceneInteractive();
    this.destroy();
  }
}

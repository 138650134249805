import api from "@/api";
import { IGameTemplateDto, IGame, IRoom } from "escape-rooms-types/types/game";

export function getGames(
  page: number = 1,
  limit: number = 10,
  sortBy: string = "_id",
  sortOrder: number = 1,
  searchQuery: string,
  status: string,
  client: string,
  industry: string
) {
  const query = new URLSearchParams({
    page: ((page - 1) * limit).toString(),
    limit: limit.toString(),
    sortOrder: sortOrder.toString(),
    sortBy,
  });

  if (searchQuery.length > 0) {
    query.append("query", searchQuery);
  }
  if (status.length > 0) {
    query.append("status", status);
  }
  if (client.length > 0) {
    query.append("client", client);
  }
  if (industry.length > 0) {
    query.append("industry", industry);
  }

  return api.get(
    `/games${query.toString().length > 0 ? "?" + query.toString() : ""}`
  );
}

export function getGame(gameId: string) {
  return api.get(`/games/${gameId}`);
}

export function createGame(game: Partial<IGame>) {
  return api.post(`/games`, game);
}

export function updateGame(gameId: string, game: IGame) {
  return api.put(`/games/${gameId}`, game);
}

export function deleteGame(gameId: string) {
  return api.delete(`/games/${gameId}`);
}

export function duplicateGame(game: IGame) {
  return api.post(`/games`, {
    ...game,
    _id: undefined,
    status: "draft",
  });
}

export function createRoom(gameId: string, room: IRoom) {
  return api.post(`/games/${gameId}/rooms`, room);
}

export function updateRoom(gameId: string, roomId: string, room: IRoom) {
  return api.put(`/games/${gameId}/rooms/${roomId}`, room);
}

export function deleteRoom(gameId: string, roomId: string) {
  return api.delete(`/games/${gameId}/rooms/${roomId}`);
}

export function getClientList() {
  return api.get(`/games/clients`);
}

export function createGameFromTemplate(creationParams: IGameTemplateDto) {
  return api.post(`/games/createGameFromTemplate`, creationParams);
}

import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import CloseButton from "../../ui/CloseButton";
import PopupBackground from "../../ui/PopupBackground";
import PreviewRoomObject from "../../RoomObject/PreviewRoomObject";
import GameRoomObject from "../../RoomObject/GameRoomObject";
import { SCREEN_CENTER_X, SCREEN_CENTER_Y } from "@/constants";


export default class UnlockScreen extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly callback: () => void;
  private readonly textObject: Phaser.GameObjects.Text;
  
  private readonly timerEvents: Phaser.Time.TimerEvent[] = [];
  private readonly popupBackground: PopupBackground;
  private readonly assetImage: Phaser.GameObjects.Image;
  
  constructor(
    scene: UIScene,
    imageType: string,
    text: string,
    sourceObject: BaseRoomObject,
    callback: () => void = () => {}
  ) {
    super(scene, 0, 0);
    this.scene = scene;
    this.sourceObject = sourceObject;
    this.callback = callback;

    this.scene.hud.setHintButtonVisible(false);

    this.popupBackground = new PopupBackground(scene, 1);
    this.add(this.popupBackground);

    const closeButton = new CloseButton(this.scene, () => this.closeModal());
    this.add(closeButton);

    this.assetImage = new Phaser.GameObjects.Image(
        scene,
        SCREEN_CENTER_X * 5 / 8,
        SCREEN_CENTER_Y,
        imageType
      ).setOrigin(0.5);
  
      this.add(this.assetImage);

    this.textObject = new Phaser.GameObjects.Text(
      scene, 
      this.assetImage.getTopCenter().x!, 
      this.assetImage.getTopCenter().y! - 20, 
      text, 
      {
        fontFamily: "Arial",
        fontSize: "36px",
        color: "#ffffff",
        align: "center",
      })
      .setLineSpacing(8)
      .setOrigin(0.5, 1);

    this.add(this.textObject);

    this.scene.game.anims.create({
      key: "unlockAnimation", 
      frames: this.scene.game.anims.generateFrameNumbers('unlock'), 
      frameRate: 3, 
      repeat: 0 
    });

    const unlockSprite = new Phaser.GameObjects.Sprite(scene, SCREEN_CENTER_X * 11 / 8, SCREEN_CENTER_Y, "unlock");
    this.add(unlockSprite);

    const timerEvent = this.scene.time.delayedCall(500, () => {
      unlockSprite.anims.play("unlockAnimation");
    });

    this.timerEvents.push(timerEvent);
    
    this.scene.add.existing(this);
  }
 
  private closeModal() {
    if (this.scene.env === "preview") {
      (this.sourceObject as PreviewRoomObject).completeAction();
    } else {
      (this.sourceObject as GameRoomObject).completeAction(this.scene.sourceScene.user.id!);
    }

    this.scene.makeGameSceneInteractive();
    this.scene.time.removeEvent(this.timerEvents);
    this.scene.game.anims.remove('unlockAnimation');
    this.destroy();
  }
}

import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";

export default class Clock extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly countryNameText: Phaser.GameObjects.Text;
  private readonly clockImage: Phaser.GameObjects.Image;
  private readonly hour: Phaser.GameObjects.Rectangle;
  private readonly minute: Phaser.GameObjects.Rectangle;
  private readonly globalX: number;
  private readonly globalY: number;
  private readonly circle: Phaser.GameObjects.Graphics;
  private readonly clockCenter: any;
  private readonly callback: () => void;

  constructor(
    scene: UIScene,
    x: number, 
    y: number,
    cityName: string,
    sourceObject: BaseRoomObject,
    callback: () => void = () => {}
  ) {
    super(scene, x, y);
    this.scene = scene;
    this.sourceObject = sourceObject;
    this.callback = callback;

    this.setName(cityName);

    this.clockImage = new Phaser.GameObjects.Image(scene, 0, 0, "clock");
    this.add(this.clockImage);

    this.clockCenter = this.clockImage.getCenter();

    this.countryNameText = new Phaser.GameObjects.Text(scene, this.clockCenter.x! + 4, this.clockCenter.y! + 60, cityName, {
        fontFamily: "Roboto-Regular",
        fontSize: "20px",
        color: "#ffffff",
        align: "center",
      }
    ).setLineSpacing(8)
        .setOrigin(0.5, 0.5);
    this.add(this.countryNameText);

    this.circle = new Phaser.GameObjects.Graphics(this.scene);
    this.circle.fillStyle(0x000000, 1.0);
    this.circle.fillCircle(this.clockCenter.x! + 4, this.clockCenter.y!, 7);
    this.add(this.circle);

    this.hour = new Phaser.GameObjects.Rectangle(this.scene, this.clockCenter.x! + 4, this.clockCenter.y!, 8, 78, 0x000000, 1);
    this.hour.setOrigin(0.5, 1);
    this.add(this.hour);
    this.hour.setInteractive();
    this.hour.rotation = Math.PI / 18;
    this.scene.input.setDraggable(this.hour);
    this.hour.on(Phaser.Input.Events.DRAG, this.onDragHour);
    this.hour.on(Phaser.Input.Events.DRAG_END, this.onDragEnd);

    this.minute = new Phaser.GameObjects.Rectangle(this.scene, this.clockCenter.x! + 4, this.clockCenter.y!, 8, 140, 0x000000, 1);
    this.minute.setOrigin(0.5, 1);
    this.add(this.minute);
    this.minute.setInteractive();
    this.scene.input.setDraggable(this.minute);
    this.minute.on(Phaser.Input.Events.DRAG, this.onDragMinute);
    this.minute.on(Phaser.Input.Events.DRAG_END, this.onDragEnd);
    
    const containerWorldX = this.x;
    const containerWorldY = this.y;
    const localX = this.clockCenter.x!;
    const localY = this.clockCenter.y!;
    this.globalX = containerWorldX + localX;
    this.globalY = containerWorldY + localY;
  }

  public getSelectedHour(): number  {
    return this.mapAngleToHour(this.hour.rotation);
  }

  public getSelectedMinute(): number  {
    return this.mapAngleToMinute(this.minute.rotation);
  }

  onDragHour = (pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Rectangle, dragX: number, dragY: number) => {
    const angle = this.getAngle(pointer);
    this.hour.rotation = angle;
  };

  onDragMinute = (pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Rectangle, dragX: number, dragY: number) => {
    const angle = this.getAngle(pointer);
    this.minute.rotation = angle;
  };

  onDragEnd = () => {
    this.callback.call(this);
  }

  getAngle(pointer: Phaser.Input.Pointer): number {
    return Phaser.Math.Angle.Between(this.globalX, this.globalY, pointer.x, pointer.y) + Math.PI / 2;
  }

  mapAngleToHour(angle: number): number {
    return this.mapAngleToRange(angle, 12);
  }

  mapAngleToMinute(angle: number): number {
    return this.mapAngleToRange(angle, 60);
  }

  mapAngleToRange(angle: number, range: number): number {
    let normalizedAngle = angle;
    
    if (angle < 0) {
      normalizedAngle += 2 * Math.PI;
    }

    const number = (normalizedAngle / (2 * Math.PI)) * range;

    return Math.round(number) % range;
  }

  markAsDone() {
    const color = 0x21812D;
    this.hour.fillColor = color;
    this.minute.fillColor = color;
    this.circle.clear();
    this.circle.fillStyle(color, 1.0);
    this.circle.fillCircle(this.clockCenter.x! + 4, this.clockCenter.y!, 7);

    this.scene.input.setDraggable(this.hour, false);
    this.scene.input.setDraggable(this.minute, false);
  }

  closeModal() {
    this.scene.makeGameSceneInteractive();
    
    this.hour.off(Phaser.Input.Events.DRAG, this.onDragHour);
    this.hour.off(Phaser.Input.Events.DRAG_END, this.onDragEnd);
    
    this.minute.off(Phaser.Input.Events.DRAG, this.onDragMinute);
    this.minute.off(Phaser.Input.Events.DRAG_END, this.onDragEnd);

    this.scene.input.setDraggable(this.hour, false);
    this.scene.input.setDraggable(this.minute, false);
    
    this.destroy();
  }
}

import UIScene from "@/phaser/scenes/UIScene";
import OnboardingMainScreen from "./OnboardingMainScreen";
import GameScene from "@/phaser/scenes/GameScene";
import OnboardingScreenBase from "./OnboardingScreenBase";


export default class GameIntroduction extends OnboardingScreenBase {
  private readonly marginLeft: number;
  private readonly continueButtonText: Phaser.GameObjects.Text;
  private readonly continueButtonBackground: Phaser.GameObjects.Graphics;
  private readonly headerForm: Phaser.GameObjects.DOMElement;
  private readonly contentForm: Phaser.GameObjects.DOMElement;
  private readonly gameScene: GameScene;
  // private readonly imageForm: Phaser.GameObjects.DOMElement;

  constructor(
    scene: UIScene,
    marginLeft: number,
    callback: () => void = () => {}
  ) {
    super(scene, callback);

    this.gameScene = scene.sourceScene as GameScene;
    this.marginLeft = marginLeft;

    this.headerForm = new Phaser.GameObjects.DOMElement(this.scene, this.marginLeft, 210, "div", OnboardingMainScreen.STYLE_H1, "Welcome to our interactive game!").setOrigin(0);
    this.add(this.headerForm);

    this.contentForm = new Phaser.GameObjects.DOMElement(this.scene, this.marginLeft, 358).setOrigin(0);
    this.add(this.contentForm);

    // this.imageForm = new Phaser.GameObjects.DOMElement(this.scene, 600, 310);
    // this.add(this.imageForm);

    const paddingX = 24;
    const paddingY = 8;

    this.continueButtonText = new Phaser.GameObjects.Text(this.scene, this.marginLeft + paddingX, 783, "Continue", {
      font: "500 16px Arial",
        color: "#000000",
        align: "center",
    }).setOrigin(0);
    
    this.continueButtonBackground = new Phaser.GameObjects.Graphics(this.scene);
    this.continueButtonBackground.fillStyle(0xEBEBEB, 1);
    this.continueButtonBackground.fillRoundedRect(
      this.continueButtonText.getTopLeft().x! - paddingX,
      this.continueButtonText.getTopLeft().y! - paddingY,
      this.continueButtonText.width + 2 * paddingX,
      this.continueButtonText.height + 2 * paddingY,
      5 
    );
    this.add(this.continueButtonBackground);
    this.add(this.continueButtonText);

    this.continueButtonText.setInteractive().on(Phaser.Input.Events.POINTER_DOWN, this.onContinue);
    this.continueButtonBackground.setInteractive(
      new Phaser.Geom.Rectangle(
        this.continueButtonText.getTopLeft().x! - paddingX, 
        this.continueButtonText.getTopLeft().y! - paddingY, 
        this.continueButtonText.width + 2 * paddingX,this.continueButtonText.height + 2 * paddingY), Phaser.Geom.Rectangle.Contains
    ).on(Phaser.Input.Events.POINTER_DOWN, this.onContinue);
  }

  public activate(): void {
    let contentText = "You're about to embark on an exciting journey filled with challanges testing your wits and your knowledge of the training program. <br/><br/>Get ready to explore, solve puzzles, and collaborate with your team. Let's make this an unforgettable adventure!";

    const game = this.gameScene.session?.game;
    if (!game.displayIntroduction) {
      this.onCloseCallback();
      return;
    }

    // const imageHtml = `<img style="border: 2px solid #696969; border-radius: 5px;" src="/phaser-assets/video.png" />`;
    // this.imageForm.createFromHTML(imageHtml);
    
    contentText = game.introduction;
    const html = `<div style="font: 400 24px Arial; color: #ffffff; text-align: left; width: 1010px">${contentText}</div>`;
    this.contentForm.createFromHTML(html);
  }

  private onContinue = () => {
    this.onCloseCallback();
  }
}

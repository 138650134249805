import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import CloseButton from "../../ui/CloseButton";
import TVScreen from "../../ui/TVScreen";
import PreviewRoomObject from "../../RoomObject/PreviewRoomObject";
import GameRoomObject from "../../RoomObject/GameRoomObject";
import HintBar from "../../ui/HintBar";
import EventBridge from "@/utils/EventBridge";


export default class TVCode extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;

  private readonly sourceObject: BaseRoomObject;
  private readonly callback: () => void;
  private readonly descriptionTextObject: Phaser.GameObjects.Text;
  private readonly yPosition: integer;
  private readonly answerIconCorrect: Phaser.GameObjects.Image;
  private readonly answerIconWrong: Phaser.GameObjects.Image;
  private readonly closeButton: CloseButton;
  private readonly hintBar: HintBar;
  private readonly timerEvents: Phaser.Time.TimerEvent[] = [];

  private readonly ICON_PADDING: number = 30;
  private readonly VALID_ANSWER: string = "645";
  private readonly INITIAL_TEXT: string = "Enter 3 number password";
  private readonly HINT_MESSAGE: string = "Check 5th of April in the calendar";

  private underlineCodeTextObject!: Phaser.GameObjects.Text;
  private codeTextObject!: Phaser.GameObjects.Text;

  constructor(
    scene: UIScene,
    text: string,
    sourceObject: BaseRoomObject,
    callback: () => void = () => { }
  ) {
    super(scene, 0, 0);
    this.scene = scene;
    this.sourceObject = sourceObject;
    this.callback = callback;

    const tvScreen = new TVScreen(this.scene);
    this.add(tvScreen);

    this.hintBar = new HintBar(scene, this.HINT_MESSAGE);
    this.add(this.hintBar);

    this.closeButton = new CloseButton(this.scene, () => this.closeModal());
    this.add(this.closeButton);

    this.yPosition = this.scene.scale.height / 2;

    const lineHeight = 66;

    this.descriptionTextObject = new Phaser.GameObjects.Text(
      scene,
      scene.scale.width / 2,
      this.yPosition - lineHeight * 2,
      this.INITIAL_TEXT,
      this.getCodeFontStyle()
    )
      .setOrigin(0.5)
      .setLineSpacing(8);

    this.add(this.descriptionTextObject);

    this.answerIconCorrect = new Phaser.GameObjects.Image(
      this.scene,
      this.descriptionTextObject.x + this.descriptionTextObject.width / 2 + this.ICON_PADDING,
      this.descriptionTextObject.y,
      "answer-correct");
    this.answerIconCorrect.visible = false;
    this.add(this.answerIconCorrect);

    this.answerIconWrong = new Phaser.GameObjects.Image(
      this.scene,
      this.descriptionTextObject.x + this.descriptionTextObject.width / 2 + this.ICON_PADDING,
      this.descriptionTextObject.y,
      "answer-wrong");
    this.answerIconWrong.visible = false;
    this.add(this.answerIconWrong);

    this.initializeCodeTextArea(lineHeight);
    this.addKeyboardInputListener();
    this.scene.add.existing(this);
  }

  private addKeyboardInputListener() {
    this.scene.input.keyboard?.on("keydown", this.keydownCallback);
  }

  private updateCodeText(number: string) {
    const currentText = this.codeTextObject.text;

    if (currentText.length < this.VALID_ANSWER.length) {
      this.codeTextObject.setText(currentText + number);
    }

    this.checkIfComplete();
  }

  private initializeCodeTextArea(lineHeight: number) {
    this.codeTextObject = new Phaser.GameObjects.Text(
      this.scene,
      0,
      this.yPosition,
      "123",
      this.getCodeFontStyle()
    )
      .setLetterSpacing(30)
      .setLineSpacing(8);

    this.codeTextObject.setX(this.scene.scale.width / 2 - this.codeTextObject.width / 2);
    this.codeTextObject.setText("");
    this.add(this.codeTextObject);

    this.underlineCodeTextObject = new Phaser.GameObjects.Text(
      this.scene,
      this.scene.scale.width / 2,
      this.yPosition + lineHeight / 3,
      "___",
      this.getCodeFontStyle()
    )
      .setLineSpacing(8)
      .setLetterSpacing(30);

    this.underlineCodeTextObject.setX(this.scene.scale.width / 2 - this.underlineCodeTextObject.width / 2);
    this.add(this.underlineCodeTextObject);
  }

  private getCodeFontStyle(): Phaser.Types.GameObjects.Text.TextStyle {
    return {
      fontFamily: "Arial",
      fontSize: "58px",
      color: "#ffffff",
      align: "center",
    }
  }

  private checkIfComplete() {
    const currentCode = this.codeTextObject.text;

    if (currentCode === this.VALID_ANSWER) {
      this.scene.input.keyboard?.removeListener("keydown", this.keydownCallback);
      this.descriptionTextObject.setText("Correct");
      this.answerIconCorrect.setX(this.descriptionTextObject.x + this.descriptionTextObject.width / 2 + this.ICON_PADDING);
      this.answerIconCorrect.visible = true;
      this.underlineCodeTextObject.setColor("#21812D");

      this.complete();
    } else if (currentCode.length == this.VALID_ANSWER.length) {
      this.scene.input.keyboard?.removeListener("keydown", this.keydownCallback);
      this.descriptionTextObject.setText("Incorrect");
      this.answerIconWrong.setX(this.descriptionTextObject.x + this.descriptionTextObject.width / 2 + this.ICON_PADDING);
      this.answerIconWrong.visible = true;
      this.underlineCodeTextObject.setColor("#C52A1A");

      const timerEvent = this.scene.time.delayedCall(2000, () => {
        this.descriptionTextObject.setText(this.INITIAL_TEXT);
        this.answerIconWrong.visible = false;
        this.codeTextObject.setText("");
        this.underlineCodeTextObject.setColor("white");
        this.addKeyboardInputListener();
        this.timerEvents.pop();
      });

      this.timerEvents.push(timerEvent);
    }
  }

  private keydownCallback = (e: KeyboardEvent) => {
    const keyValue = parseInt(e.key);
    if (!isNaN(keyValue)) {
      this.updateCodeText(e.key);
    }
  }

  private complete() {
    
    this.scene.hud.setHintButtonVisible(false);
    const timerEvent = this.scene.time.delayedCall(2000, this.completeAction);
    this.timerEvents.push(timerEvent);
  }

  private completeAction = () => {
    if (this.scene.env === "preview") {
      (this.sourceObject as PreviewRoomObject).incrementProgress();
      (this.sourceObject as PreviewRoomObject).completeAction();
    } else {
      //(this.sourceObject as GameRoomObject).incrementProgress();
      EventBridge.emit("game.puzzleEnded");
      (this.sourceObject as GameRoomObject).completeAction(this.scene.sourceScene.user.id!);
    }

    this.closeModal(false);
  }

  private closeModal(hideHintButton: boolean = true) {
    if (hideHintButton) {
      this.scene.hud.setHintButtonVisible(false);
    }

    this.scene.input.keyboard?.removeListener("keydown", this.keydownCallback);
    this.scene.time.removeEvent(this.timerEvents);
    this.scene.makeGameSceneInteractive();
    this.destroy();
  }
}

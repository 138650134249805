export abstract class Status {
    constructor() {}

    abstract getHint(): string;
    abstract getMessage(): string;
}

export class StatusEmpty extends Status {
    getHint(): string {
        return "Find the key fob or the code"
    }

    getMessage(): string {
        return "Input code and tap fob";
    }
}

export class StatusCodeEmptyAndFoundKeyNotUsed extends Status {
    getHint(): string {
        return "Find the code or use the key fob you found"
    }

    getMessage(): string {
        return "Input code and tap fob";
    }
}

export class StatusCodeEmptyAndFoundKeyUsed extends Status {
    getHint(): string {
        return "Find the code"
    }

    getMessage(): string {
        return "Now input code to open";
    }
}

export class StatusCodeApprovedAndKeyNotFound extends Status {
    getHint(): string {
        return "Find the key fob"
    }

    getMessage(): string {
        return "Now use fob to open";
    }
}

export class StatusCodeApprovedAndKeyFound extends Status {
    getHint(): string {
        return "Use the key fob you found"
    }

    getMessage(): string {
        return "Now use fob to open ";
    }
}

export class StatusKeyFound extends Status {
    getHint(): string {
        return ""
    }

    getMessage(): string {
        return "Vault unlocked!";
    }
}


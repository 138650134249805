import UIScene from "@/phaser/scenes/UIScene";

export default abstract class OnboardingScreenBase extends Phaser.GameObjects.Container {
    public readonly scene: UIScene;
    protected readonly onCloseCallback: () => void;
    
    constructor (
        scene: UIScene,
        onCloseCallback: () => void
    ) {
        super(scene, 0, 0);
        
        this.scene = scene;
        this.onCloseCallback = onCloseCallback;
    }

    abstract activate(): void;
}
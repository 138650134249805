import UIScene from "@/phaser/scenes/UIScene";

export default class ProgressBar extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;

  private readonly background: Phaser.GameObjects.Graphics;
  private readonly bar: Phaser.GameObjects.Graphics;

  private readonly progressBarWidth: number;
  private readonly progressBarHeight: number;
  private readonly radius: number = 2;

  private progress: number = 0;

  constructor(scene: UIScene, width: number, height: number) {
    super(scene);
    this.scene = scene;

    this.progressBarWidth = width;
    this.progressBarHeight = height;

    this.background = new Phaser.GameObjects.Graphics(scene);
    this.background.fillStyle(0x6E7CA2, 0.9);
    this.background.fillRoundedRect(0, 0, width, height, this.radius);

    this.bar = new Phaser.GameObjects.Graphics(scene);

    this.add(this.background);
    this.add(this.bar);
  }

  public setProgress(progress: number) {
    this.progress = Math.min(1, progress);
    this.progress = Math.max(0, this.progress);

    if (progress == 0) {
      return;
    }

    this.bar.clear();
    this.bar.fillStyle(0xffffff);
    this.bar.fillRoundedRect(
      0,
      0,
      this.progressBarWidth * this.progress,
      this.progressBarHeight,
      this.radius
    );
  }
}

import {
  Button,
  Modal,
  Panel,
  Steppers,
  Stepper,
  Notification,
  toaster,
  Pagination,
  PaginationRef,
} from "@appkit4/react-components";
import {IGame} from "escape-rooms-types/types/game";
import { useLocation, useNavigate } from "react-router-dom";
import {createGame, getGames} from "@/api/game";
import styles from "./GameTheme.module.scss";
import { useState, useEffect, useRef } from "react";
import {useQuery} from "react-query";
import {v4 as uuidv4} from "uuid";
import { themes } from "@/constants";

const GameTheme = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const paginationRef = useRef<PaginationRef>(null);

  const [games, setGames] = useState<IGame[]>([]);

  const [searchString, setSearchString] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [client, setClient] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [sortBy, setSortBy] = useState<string>("_id");
  const [sortOrder, setSortOrder] = useState<number>(1);

  const [totalPages, setTotalPages] = useState<number>(1);
  const [showGameCreationModal, setShowGameCreationModal] = useState(false);
  const [showAdvancedModeModal, setShowAdvancedModeModal] = useState(false);
  const { selectedTheme } = location.state || { selectedTheme: "" };
  const [currentTheme, setCurrentTheme] = useState(selectedTheme);
  const firstFourThemes = themes.slice(0, 3);
  const lastFourThemes = themes.slice(3);

  const noThemeButtonError = (
    <Notification
      title='Error: No Theme Selected'
      message={(
        <div>
          You have not selected a theme. <br/>
          Please select a theme to continue.
        </div>
      )}
      status='error'
    />
  );

  const handleClose = () => {
    console.log('Closed!')
  }

  const handleThemeClick = (themeName: string) => {
    setCurrentTheme(themeName === currentTheme ? "" : themeName);
  };

  const createGameQuery = useQuery({
    queryKey: ["createGame"],
    queryFn: () =>
      createGame({
        name: "Untitled Game",
        rooms: [
          {
            name: "New Room",
            ref: uuidv4(),
            objects: [],
            items: [],
            isActive: true,
            length: 1,
            order: 0,
          },
        ],
      }),
    onSuccess: (res) => {
      navigate(`/games/${res.data._id}/advancedmode`);
      getGamesQuery.refetch();
    },
    enabled: false,
  });

  useEffect(() => {
    getGamesQuery.refetch();
  }, [page, limit, sortOrder, sortBy, searchString, status, client, industry]);

  const getGamesQuery = useQuery({
    queryKey: ["getGames", searchString],
    queryFn: () =>
      getGames(page, limit, sortBy, sortOrder, searchString, status, client, industry),
    onSuccess: (res) => {
      setGames(res.data.data);
      const totalPages = Math.max(Math.ceil(res.data.count / limit), 1);
      setTotalPages(totalPages);
      setPage(Math.min(page, totalPages));
      if (paginationRef.current) {
        paginationRef.current.setPageNumber(Math.min(page, totalPages));
      }
    },
  });

  return (
    <div className="ap-container">
      <div className="row">
        <h1 className="ap-typography-4 ap-font-weight-1 ap-mt-spacing-6 ap-mb-spacing-6">
          Create a new game
        </h1>
        <div className={`col-2 ${styles.colorContainer}`}>
          <Steppers
            orientation="vertical"
            readonly
            space={40}
            activeIndex={0}
          >
            <Stepper label="Theme" status="normal"></Stepper>
            <Stepper label="Template set up" status="normal"></Stepper>
            <Stepper label="Basic Information" status="normal"></Stepper>
          </Steppers>
        </div>
        <div className="col-8">
          <Panel className="ap-pt-spacing-3 ap-mb-spacing-6">
            <div className="row ap-my-spacing-6">
              <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">
                What theme is your training related to?
              </h1>
              <h2 className="ap-typography ap-font-weight-1 ap-mt-spacing-4 ap-mb-spacing-6">
                We will pick the most relevant template for you based on this.
              </h2>
              <div className="col-6">
                {firstFourThemes.map((theme) => (
                  <Button
                    key={theme.name}
                    className={`${styles.themeButtons} ${
                      theme.name === currentTheme ? "clicked" : ""
                    }`}
                    kind="secondary"
                    icon={theme.icon}
                    onClick={() => handleThemeClick(theme.name)}
                    style={{
                      boxShadow: "none",
                      border:
                        theme.name === currentTheme
                          ? "1px solid #D04A02"
                          : "1px solid #DDDDDD",
                      borderRight:
                        theme.name === currentTheme
                          ? "3px solid #D04A02"
                          : "1px solid #DDDDDD",
                      color:
                        theme.name === currentTheme ? "#D04A02" : "#474747",
                    }}
                  >
                    {theme.name}
                    {theme.name === currentTheme && (
                      <span className={`${styles.checkmarkIcon}`}>
                        <span
                          className="Appkit4-icon icon-circle-checkmark-fill ap-font-16"
                          aria-hidden="true"
                        ></span>
                      </span>
                    )}
                  </Button>
                ))}
              </div>
              <div className="col-6">
                {lastFourThemes.map((theme) => (
                  <Button
                    key={theme.name}
                    className={`${styles.themeButtons} ${
                      theme.name === currentTheme ? "clicked" : ""
                    }`}
                    kind="secondary"
                    icon={theme.icon}
                    onClick={() => handleThemeClick(theme.name)}
                    style={{
                      boxShadow: "none",
                      border:
                        theme.name === currentTheme
                          ? "1px solid #D04A02"
                          : "1px solid #DDDDDD",
                      borderRight:
                        theme.name === currentTheme
                          ? "3px solid #D04A02"
                          : "1px solid #DDDDDD",
                      color:
                        theme.name === currentTheme ? "#D04A02" : "#474747",
                    }}
                  >
                    {theme.name}
                    {theme.name === currentTheme && (
                      <span className={`${styles.checkmarkIcon}`}>
                        <span
                          className="Appkit4-icon icon-circle-checkmark-fill ap-font-16"
                          aria-hidden="true"
                        ></span>
                      </span>
                    )}
                  </Button>
                ))}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                kind="secondary"
                style={{ width: "100px" }}
                onClick={() => {
                  setShowGameCreationModal(true);
                }}
              >
                Cancel
              </Button>
              <Button
                kind="primary"
                style={{ width: "100px" }}
                className="col-6"
                onClick={() => {
                  if (currentTheme) {
                    navigate("/games/templatesetup", {
                      state: {
                        stepperActiveIndex: 1,
                        selectedTheme: currentTheme,
                        ...location.state
                      } });
                  } else {
                    toaster.notify(noThemeButtonError, { position: 'center', onClose: handleClose})}
                  }
                }
              >
                Continue
              </Button>
            </div>
          </Panel>
          <div className={`ap-my-spacing-4 ${styles.buttonContainer}`}>
            <Button
              kind="text"
              icon="icon-close-outline"
              onClick={() => {
                setShowAdvancedModeModal(true);
              }}
            >
              I don't want help setting up
            </Button>
          </div>
        </div>
      </div>
      <Modal
        visible={showAdvancedModeModal}
        title={"Switch to Advanced Mode?"}
        ariaLabel={"Switch to Advanced Mode?"}
        onCancel={() => setShowAdvancedModeModal(false)}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
          marginTop: "-8px",
          minHeight: "64px",
        }}
        header={""}
        icons={""}
        footer={
          <>
            <Button
              onClick={() => {
                setShowAdvancedModeModal(false);
                createGameQuery.refetch();
              }}
              kind="secondary"
            >
              Go to Advanced Mode
            </Button>
            <Button
              onClick={() => {
                setShowAdvancedModeModal(false);
              }}
            >
              Continue with template
            </Button>
          </>
        }
        bodyStyle={{ minHeight: "92px" }}
      >
        <p>
          You are about to switch to Advanced Mode. This action is irreversible.
          <br />
          <br />
          In Advanced Mode, you'll be responsible for creating and configuring
          each room and puzzle manually.
          <br />
          <br />
          Please be aware, you cannot return to the templated version once you
          proceed. Ensure you are ready for this level of customisation and
          control.
          <br />
        </p>
      </Modal>
      <Modal
        visible={showGameCreationModal}
        title={"Are you sure you want to leave?"}
        ariaLabel={"Are you sure you want to leave?"}
        onCancel={() => setShowGameCreationModal(false)}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
          marginTop: "-8px",
          minHeight: "64px",
        }}
        header={""}
        icons={""}
        footer={
          <>
            <Button
              onClick={() => {
                navigate("/");
                setShowGameCreationModal(false);
              }}
              kind="secondary"
            >
              Leave page
            </Button>
            <Button onClick={() => setShowGameCreationModal(false)}>
              Stay and continue
            </Button>
          </>
        }
        bodyStyle={{ minHeight: "92px" }}
      >
        <p>
          If you leave this page now, any selections you've made will be lost.
          Would you like to stay on this page and continue your progress?
        </p>
      </Modal>
    </div>
  );
};

export default GameTheme;

import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../../RoomObject/BaseRoomObject";

enum Colors {
  black = 0x000000,
  white = 0xffffff
}

export default class Introduction extends Phaser.GameObjects.Container {
    public readonly scene: UIScene;
    private readonly sourceObject: BaseRoomObject;
    private readonly onStartCallback: () => void;
  
    private readonly text1: Phaser.GameObjects.Text;
    private readonly text2: Phaser.GameObjects.Text;
    private readonly buttonBackground: Phaser.GameObjects.Graphics;
    buttonText: Phaser.GameObjects.Text;
    
    constructor(
        scene: UIScene,
        sourceObject: BaseRoomObject,
        onStartCallback: () => void = () => {},
        width: number, 
        height: number
    ) {
        super(scene, 0, 0);

        this.scene = scene;
        this.sourceObject = sourceObject;
        this.onStartCallback = onStartCallback;
        
        this.width = width;
        this.height = height;

        this.text1 = new Phaser.GameObjects.Text(
            this.scene,
            this.width / 2,
            222,
            "Excellent, you now each need to answer some questions to unlock the next puzzle!", 
            {
                fontFamily: "Arial",
                fontSize: "36px",
                color: "#ffffff",
                align: "center",
            }
        ).setWordWrapWidth(700)
        .setOrigin(0.5);
        
        this.add(this.text1);

        this.text2 = new Phaser.GameObjects.Text(
            this.scene,
            this.width / 2,
            356,
            "Each player must answer the questions independently. \nRemember, accuracy is key - incorrect answers will deduct points!", 
            {
                fontFamily: "Arial",
                fontSize: "20px",
                color: "#ffffff",
                align: "center",
            }
        ).setWordWrapWidth(700)
        .setOrigin(0.5);
        
        this.add(this.text2);

        this.buttonText = new Phaser.GameObjects.Text(
            this.scene,
            this.width / 2,
            466,
            "Let's go!",
            {
                fontFamily: "Arial",
                fontSize: "16px",
                color: "#000000",
                align: "center",
            }
        ).setOrigin(0.5);

        const buttonPaddingX = 20;
        const buttonPaddingY = 10;
        const buttonX = this.buttonText.x - this.buttonText.width / 2 - buttonPaddingX;
        const buttonY = this.buttonText.y - this.buttonText.height / 2 - buttonPaddingY;

        this.buttonBackground = new Phaser.GameObjects.Graphics(this.scene);
        this.buttonBackground.fillStyle(Colors.white);
        this.buttonBackground.fillRoundedRect(
            buttonX,
            buttonY,
            this.buttonText.width + 2 * buttonPaddingX,
            this.buttonText.height + 2 * buttonPaddingY,
            5); 

        this.add(this.buttonBackground);
        this.add(this.buttonText);

        this.buttonBackground.setInteractive(new Phaser.Geom.Rectangle(buttonX, buttonY, this.buttonText.width + 2 * buttonPaddingX, this.buttonText.height + 2 * buttonPaddingY), Phaser.Geom.Rectangle.Contains);
        this.buttonText.setInteractive();

        this.buttonBackground.on(Phaser.Input.Events.POINTER_DOWN, this.onStart);
        this.buttonText.on(Phaser.Input.Events.POINTER_DOWN, this.onStart);
    }

    private onStart = () => {
        this.onStartCallback();
    }
}

import {
  Button,
  Input,
  Tag,
  Table,
  Column,
  TextArea,
  Loading,
  Tooltip,
  Panel,
  Switch,
  Upload,
} from "@appkit4/react-components";
import { useNavigate } from "react-router-dom";
import styles from "./GameSettings2.module.scss";
import React, { useState, useEffect, ReactElement } from "react";
import { useGameStore } from "@/store/game-store";
import { useQuery } from "react-query";
import { getGame } from "@/api/game";
import GameEditorSidebar from "@/components/game-editor-sidebar/GameEditorSidebar";
import GameTabs from "../game-tabs/GameTabs";
import { durations, menuItems } from "@/constants";
import GameMission from "../game-mission/GameMission";
import IntroductionTest from "../game-customisation-template-pages/IntroductionTest";
import svg1 from "../../../public/asset-state-textures/fs-room1-background_default.svg";
import svg2 from "../../../public/asset-state-textures/fs-room2-background_default_light_on.svg";
import svg3 from "../../../public/asset-state-textures/fs-room3-background_default.svg";

const GameSettings2 = () => {
  const navigate = useNavigate();
  const name = useGameStore((state) => state.game.name);
  const status = useGameStore((state) => state.game.status);
  const rooms = useGameStore((state) => state.game.rooms);
  const { setGame } = useGameStore((state) => state);
  const [isIntroActive, setIsIntroActive] = useState(false);
  const [titleValue, setTitleValue] = useState("");
  const [headerValue, setHeaderValue] = useState("");
  const [bodyValue, setBodyValue] = useState("");
  const [questionTitleError, setQuestionTitleError] = useState(false);
  const [questionHeaderError, setQuestionHeaderError] = useState(false);
  const [questionBodyError, setQuestionBodyError] = useState(false);
  const [additionalDetails, setAdditionalDetails] = useState("");
  const activeRooms = rooms.filter((room) => room.isActive && room.isVisible);

  const tdata: Array<any> = [
    {
      name: "Oliver Smith (you)",
      email: "oliver.smith@pwc.com",
      actions: "",
    },
  ];

  const [originalData, setOriginalData] = React.useState(tdata);

  const renderTotalCell = (row: any, field: string) => {
    if (!(field in row)) return "";

    return `${row[field]}`;
  };

  const onUploadChange = (file: File, fileList: FileList): void => {
    console.log("onChange", file, fileList);
    //   modalContext?.setIsChanged(true);
    //   console.log('game editor is changed: ', modalContext?.isChanged)
  };
  const uploadFiles = (fileList: any) => {
    console.log(fileList);
  };

  const onAdditionalDetailsChange = (value: any, event: any) => {
    setAdditionalDetails(value);
  };

  const onTitleChange = (value: any, event: any) => {
    setTitleValue(value);
  };

  const onHeaderChange = (value: any, event: any) => {
    setHeaderValue(value);
  };

  const onBodyChange = (value: any, event: any) => {
    setBodyValue(value);
  };

  const loadGame = (gameData: any) => {
    if (gameData == null) {
      return;
    }
    setGame(gameData);
  };
  const gameId = location.pathname.split("/").at(-2);
  const getGameQuery = useQuery({
    queryKey: ["getGame"],
    queryFn: () => getGame(gameId!),
    onSuccess: (res) => {
      loadGame(res.data.data);
    },
  });

  useEffect(() => {
    getGameQuery.refetch();
  }, []);

  // TODO: Replace this placeholder component with the actual Introduction component.
  const [bodyComponent, setBodyComponent] = useState<ReactElement | null>(
    <IntroductionTest />
  );
  const [selectedTopLevelIndex, setSelectedTopLevelIndex] = useState(0);
  const [selectedPuzzleIndex, setSelectedPuzzleIndex] = useState<number | null>(
    null
  );
  const [selectedTrainingQuestionIndex, setSelectedTrainingQuestionIndex] =
    useState<number | null>(null);

  const getGameDuration = (numberOfRooms: number) => {
    const duration = durations.find(
      (d) => numberOfRooms == parseInt(d.rooms.split("")[0])
    );
    if (duration) {
      return duration.name;
    }
    return "";
  };

  const getNumberOfPuzzle = (numberOfRooms: number) => {
    const duration = durations.find(
      (d) => numberOfRooms == parseInt(d.rooms.split("")[0])
    );
    if (duration) {
      const { puzzles } = duration;
      return puzzles;
    }
    return "";
  };

  const getRoomId =
    menuItems[selectedTopLevelIndex] && menuItems[selectedTopLevelIndex].id
      ? menuItems[selectedTopLevelIndex].id
      : null;

  const getPuzzleId =
    menuItems[selectedTopLevelIndex].children && selectedPuzzleIndex !== null
      ? menuItems[selectedTopLevelIndex].children[selectedPuzzleIndex].content
          .id
      : null;

  const getTrainingQuestionId =
    menuItems[selectedTopLevelIndex].children &&
    selectedPuzzleIndex !== null &&
    selectedTrainingQuestionIndex
      ? menuItems[selectedTopLevelIndex].children[selectedPuzzleIndex].content
          .trainingQuestions[selectedTrainingQuestionIndex - 1].id
      : null;

  // TODO: Replace the placeholder components with the actual page components.
  useEffect(() => {
    if (selectedTopLevelIndex === 0) {
      setBodyComponent(<IntroductionTest />);
    } else if (selectedTopLevelIndex === 1) {
      setBodyComponent(<GameMission />);
    } else if (selectedTopLevelIndex === 5) {
      // setBodyComponent(<OutroTest />);
    } else if (
      (selectedTopLevelIndex === 2 ||
        selectedTopLevelIndex === 3 ||
        selectedTopLevelIndex === 4) &&
      selectedPuzzleIndex === null
    ) {
      // setBodyComponent(<RoomOverviewTest roomId={getRoomId} />);
    } else if (
      (selectedTopLevelIndex === 2 ||
        selectedTopLevelIndex === 3 ||
        selectedTopLevelIndex === 4) &&
      selectedPuzzleIndex !== null &&
      selectedTrainingQuestionIndex === null
    ) {
      // setBodyComponent(<PuzzleOverviewTest puzzleId={getPuzzleId} />);
    } else if (selectedTrainingQuestionIndex === 0) {
      // setBodyComponent(<CreateQuestionsTest puzzleId={getPuzzleId} />);
    } else if (
      selectedTrainingQuestionIndex &&
      selectedTrainingQuestionIndex > 0
    ) {
      // setBodyComponent(<EditQuestionsTest questionId={getTrainingQuestionId} />);
    }
  }, [
    selectedTopLevelIndex,
    selectedPuzzleIndex,
    selectedTrainingQuestionIndex,
  ]);

  return (
    <div className="ap-container">
      <div className="row mt-4">
        <div className="col-md-12">
          <GameTabs />
        </div>
      </div>
      <div className="row mt-2">
        <div
          className="col-md-4 mb-4"
          style={{ width: "548px", height: "518px" }}
        >
          <Panel style={{ width: "548px", height: "518px" }}>
            <h3>Basic information</h3>
            <div className={styles["info-container"]}>
              <div className={styles["info-item"]}>
                <div className={styles["info-column"]}>
                  <p className={styles["bi-header"]}>Created by</p>
                </div>
                <div className={styles["info-column"]}>
                  <p className={styles["bi-body"]}>
                    Oliver Smith on 11/03/24 at 5:00PM CET
                  </p>
                </div>
              </div>
              <div className={styles["info-item"]}>
                <div className={styles["info-column"]}>
                  <p className={styles["bi-header"]}>Last edited by</p>
                </div>
                <div className={styles["info-column"]}>
                  <p className={styles["bi-body"]}>
                    Oliver Smith on 11/03/24 at 5:00PM CET
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.switch}>
              <Switch
                defaultChecked={isIntroActive}
                onChange={() => {
                  setIsIntroActive((prevState) => {
                    return !prevState;
                  });
                }}
              >
                {"Publish Game"}
              </Switch>
            </div>
            <div style={{ width: "calc(100% - 10px)" }}>
              <Input
                type={"text"}
                title={"Name of Game"}
                onClick={() => {
                  console.log("you clicked the input");
                }}
                value={titleValue}
                style={{ width: "100%", paddingBottom: "10px" }}
                onChange={onTitleChange}
                error={questionTitleError}
                errorNode={
                  <div
                    id="errormessage"
                    aria-live="polite"
                    className="ap-field-email-validation-error"
                  >
                    Please enter a question title
                  </div>
                }
              ></Input>
              <Input
                type={"text"}
                title={"Industry"}
                style={{ width: "100%", paddingBottom: "10px" }}
                autosize={true}
                required={true}
                value={headerValue}
                onChange={onHeaderChange}
                error={questionHeaderError}
                errorNode={
                  <div
                    id="errormessage"
                    aria-live="polite"
                    className="ap-field-email-validation-error"
                  >
                    Please enter a question header
                  </div>
                }
              ></Input>
              <Input
                type={"text"}
                title={"Client"}
                style={{ width: "100%", paddingBottom: "10px" }}
                autosize={true}
                required={true}
                value={bodyValue}
                onChange={onBodyChange}
                error={questionBodyError}
                errorNode={
                  <div
                    id="errormessage"
                    aria-live="polite"
                    className="ap-field-email-validation-error"
                  >
                    Please enter a question body
                  </div>
                }
              ></Input>
              <div>
                <TextArea
                  style={{ width: "100%", height: "78px" }}
                  title={"Additional Details"}
                  maxLength={420}
                  value={additionalDetails}
                  onChange={onAdditionalDetailsChange}
                ></TextArea>
              </div>
            </div>
            <div className="mt-4" style={{ paddingTop: "10px" }}>
              <Button kind="text" icon="icon-delete-outline">
                Delete Game
              </Button>
            </div>
          </Panel>
        </div>
        <div className="col-md-8" style={{ width: "780px", height: "400px" }}>
          <Panel
            style={{ width: "780px", height: "400px", marginLeft: "32px" }}
          >
            <h3>Game administrators</h3>
            <p>Add new administrators for this game</p>
            <Input
              type={"text"}
              title={"Type e-mails"}
              style={{ width: "100%", paddingBottom: "10px" }}
              autosize={true}
              value={bodyValue}
              onChange={onBodyChange}
              error={questionBodyError}
              errorNode={
                <div
                  id="errormessage"
                  aria-live="polite"
                  className="ap-field-email-validation-error"
                >
                  Type e-mails
                </div>
              }
            ></Input>
            <Button kind="secondary" icon="icon-add-user-outline">
              Add administrator
            </Button>
            <h3>Administrators</h3>
            <div className="ap-table-demo">
              <Table originalData={originalData} hasTitle striped>
                <Column field="name">Name</Column>
                <Column field="email">Email</Column>
                <Column field="actions" renderCell={renderTotalCell}>
                  Actions
                </Column>
              </Table>
            </div>
          </Panel>
        </div>
      </div>
      <div className="row mt-0">
        <div
          className="col-md-4"
          style={{ width: "548px", height: "696.87px" }}
        >
          <Panel style={{ width: "548px", height: "696.87px" }}>
            <h3>Custom colours and logo (optional)</h3>
            <p style={{ paddingBottom: "20px" }}>Customise the colour scheme</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  backgroundColor: "#415385",
                  borderRadius: "4px",
                  border: "1px solid #D1D1D1",
                  marginRight: "10px",
                }}
              ></div>
              <span>Primary colour</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  backgroundColor: "#FFB600",
                  borderRadius: "4px",
                  border: "1px solid #D1D1D1",
                  marginRight: "10px",
                }}
              ></div>
              <span>Secondary colour</span>
            </div>
            <Button kind="secondary" icon="icon-art-themes-outline">
              Pick new colour scheme
            </Button>
            <div className={styles.divider}></div>
            <p>Upload a logo to integrate into the illustrations</p>
            <span>Format requirements - please only use:</span>
            <div style={{ width: "calc(100% - 10px)" }}>
              <Upload
                className="upload"
                onChange={onUploadChange}
                multiple={false}
                autoUpload={true}
                onUpload={uploadFiles}
                acceptFileType=".JPG,.PNG,.MP4"
                maxFileSize={10 * 1024 * 1024}
                config={{
                  trigger: false,
                  type: "inline",
                  size: true,
                }}
              ></Upload>
            </div>
            <div className={styles.divider}></div>
            <p>Preview</p>
            <div style={{ display: "flex" }}>
              <img
                src={svg1}
                alt="Room 1"
                style={{
                  height: "92.87px",
                  width: "164.7px",
                  marginRight: "10px",
                }}
              />
              <img
                src={svg2}
                alt="Room 2"
                style={{
                  height: "92.87px",
                  width: "164.7px",
                  marginRight: "10px",
                }}
              />
              <img
                src={svg3}
                alt="Room 3"
                style={{ height: "92.87px", width: "164.7px" }}
              />
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default GameSettings2;

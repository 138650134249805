import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import CloseButton from "../../ui/CloseButton";
import TVScreen from "../../ui/TVScreen";
import PreviewRoomObject from "../../RoomObject/PreviewRoomObject";
import GameRoomObject from "../../RoomObject/GameRoomObject";
import HintBar from "../../ui/HintBar";
import EventBridge from "@/utils/EventBridge";


export default class MemoGame extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly callback: () => void;
  
  private readonly textObject: Phaser.GameObjects.Text;
  private readonly answerIconCorrect: Phaser.GameObjects.Image;
  private readonly answerIconWrong: Phaser.GameObjects.Image;
  private readonly closeButton: CloseButton;
  private readonly hintBar: HintBar;
  private readonly timerEvents: Phaser.Time.TimerEvent[] = [];

  private readonly CARDS_PADDING: number = 30;
  private readonly HINT_MESSAGE = "Might want to Google it!";
  
  private cards: Array<Phaser.GameObjects.Plane> = [];
  private gameCompleted: boolean = false;
  private canMove: boolean = false;
  private cardOpened?: Phaser.GameObjects.Plane = undefined;

  private readonly currencyCountryPairs = [
    { currency: 'baht', country: 'thailand' },
    { currency: 'lira', country: 'turkey' },
    { currency: 'naira', country: 'nigeria' },
    { currency: 'peso', country: 'mexico' },
  ];
  
  private readonly gridConfiguration = {
    x: 0,
    y: 0,
    paddingX: 20,
    paddingY: 20
  }
  
  constructor(
    scene: UIScene,
    text: string,
    sourceObject: BaseRoomObject,
    callback: () => void = () => {}
  ) {
    super(scene, 0, 0);
    this.scene = scene;
    this.sourceObject = sourceObject;
    this.callback = callback;

    const tvScreen = new TVScreen(this.scene);
    this.add(tvScreen);

    this.hintBar = new HintBar(scene, this.HINT_MESSAGE);
    this.add(this.hintBar);

    this.closeButton = new CloseButton(this.scene, () => this.closeModal());
    this.add(this.closeButton);
    
    this.textObject = new Phaser.GameObjects.Text(
      scene, 
      scene.scale.width / 2, 
      150, 
      "Match the currency to the country", 
      {
        fontFamily: "Arial",
        fontSize: "26px",
        color: "#ffffff",
        align: "center",
      })
      .setLineSpacing(8)
      .setOrigin(0.5, 0.5);

    this.add(this.textObject);

    this.answerIconCorrect = new Phaser.GameObjects.Image(
      this.scene, 
      this.textObject.x + this.textObject.width / 2 + this.CARDS_PADDING,
      this.textObject.y,
      "answer-correct");
    this.answerIconCorrect.visible = false;
    this.add(this.answerIconCorrect);

    this.answerIconWrong = new Phaser.GameObjects.Image(
      this.scene, 
      this.textObject.x + this.textObject.width / 2 + this.CARDS_PADDING,
      this.textObject.y,
      "answer-wrong");
    this.answerIconWrong.visible = false;
    this.add(this.answerIconWrong);

    const cardWidth = 128;
    const cardHeight = 201;

    this.gridConfiguration.x = this.scene.scale.width / 2 - (2 * (cardWidth + this.gridConfiguration.paddingX)) + cardWidth / 2  ;
    this.gridConfiguration.y = this.scene.scale.height / 2 - cardHeight / 2;

    const frames = scene.textures.get('currenciesAtlas').getFrameNames();
    const randomFrames = this.getRandomFrames(frames);
    
    for (let i = 0; i < 8; i++)
    {
      const x = this.gridConfiguration.x + (cardWidth + this.gridConfiguration.paddingX) * (i % 4);
      const y = this.gridConfiguration.y + (cardHeight + this.gridConfiguration.paddingY) * Math.floor(i / 4);

      const frameName = randomFrames[i + 1];
      const isCurrency = frameName.indexOf("currency") !== -1;
      let data = frameName.replace("Card-", "").replace(".svg", "");
      data = isCurrency ? data.replace("currency-", "") : data.replace("flag-", "");
      const countryName = isCurrency ? this.currencyCountryPairs.find(pair => pair.currency == data)?.country : data;

      const card = new Phaser.GameObjects.Plane(scene, x, y, "currenciesAtlas")
        .setName(frameName)
        .setData("countryName", countryName)
        .setInteractive();

      this.add(card);  
      this.cards.push(card);
    }

    this.scene.add.existing(this);
    this.canMove = true;

    this.scene.input.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);
  }
  
  getRandomFrames(frames: string[]) {
    const groupSize = 4;
    const firstItem = frames[0];
    const currencies = Phaser.Utils.Array.Shuffle(frames.slice(1, groupSize + 1));
    const flags = Phaser.Utils.Array.Shuffle(frames.slice(groupSize + 1));
    const result = [ firstItem, ...currencies, ...flags];

    return result;
  }

  flipCard(card: Phaser.GameObjects.Plane, callbackComplete: () => void) {
    const before =  card.rotateY;

    const rotation = {y: 0};
    if (before === 360) {
      rotation.y = 180;
    }
    
    let isFlipping = false;
    
    this.scene.add.tween({
      targets: [rotation],
      y: (rotation.y === 180) ? 0 : 180,
      ease: Phaser.Math.Easing.Expo.Out,
      duration: 500,
      
      onStart: () => {
        isFlipping = true;
        this.scene.tweens.chain({
            targets: card,
            ease: Phaser.Math.Easing.Expo.InOut,
            tweens: [
                {
                    duration: 200,
                    scale: 1.1,
                },
                {
                    duration: 300,
                    scale: 1
                },
            ]
        })
      },

      onUpdate: () => {
        card.modelRotation.y = Phaser.Math.DegToRad(180) + Phaser.Math.DegToRad(rotation.y);
        card.rotateY = 180 + rotation.y;
        const cardRotation = Math.floor(card.rotateY) % 360;
        
        if ((cardRotation >= 0 && cardRotation <= 90) || (cardRotation >= 270 && cardRotation <= 359)) {
          card.setTexture(card.name);
        }
        else {
            card.setTexture("currenciesAtlas");
        }
      },

      onComplete: () => {
        isFlipping = false;
        
        if (callbackComplete) {
            callbackComplete();
        }
      }
    });  
  }

  onPointerDown = (pointer: Phaser.Input.Pointer) => {
    if (this.canMove && this.cards.length) {
      const card = this.cards.find(card => card.hasFaceAt(pointer.x, pointer.y));

      if (card) {
        this.canMove = false;

        // Detect if there is a card opened
        if (this.cardOpened !== undefined) {
          // If the card is the same that the opened not do anything
          if (this.cardOpened.x === card.x && this.cardOpened.y === card.y) {
            this.canMove = true;
            return false;
          }

          this.flipCard(card, () => {      
            if (this.cardOpened?.getData("countryName") === card.getData("countryName")) {
              this.displayAsnwerDetails(true);

              this.cards = this.cards.filter(cardLocal => cardLocal.getData("countryName") !== card.getData("countryName"));
              this.cardOpened = undefined;
              this.canMove = true;

              if (this.cards.length === 0) {
                this.answerIconCorrect.visible = false;
                this.textObject.setText("Nice work!");
                this.canMove = false;
                this.gameCompleted = true;
                this.complete();
              }
            } 
            else {
              this.displayAsnwerDetails(false);
                  
              this.flipCard(card, () => {});
              this.flipCard(card, () => {});

              this.flipCard(this.cardOpened!, () => {
                this.cardOpened = undefined;
                this.canMove = true;
              });     
            }
          });
        } else if (this.cardOpened === undefined && this.cards.length > 0) {  
          this.flipCard(card, () => {
              this.canMove = true;
          });

          this.cardOpened = card;
        }
        else {
          console.log('Should not be here');
        }
      }
    }
  }

  displayAsnwerDetails(correctAnswer: boolean) {
    if (correctAnswer) {
      this.answerIconWrong.visible = false;      
      this.textObject.setText("Correct!");
      this.answerIconCorrect.setX(this.textObject.x + this.textObject.width / 2 + this.CARDS_PADDING);
      this.answerIconCorrect.visible = true;
    } else {
      this.answerIconCorrect.visible = false;
      this.textObject.setText("Wrong");  
      this.answerIconWrong.setX(this.textObject.x + this.textObject.width / 2 + this.CARDS_PADDING);
      this.answerIconWrong.visible = true;
    }
  }

  private complete() {
    this.scene.hud.setHintButtonVisible(false);
    const timerEvent = this.scene.time.delayedCall(2000, this.completeAction);
    this.timerEvents.push(timerEvent);
  }

  private completeAction = () => {
    if (this.scene.env === "preview") {
      (this.sourceObject as PreviewRoomObject).incrementProgress();
      (this.sourceObject as PreviewRoomObject).completeAction();
    } else {
      //(this.sourceObject as GameRoomObject).incrementProgress();
      EventBridge.emit("game.puzzleEnded");
      (this.sourceObject as GameRoomObject).completeAction(this.scene.sourceScene.user.id!);
    }

    this.closeModal(false);
  }

  private closeModal(hideHintButton: boolean = true) {
    if (hideHintButton) {
      this.scene.hud.setHintButtonVisible(false);
    }

    if (this.canMove || this.gameCompleted) {
      this.scene.time.removeEvent(this.timerEvents);
      this.cards.forEach(card => card.destroy());
      this.scene.input.off(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);
      this.scene.makeGameSceneInteractive();
      this.destroy();
    }
  }
}

import UIScene from "@/phaser/scenes/UIScene";
import PopupBackground from "./PopupBackground";

export default class TVScreen extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly callback: () => void;
  private readonly popupBackground: PopupBackground; 

  constructor(
    scene: UIScene,
    callback: () => void = () => {}
  ) {
    super(scene, 0, 0);
    this.scene = scene;
    this.callback = callback;
    
    this.popupBackground = new PopupBackground(scene);
    this.add(this.popupBackground);

    const tvFrame = new Phaser.GameObjects.Graphics(scene);

    const TV_WIDTH = 1000;
    const TV_HEIGHT = 650;
    const X = (this.scene.scale.width - TV_WIDTH) / 2;
    const Y = 105;
    const BOTTOM_BAR_HEIGHT = 55;
    const RADIUS = 11;

    tvFrame.fillStyle(0x000000, 1);
    tvFrame.fillRoundedRect(X, Y, TV_WIDTH, TV_HEIGHT, RADIUS);

    tvFrame.lineStyle(1, 0xffffff);
    tvFrame.strokeRoundedRect(X - 1, Y - 1, TV_WIDTH + 2, TV_HEIGHT + 2, RADIUS);

    tvFrame.fillStyle(0xffffff, 1);
    tvFrame.fillRoundedRect(X, Y + TV_HEIGHT - BOTTOM_BAR_HEIGHT - 1, TV_WIDTH + 1, BOTTOM_BAR_HEIGHT + 2, { tl: 0, tr: 0, bl: RADIUS, br: RADIUS });

    this.add(tvFrame);      
  }
}
import { Input, Select } from "@appkit4/react-components";
import { IAction, IRoomObject, IStateBasedPayload } from "escape-rooms-types/types/game";
import { imagePopupChoices } from "@/constants";
import { SelectValue } from "@appkit4/react-components/esm/combobox/Combobox";
import styles from "../ObjectConfigAction.module.scss";

interface IActionDisplayImageByStateProps {
  action: IAction;
  selected: IRoomObject;
  updateActionProperties: (payload: Partial<IAction>) => void;
  stateName: string;
}

const ActionDisplayImageByState = ({
  action,
  selected,
  updateActionProperties
}: IActionDisplayImageByStateProps) => {
  
  const assetStateNames = Object.values(selected.asset.states || {}).map(state => state.name);

  return (
    <>
      { assetStateNames.map((stateName, index) => (
        <ActionDisplayImageStateDetails
          key={stateName + '_' + index} 
          action={action}
          selected={selected}
          updateActionProperties={updateActionProperties}
          stateName={stateName} 
        />
      ))}
    </>
  );
};


const ActionDisplayImageStateDetails = ({
  action,
  selected,
  updateActionProperties,
  stateName
}: IActionDisplayImageByStateProps, 
) => {
  
  const updateModalPayload = (payload: IStateBasedPayload) => {
    let updatedStateBasedPayload = action.stateBasedPayload === undefined ? [] : [...action.stateBasedPayload];

    if (updatedStateBasedPayload.find(item => item.state === payload.state)) {
      updatedStateBasedPayload = action.stateBasedPayload.map((item: IStateBasedPayload) => {
        if (item.state === payload.state) {
          return {
            ...item, 
            title: payload.title !== undefined ? payload.title : item.title,
            message: payload.message !== undefined ? payload.message : item.message,
          };
        }

        return item;
      });
    } else {
      updatedStateBasedPayload.push(
        {
          state: payload.state,
          title: payload.title || "",
          message: payload.message || "",
        }
      );
    }
    
    updateActionProperties( {
        stateBasedPayload: updatedStateBasedPayload
      }
    );
  };

  return (
    <>
      <div className="ap-typography-5 ap-font-weight-2 ap-my-spacing-6" key={`state_${stateName}`}>
            State: {stateName}
      </div>
      <div className={styles.divider}></div>
      <div className="col-4" key={`image_${stateName}`}>
        <span className="input-label">Image</span>
        <Select 
          placeholder=""
          data={
            imagePopupChoices.map(imageName => ({
              label: imageName,
              value: imageName,
            }))}
          value={action?.stateBasedPayload?.find(s => s.state == stateName)?.title || ""}
          
          onSelect={(title: SelectValue) => {
            updateModalPayload({
                action: action,
                state: stateName,
                title: title,
              });
          }}
        />
      </div>
      <div className="col-8" key={`hint_${stateName}`}>
        <span className="input-label">Hint message</span>
        <Input 
          maxLength={500}
          value={action?.stateBasedPayload?.find(s => s.state == stateName)?.message || ""}
          onChange={(message: string) => {
            updateModalPayload({
                action: action,
                state: stateName,
                message: message,
              }
            );
          }}
        />
      </div>      
    </>
  );
};

export default ActionDisplayImageByState;

import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../RoomObject/BaseRoomObject";
import CloseButton from "../ui/CloseButton";
import PopupBackground from "../ui/PopupBackground";
import HintBar from "../ui/HintBar";
import { SCREEN_CENTER_X, SCREEN_CENTER_Y } from "@/constants";


export default class ImagePopup extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly image: Phaser.GameObjects.Image;
  private readonly callback: () => void;
  private readonly popupBackground: PopupBackground;
  private readonly hintBar: HintBar;

  constructor(
    scene: UIScene,
    imageType: string,
    hintMessage: string,
    sourceObject: BaseRoomObject,
    callback: () => void = () => {}
  ) {
    super(scene, 0, 0);

    this.scene = scene;
    this.sourceObject = sourceObject;
    this.callback = callback;
    this.scene.hud.setHintButtonVisible(true); 

    this.popupBackground = new PopupBackground(scene);
    this.add(this.popupBackground);

    const containerXinGlobalSpace = SCREEN_CENTER_X;
    const containerYinGlobalSpace = SCREEN_CENTER_Y;

    this.image = new Phaser.GameObjects.Image(
      scene,
      containerXinGlobalSpace,
      containerYinGlobalSpace,
      imageType
    );

    this.add(this.image);

    this.hintBar = new HintBar(scene, hintMessage);
    this.add(this.hintBar);

    const closeButton = new CloseButton(this.scene, () => this.closeModal());
    this.add(closeButton);

    scene.add.existing(this);    
  }

  closeModal() {
    this.scene.hud.setHintButtonVisible(false); 
    this.scene.makeGameSceneInteractive();
    this.destroy();
  }
}

import UIScene from "@/phaser/scenes/UIScene";
import OnboardingMainScreen from "./OnboardingMainScreen";
import OnboardingScreenBase from "./OnboardingScreenBase";


export default class GameInstructions extends OnboardingScreenBase {
  private readonly marginLeft: number;
  private readonly continueButtonText: Phaser.GameObjects.Text;
  private readonly continueButtonBackground: Phaser.GameObjects.Graphics;
  private readonly contentForm: Phaser.GameObjects.DOMElement;
  private readonly headerForm: Phaser.GameObjects.DOMElement;

  constructor(
    scene: UIScene,
    marginLeft: number,
    callback: () => void = () => {}
  ) {
    super(scene, callback);

    this.marginLeft = marginLeft;

    this.headerForm = new Phaser.GameObjects.DOMElement(this.scene, this.marginLeft, 132, "div", OnboardingMainScreen.STYLE_H1, "A few instructions before we start playing!").setOrigin(0);
    this.add(this.headerForm);

    this.contentForm = new Phaser.GameObjects.DOMElement(this.scene, this.marginLeft, 250).setOrigin(0).setVisible(false);
    this.add(this.contentForm);

    const paddingX = 24;
    const paddingY = 8;

    this.continueButtonText = new Phaser.GameObjects.Text(this.scene, this.marginLeft + paddingX, 830, "Understood", {
      font: "500 16px Arial",
        color: "#000000",
        align: "center",
    }).setOrigin(0);
    
    this.continueButtonBackground = new Phaser.GameObjects.Graphics(this.scene);
    this.continueButtonBackground.fillStyle(0xEBEBEB, 1);
    this.continueButtonBackground.fillRoundedRect(
      this.continueButtonText.getTopLeft().x! - paddingX,
      this.continueButtonText.getTopLeft().y! - paddingY,
      this.continueButtonText.width + 2 * paddingX,
      this.continueButtonText.height + 2 * paddingY,
      5 
    );
    this.add(this.continueButtonBackground);
    this.add(this.continueButtonText);

    this.continueButtonText.setInteractive().on(Phaser.Input.Events.POINTER_DOWN, this.onContinue);
    this.continueButtonBackground.setInteractive(
      new Phaser.Geom.Rectangle(
        this.continueButtonText.getTopLeft().x! - paddingX, 
        this.continueButtonText.getTopLeft().y! - paddingY, 
        this.continueButtonText.width + 2 * paddingX,this.continueButtonText.height + 2 * paddingY), Phaser.Geom.Rectangle.Contains
    ).on(Phaser.Input.Events.POINTER_DOWN, this.onContinue);
  }

  public activate(): void {
    this.contentForm.createFromCache('game-instructions').setVisible(true);
  }

  private onContinue = () => {
    this.onCloseCallback();
  }
}

import { useGameStore } from "@/store/game-store";
import { DropdownButton, Tab, Tabs } from "@appkit4/react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const GameTabs = () => {
  const navigate = useNavigate();
  const status = useGameStore((state) => state.game.status);
  const rooms = useGameStore((state) => state.game.rooms);

  const getPage = () => {
    if (location.pathname.includes("edit")) {
      return 0;
    } else if (location.pathname.includes("settings")) {
      return 1;
    } else if (location.pathname.includes("sessions")) {
      return 2;
    }
  };

  const [selectedTabIndex] = useState(getPage());

  const gameId = location.pathname.split("/").at(-2);

  const onTabChange = (i: number) => {
    if (i === 0) {
      return navigate(`/games/${gameId}/edit`);
    } else if (i === 1) {
      return navigate(`/games/${gameId}/settings`);
    } else if (i === 2 && status === "published") {
      return navigate(`/games/${gameId}/sessions`);
    }
  };

  const dropdownItems = rooms.map((room) => {
    return { label: room.name, value: room };
  });

  const handleFullPreview = () => {
    console.log("Preview full game");
  };

  const handleRoomPreview = (item) => {
    console.log("Preview room game: ", item);
    window.open(`/games/${gameId}/rooms/${item._id}/preview`, '_blank');
  };

  return (
    <div className="row ap-my-spacing-5">
      <Tabs
        className="w-auto"
        activeIndex={selectedTabIndex}
        onTabChange={onTabChange}
      >
        <Tab label="Game overview" />
        <Tab label="Game settings" />
        <Tab disabled={status !== "published"} label="Sessions" />
      </Tabs>
      <DropdownButton
        data={dropdownItems}
        splitButton
        kind="secondary"
        className="ml-auto w-auto"
        onClick={() => handleFullPreview()}
        onSelect={(item) => handleRoomPreview(item)}
      >
        Preview game
      </DropdownButton>
    </div>
  );
};

export default GameTabs
;

import UIScene from "@/phaser/scenes/UIScene";
import { SCREEN_HEIGHT, SCREEN_WIDTH } from "@/constants";
import OnboardingScreenBase from "./OnboardingScreenBase";


export default class CountdownScreen extends OnboardingScreenBase {
  private readonly text: Phaser.GameObjects.Text;
  

  constructor(
    scene: UIScene,
    callback: () => void
  ) {
    super(scene, callback);

    this.text = new Phaser.GameObjects.Text(
      this.scene,
      SCREEN_WIDTH / 2,
      SCREEN_HEIGHT / 2,
      "START",
      {
        fontFamily: "Arial",
        fontSize: "220px",
        color: "#ffffff",
        align: "center",
      }).setOrigin(0.5);
    this.add(this.text);
  }

  public activate(): void {
    const countdownTexts = ["3", "2", "1", "START"];
    this.text.setText(countdownTexts[0]);
    
    let countdownIndex = 0;

    const timerEvent = this.scene.time.addEvent({
      delay: 1000,
      repeat: countdownTexts.length,
      callback: () => {
        countdownIndex++;
        if (countdownIndex < countdownTexts.length) {
          this.text.setText(countdownTexts[countdownIndex]);
        }
        else {
          this.scene.time.removeEvent(timerEvent);
          this.onCloseCallback();
        }
      }
    });
  }
}

import { useGameStore } from "@/store/game-store";
import {
  Breadcrumb as AppkitBreadcrumb,
  BreadcrumbItem,
} from "@appkit4/react-components";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { NavLink, useLocation, useParams } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();
  const params = useParams();
  const game = useGameStore((state) => state.game);
  const currentRoomIndex = useGameStore((state) => state.currentRoomIndex);

  const intl = useIntl();

  useEffect(() => {
    if (game == null) {
      return;
    }
    if (game.rooms == null) {
      return;
    }
    if (currentRoomIndex != null) {
      return;
    }
  }, [currentRoomIndex]);

  const generateItems = () => {
    const items: Array<JSX.Element> = [];
    const routes = location.pathname.split("/");
    if (routes[1] === "games") {
      items.push(
        <BreadcrumbItem>
          <NavLink to={`/`}>
            <span>{intl.formatMessage({ id: "location.games.list" })}</span>
          </NavLink>
        </BreadcrumbItem>
      );

      if (routes[2]) {
        items.push(
          <BreadcrumbItem>
            <NavLink to={`/games/${params.gameId}`}>
              <span>{game.name}</span>
            </NavLink>
          </BreadcrumbItem>
        );

        if (routes[3] === "rooms") {
          if (routes[4]) {
            items.push(
              <BreadcrumbItem>
                <NavLink to={`/games/${params.gameId}/rooms/${params.roomId}`}>
                  <span>{game.rooms[currentRoomIndex]?.name ?? "ROOM"}</span>
                </NavLink>
              </BreadcrumbItem>
            );
          }
        }
      }
    }
    if (routes[1] === "sessions") {
      if (routes[2]) {
        items.push(
          <BreadcrumbItem>
            <NavLink to={`/sessions`}>
            <span>
              {intl.formatMessage({ id: "location.session.manager" })}
            </span>
            </NavLink>
          </BreadcrumbItem>
        );
        items.push(
          <BreadcrumbItem>
            <NavLink to={`/sessions/${params.sessionId}`}>
              <span>
                {intl.formatMessage({ id: "location.session.settings" })}
              </span>
            </NavLink>
          </BreadcrumbItem>
        );
      }
    }

    return items;
  };

  return (
    <AppkitBreadcrumb className="ap-py-spacing-4">
      {generateItems()}
    </AppkitBreadcrumb>
  );
};

export default Breadcrumb;

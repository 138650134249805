import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import CloseButton from "../../ui/CloseButton";
import PopupBackground from "../../ui/PopupBackground";
import HintBar from "../../ui/HintBar";
import { SCREEN_CENTER_X, SCREEN_CENTER_Y } from "@/constants";
import PreviewRoomObject from "../../RoomObject/PreviewRoomObject";
import GameRoomObject from "../../RoomObject/GameRoomObject";
import EventBridge from "@/utils/EventBridge";

export default class Keyhooks extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly callback: () => void;
  private readonly popupBackground: PopupBackground;
  private readonly closeButton: CloseButton;
  private readonly hintBar: HintBar;
  private readonly timerEvents: Phaser.Time.TimerEvent[] = [];
  private readonly images: Phaser.GameObjects.Image[] = [];
  private readonly CORRECT_KEY_NUMBER = 5;
  private readonly penaltyPoints = 50;
  
  private readonly INITIAL_MESSAGE: string = "Be careful what you choose... points could be lost";
  private readonly COMPLETE_MESSAGE: string = "Key added to inventory";
  public static readonly INVENTORY_ITEM_NAME = "Fs3 Key"

  constructor(
    scene: UIScene,
    text: string,
    sourceObject: BaseRoomObject,
    callback: () => void = () => {}
  ) {
    super(scene, 0, 0);

    this.scene = scene;
    this.sourceObject = sourceObject;
    this.callback = callback;

    this.popupBackground = new PopupBackground(scene);
    this.add(this.popupBackground);

    this.hintBar = new HintBar(scene, this.INITIAL_MESSAGE);
    this.add(this.hintBar);

    this.closeButton = new CloseButton(this.scene, () => this.closeModal());
    this.add(this.closeButton);

    const image = new Phaser.GameObjects.Image(this.scene, SCREEN_CENTER_X, SCREEN_CENTER_Y, "keyhooks");
    this.add(image);

    this.loadKeys();

    this.scene.add.existing(this);
    this.scene.input.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);

    this.hintBar.displayMessage(this.INITIAL_MESSAGE);
  }
  
  private onPointerDown = (pointer: Phaser.Input.Pointer) => {
    const clickedImage = this.images.find(img => img.getBounds().contains(pointer.x, pointer.y));
    
    if (clickedImage) {
      const clickedImageNumber = parseInt(clickedImage.name);

      if (clickedImageNumber == this.CORRECT_KEY_NUMBER) {
        this.hintBar.displayMessage(this.COMPLETE_MESSAGE);
        clickedImage.setVisible(false);
        this.scene.input.off(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);

        if (this.scene.env === "preview") {
          (this.sourceObject as PreviewRoomObject).incrementProgress();
          (this.sourceObject as PreviewRoomObject).completeAction();
        } else {
          EventBridge.emit("game.puzzleEnded");
          //(this.sourceObject as GameRoomObject).incrementProgress();
          (this.sourceObject as GameRoomObject).completeAction(this.scene.sourceScene.user.id!);
        }

        this.addKeyItemToInventory();
      }
      else {
        const topCenter = clickedImage.getTopCenter();
        this.displayTooltip(topCenter.x!, topCenter.y!);
      }
    }
  }

  private loadKeys() {
    const numberOfKeys = 9;

    for (let i = 0; i < numberOfKeys; i++) {
      const key = new Phaser.GameObjects.Image(this.scene, 0, 0, "key");
      key.name = i.toString();
      this.add(key);
      this.images.push(key);
    }

    Phaser.Actions.GridAlign(this.images, {
      width: 3,
      height: 3,
      cellWidth: 298,
      cellHeight: 213,
      x: 310,
      y: 212,
    });
  }

  private displayTooltip(x: number, y: number) {
    const messageText = new Phaser.GameObjects.Text(this.scene, x + 80, y - 40, "Ouch, you got zapped!", {
      fontFamily: "Roboto-Regular",
      fontSize: "20px",
      color: "#000000",
      align: "center",
    }).setOrigin(0.5);
    
    const MESSAGE_BACKGROUND_PADDING = 20;
    const POINTS_BACKGROUND_PADDING = 10;
    const messageBackgroundWidth =  messageText.width + 2 * MESSAGE_BACKGROUND_PADDING;
    const messageBackgroundHeight = messageText.height + 2 * MESSAGE_BACKGROUND_PADDING;

    const messageBackground = new Phaser.GameObjects.Graphics(this.scene);
    messageBackground.fillStyle(0xD3C9B0, 1);
    messageBackground.fillRoundedRect(
      messageText.getTopLeft().x! - MESSAGE_BACKGROUND_PADDING, 
      messageText.getTopLeft().y! - MESSAGE_BACKGROUND_PADDING, 
      messageBackgroundWidth, 
      messageBackgroundHeight, 
      15);

    this.add(messageBackground);
    this.add(messageText);

    const pointsText = new Phaser.GameObjects.Text(this.scene, messageText.getTopCenter().x!, messageText.getTopCenter().y! - MESSAGE_BACKGROUND_PADDING, `-${this.penaltyPoints} points`, {
      fontFamily: "Roboto-Regular",
      fontSize: "12px",
      color: "#ffffff",
      align: "center",
    }).setOrigin(0.5);;

    const pointsBackgroundWidth =  pointsText.width + 2 * POINTS_BACKGROUND_PADDING;
    const pointsBackgroundHeight = pointsText.height + 2 * POINTS_BACKGROUND_PADDING;

    const pointsBackground = new Phaser.GameObjects.Graphics(this.scene);
    pointsBackground.fillStyle(0xC52A1A, 1);
    pointsBackground.fillRoundedRect(
      pointsText.getTopLeft().x! - POINTS_BACKGROUND_PADDING, 
      pointsText.getTopLeft().y! - POINTS_BACKGROUND_PADDING, 
      pointsBackgroundWidth, 
      pointsBackgroundHeight, 
      15);

    this.add(pointsBackground);
    this.add(pointsText);

    this.scene.time.delayedCall(2000, () => {
      messageText.destroy();
      messageBackground.destroy();
      pointsText.destroy();
      pointsBackground.destroy();
    });
  }

  private addKeyItemToInventory() {
    const roomObjects = this.scene.sourceScene.roomObjects;
    
    Object.keys(roomObjects).forEach(key => {
      const roomObject = roomObjects[key];

      if (roomObject.gameObject.name === Keyhooks.INVENTORY_ITEM_NAME) {
        roomObject.setVisible(true);
        EventBridge.emit("ui.addItemToInventory", roomObject);
      }
    });
  }

  private closeModal(hideHintButton: boolean = true) {
    if (hideHintButton) {
      this.scene.hud.setHintButtonVisible(false);
    }

    this.scene.input.off(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);
    this.scene.time.removeEvent(this.timerEvents);
    this.scene.makeGameSceneInteractive();
    this.destroy();
  }
}

import {
  Button,
  CalendarPicker,
  Column,
  Input,
  Panel,
  Select,
  Table,
  TextArea,
  TimePicker,
} from "@appkit4/react-components";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./CreateGameSessionForm.module.scss";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IGameSession } from "escape-rooms-types/types/gameSession";
import { createGameSession } from "@/api/session";
import { getGames } from "@/api/game";
import { IGame } from "escape-rooms-types/types/game";
import { useGameStore } from "@/store/game-store";
import {
  inviteUserToSession,
} from "@/api/auth";
import { renderNotification } from "../notification/renderFunctions";
import { addToClipboard, getTimezones, getUserTimezone } from "@/utils/helpers";

const CreateGameSessionForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { gameId } = useParams();
  const { session } = useGameStore();
  const [games, setGames] = useState<Array<IGame>>([]);
  const [inviteEmail, setInviteEmail] = useState<string>("");
  const [inviteEmailError, setInviteEmailError] = useState<string | null>(null);
  const [inviteTableData, setInviteTableData] = useState<Array<any>>([]);
  const [sessionNameError, setSessionNameError] = useState<string | undefined>(undefined);
  const [gameIdError, setGameIdError] = useState<string | undefined>(undefined);

  const initialFormValues = {
    sessionName: "",
    selectedGameId: gameId || "",
    description: "",
    callLink: "",
    status: "pending",
    players: [],
    createdAt: null,
    updatedAt: null,
    plannedDate: null,
    plannedTime: null,
    timeZone: getUserTimezone()
  };

  const [formData, setFormData] = useState<IGameSession>(initialFormValues);

  const renderInput = (
    title: string,
    onChange: (e) => void,
    value: string,
    error: string | undefined,
    disabled: boolean = false,
    maxLength: number | undefined = undefined
  ) => {
    return (
      <Input
        type={"text"}
        title={title}
        required={true}
        value={value.trim()}
        maxLength={maxLength}
        onChange={onChange}
        disabled={disabled}
        error={error}
        onKeyDown={(event: any) => { if (event.code === 'Space') event.preventDefault()}}
        errorNode={
          <div
            id="errormessage"
            aria-live="polite"
            className={`ap-field-email-validation-error ${styles.error}`}
          >
            {error}
          </div>
        }
      ></Input>
    );
  };

  const renderSelect = (
    data: { value: string, label: string }[],
    value: string | undefined,
    onSelect: (e) => void,
    error: string | undefined,
    placeholder: string = "Game",
    noResultFound: string = "No published games found"
  ) => {
    return (
      <Select
        data={data}
        required={true}
        value={value}
        onSelect={onSelect}
        searchable
        placeholder={placeholder}
        noResultFound={noResultFound}
        error={error}
      ></Select>
    );
  };

  const getGamesQuery = useQuery({
    queryKey: ["getGames"],
    queryFn: () => getGames(undefined, undefined, undefined, undefined, "", "", "", ""),
    onSuccess: (res) => {
      setGames(res.data.data);
    },
  });

  const gamesData = useMemo(() => {
    const publishedGames = games.filter((game) => game.status === "published");
    return publishedGames.map((game) => ({ value: game._id, label: game.name }))
  }, [games]);

  const handleFormChange = (name: string, value: any, isValid: boolean = true) => {
    if (isValid) setFormData({ ...formData, [name]: value });
  }

  const checkIfChanged = useCallback(() => {
    return !(
      formData.sessionName === initialFormValues.sessionName &&
      formData.selectedGameId === initialFormValues.selectedGameId &&
      formData.description === initialFormValues.description &&
      formData.callLink === initialFormValues.callLink &&
      formData.status === initialFormValues.status &&
      formData.players === initialFormValues.players &&
      formData.createdAt === initialFormValues.createdAt &&
      formData.updatedAt === initialFormValues.updatedAt &&
      formData.plannedDate === initialFormValues.plannedDate &&
      formData.plannedTime === initialFormValues.plannedTime &&
      formData.timeZone === initialFormValues.timeZone
    )
  }, [formData]);

  const createGameSessionQuery = useQuery({
    queryKey: ["createGameSession"],
    queryFn: () => createGameSession({
      name: formData.sessionName,
      gameId: formData.selectedGameId,
      description: formData.description,
      callLink: formData.callLink,
      status: formData.status,
      players: formData.players.map((player) => {
        player.status = "invited";
        return player;
      }),
      createdAt: new Date(),
      updatedAt: new Date(),
      plannedDate: formData.plannedDate,
      plannedTime: formData.plannedTime,
      timeZone: formData.timeZone,
      googleMeetLink: formData.googleMeetLink
    }),
    onSuccess: (res) => {
      // For each added player, send an invite
      formData.players.forEach((player) => {
        return inviteUserToGameSession.mutate({
          sessionId: res.data.data._id,
          email: player.email
        });
      });

      openGameSession(res.data.data._id);
    },
    enabled: false,
  });

  const inviteUserToGameSession = useMutation({
    mutationKey: "inviteUserToSession",
    mutationFn: (playerAddDetails: any) => inviteUserToSession(playerAddDetails.sessionId!, playerAddDetails.email),
    onSuccess: (res) => {
      queryClient.invalidateQueries("getGameSession");
      renderNotification("Player invited to session.", "success");
      setInviteEmail("");
    },
    onError: (err) => {
      // setInviteEmailError(err.response.data.message);
      // renderNotification(err.message, "error");
    },
  });

  const addAllEmailsToClipboard = () => {
    const emails = formData.players.map((player) => player.email);
    addToClipboard(emails?.join("\n"), () =>
      renderNotification("Added to clipboard", "success")
    );
  };

  useEffect(() => {
    if (session == null) return;
    if (session.players == null) return;
    setInviteTableData(session?.players.map((player) => ({ ...player })));
  }, [session?.players]);

  const renderActions = (row: any, field: string) => {
    return (
      <div className="flex gap-1 justify-center">
        <i
          className="Appkit4-icon icon-delete-outline btn"
          onClick={() => deleteUserFromFormData(row.email)}
        ></i>
      </div>
    );
  };

  const renderStatus = (row: any, field: string) => {
    const statusString =
      row["status"].charAt(0).toUpperCase() + row["status"].slice(1);
    return (
      <div className="flex align-center gap-1">
        <div
          className={`circle ${
            row.status.toLowerCase() === "pending"
              ? "ap-bg-color-background-warning"
              : "ap-bg-color-background-success"
          }`}
        ></div>
        <span>{statusString}</span>
      </div>
    );
  };

  const openGameSession = (sessionId: string) => {
    return navigate(`/sessions/${sessionId}`);
  };

  const addGameSessionQuery = useQuery({
    queryKey: ["createGameSessions"],
    queryFn: () => createGameSession({ gameId: gameId! }),
    onSuccess: (res) => {
      openGameSession(res.data.data._id);
    },
    enabled: false,
  });

  const handleAddPlayer = (email: string) => {
    setFormData((prevState: IGameSession) => ({
      ...prevState,
      players: [...prevState.players,
                {
                  name: null,
                  email,
                  status: "pending"
                }
              ]
    }))

    setInviteEmail("");
  }

  const handleSave = () => {
    if (formData.sessionName.length < 1 || formData.selectedGameId.length < 1) {
      if (formData.sessionName.length < 1) {
        setSessionNameError("Please enter a session name");
      }
      if (formData.selectedGameId.length < 1) {
        setGameIdError("Please make a selection");
      }
      return;
    }
    createGameSessionQuery.refetch();
  }

  const deleteUserFromFormData = (email: string) => {
    const newPlayers = formData.players.filter((player) => player.email !== email);
    setFormData({ ...formData, players: newPlayers });
  }

  return (
    <div className="ap-container">
      <div className="ap-my-spacing-5">
        <Button
          className={styles.backButton}
          kind="text"
          icon="icon-left-chevron-outline"
          onClick={() => navigate("/sessions")}
        >
          <span className="ap-font-16">Back to session list</span>
        </Button>
      </div>
      <div className={styles.header}>
        <h1 className={styles.heading}>
          Create a new session
        </h1>
        <div className="flex gap-1">
          <Button kind="text" onClick={() => navigate("/sessions")}>Cancel and exit</Button>
          <Button
            kind="primary"
            onClick={() => handleSave()}
            disabled={!checkIfChanged()}
          >
            Create session
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Panel className="ap-pt-spacing-3 ap-mb-spacing-5">
            <div className="row ap-my-spacing-5">
              <div className="col-6">
                <h2 className={styles.sectionHeading}>Basic information</h2>
              </div>
            </div>
            <div className="row ap-my-spacing-7">
              <div className="col-12">
                {renderInput(
                  "Name of session",
                  (e) => {
                    handleFormChange("sessionName", e);
                    setSessionNameError(undefined);
                  },
                  formData.sessionName,
                  sessionNameError,
                  false,
                  180
                )}
              </div>
            </div>
            <div className="row ap-my-spacing-7">
              <div className="col-12">
                {renderSelect(
                  gamesData,
                  gamesData.find((game) => game.value === formData.selectedGameId)?.value,
                  (e) => {
                    handleFormChange("selectedGameId", e)
                    setGameIdError(undefined);
                  },
                  gameIdError
                )}
                <div className={`ap-field-content`}>
                  {gameIdError && (
                    <div
                      id="errormessage"
                      aria-live="polite"
                      className={`ap-field-email-validation-error ${styles.error}`}
                    >
                      {gameIdError}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row ap-my-spacing-6">
              <div className="col-12">
                <TextArea
                  maxLength={420}
                  value={formData.description}
                  onChange={(e) => handleFormChange("description", e)}
                  title="Additional details"
                />
              </div>
            </div>
          </Panel>
          <Panel className="ap-pt-spacing-3 ap-mb-spacing-5">
            <div className="row ap-my-spacing-5">
              <div className="col-6">
                <h2 className={styles.sectionHeading}>Scheduling</h2>
              </div>
            </div>
            <div className="row gap-2 ap-my-spacing-5">
              <div className="col-12 flex align-center">
                <div className="timepicker-container w-full">
                  <CalendarPicker
                    fieldTitle="Planned date"
                    locale="en"
                    minDate={new Date()}
                    fieldWidth="100%"
                    onChange={(value: any, isValid: boolean) => {
                      handleFormChange("plannedDate", value, isValid)
                    }}
                  />
                </div>
              </div>
              <div className="col-12 flex align-center gap-2">
                <div className={`${styles.w65} timepicker-container`}>
                  <TimePicker
                    className={styles.timePicker}
                    hourTime={12}
                    onChange={(value: any) => {
                      handleFormChange("plannedTime", value)
                    }}
                    fieldTitle="Planned time"
                  />
                </div>
                <Select
                  data={getTimezones()}
                  value={formData.timeZone}
                  onSelect={(e) => {
                    handleFormChange("timeZone", e)
                  }}
                  placeholder="Time zone"
                />
              </div>
            </div>
          </Panel>
          <Panel className="ap-pt-spacing-3 ap-mb-spacing-5">
            <div className="row ap-my-spacing-5">
              <div className="col-6">
                <h2 className={styles.sectionHeading}>Links</h2>
              </div>
            </div>
            <div className="row gap-2 ap-my-spacing-5">
              <div className="col-12 align-center">
              <Input
                type="text"
                maxLength={100}
                className="ap-mb-spacing-4"
                value={formData.googleMeetLink}
                onChange={(value: any) => handleFormChange("googleMeetLink", value)}
                title="Provide Google Meet link"
              />
                <p>The game session link will appear here once the session have been created.</p>
              </div>
            </div>
          </Panel>
        </div>
        <div className="col-6">
          <Panel className="ap-pt-spacing-3 ap-mb-spacing-5">
            <div className="row ap-my-spacing-5">
              <h2 className={styles.sectionHeading}>Manage players</h2>
              <p className="ap-font-weight-2 ap-mb-spacing-2">Add new players to the list</p>
              <p className={styles.paragraphText}>We recommend a maximum of 5 players to ensure a good game dynamic</p>
            </div>
            <div
              className="flex align-start mt-2 ap-mb-spacing-7 gap-2"
              style={{ alignItems: "center" }}
            >
              <Input
                className="col-8"
                type={"text"}
                title={"Type emails"}
                error={inviteEmailError}
                errorNode={
                  <div
                    id="errormessage"
                    aria-live="polite"
                    className="ap-field-email-validation-error"
                  >
                    {inviteEmailError}
                  </div>
                }
                value={inviteEmail}
                onChange={(e) => {
                  setInviteEmail(e);
                  setInviteEmailError(null);
                }}
              ></Input>
              <Button
                style={{ width: "100%", height: "100%" }}
                icon="icon-add-user-outline"
                kind="secondary"
                disabled={inviteEmail.length < 1}
                onClick={() => handleAddPlayer(inviteEmail)}
              >
                Add player
              </Button>
            </div>
            <div className={styles.tableHeader}>
            <p className="ap-font-weight-2">Players</p>
            <div>
              <Button
                kind="text"
                className="ml-auto ap-my-spacing-2"
                icon="icon-copy-outline"
                onClick={() => addAllEmailsToClipboard()}
              >
                Copy emails
              </Button>
            </div>
            </div>
            <Table
              originalData={[...formData.players]}
              hasTitle
              className={`data-table ${styles.playerTable}`}
              style={{ padding: 0 }}
            >
              <Column field="name">Name</Column>
              <Column field="email">Email</Column>
              <Column field="status" renderCell={renderStatus}>
                Status
              </Column>
              <Column field="name" renderCell={renderActions}>
                Actions
              </Column>
            </Table>
            {formData.players?.length === 0 && (
              <div className={`${styles.noSessionContainer}`}>
                <img src="/no-content.png" alt="" />
                <div>No players added yet</div>
              </div>
            )}
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default CreateGameSessionForm

import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../../RoomObject/BaseRoomObject";
import PopupBackground from "../../../ui/PopupBackground";
import { SCREEN_CENTER_X, SCREEN_CENTER_Y } from "@/constants";
import { IQuestion, IActiveQuestionnaire } from "escape-rooms-types/types/game";
import Question from "./Question";
import Introduction from "./Introduction";
import Summary from "./Summary";
import GameScene from "@/phaser/scenes/GameScene";
import QuestionnairePlayer from "../../questionnaire/QuestionnairePlayer";
import PreviewRoomObject from "@/phaser/classes/RoomObject/PreviewRoomObject";
import GameRoomObject from "@/phaser/classes/RoomObject/GameRoomObject";
import EventBridge from "@/utils/EventBridge";
import { PhaserGameClass } from "@/components/phaser-game/PhaserGame";

enum Colors {
  black = 0x000000,
  white = 0xffffff
}

export default class Questionnaire extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly actionId: string;
  private readonly callback: () => void;
  private readonly popupBackground: PopupBackground;
  private readonly timerEvents: Phaser.Time.TimerEvent[] = [];
  private readonly background: Phaser.GameObjects.Graphics;
  private readonly introduction: Introduction;
  private readonly summary: Summary;

  private readonly questions: IQuestion[];

  private readonly WIDTH = 1285;
  private readonly HEIGHT = 670;
  private readonly RADIUS = 0;

  private currentQuestionIndex = 0;
  private questionControl: Question;
  private waitingForOtherPlayers: boolean = false;
  
  constructor(
      scene: UIScene,
      sourceObject: BaseRoomObject,
      actionId: string,
      questions: IQuestion[],
      callback: () => void = () => {},
      activeQuestionnaire?: IActiveQuestionnaire
  ) {
    super(scene, 0, 0);

    this.scene = scene;
    this.sourceObject = sourceObject;
    this.actionId = actionId;
    this.questions = questions;
    this.callback = callback;

    if (activeQuestionnaire && activeQuestionnaire.currentActiveQuestionId) {
      this.currentQuestionIndex = questions.findIndex(
        (q) => q._id == activeQuestionnaire.currentActiveQuestionId
      );
    }

    this.popupBackground = new PopupBackground(scene);
    this.add(this.popupBackground);

    this.background = new Phaser.GameObjects.Graphics(this.scene);
    this.background.fillStyle(Colors.black, 1);
    this.background.fillRoundedRect(
      SCREEN_CENTER_X - this.WIDTH / 2,
      SCREEN_CENTER_Y - this.HEIGHT / 2,
      this.WIDTH,
      this.HEIGHT,
      this.RADIUS);
    this.add(this.background);

    this.introduction = new Introduction(this.scene, this.sourceObject, this.onStart, this.WIDTH, this.HEIGHT);
    this.introduction.setPosition(SCREEN_CENTER_X - this.WIDTH / 2, SCREEN_CENTER_Y - this.HEIGHT / 2);
    this.introduction.setSize(this.WIDTH, this.HEIGHT);
    this.add(this.introduction);

    this.questionControl = new Question(this.scene, this.sourceObject, this.onValidAnswerSelected, this.onInvalidAnswerSelected);
    this.questionControl.setPosition(SCREEN_CENTER_X - this.WIDTH / 2, SCREEN_CENTER_Y - this.HEIGHT / 2)

    this.summary = new Summary(this.scene, this.sourceObject, this.onComplete, this.WIDTH, this.HEIGHT);
    this.summary.setPosition(SCREEN_CENTER_X - this.WIDTH / 2, SCREEN_CENTER_Y - this.HEIGHT / 2);
    this.summary.setSize(this.WIDTH, this.HEIGHT);
    this.summary.setVisible(false);
    this.add(this.summary);
    
    this.scene.add.existing(this);

    this.addEventListeners();
  }

  private addEventListeners() {
    if (this.scene.sourceScene instanceof GameScene) {
      const scene = this.scene.sourceScene as GameScene;

      scene.game.socket.on("markUserAsDone", (payload) => {
        this.rerenderPlayers();
        const playersDone = payload.playersDone;
        Object.entries(playersDone).forEach(([playerId, isDone]) => {
          if (isDone) {
            this.markPlayerAsFinished(this.players, playerId);
          }
        });
      }); 

      scene.game.socket.on("leaveSession", async (payload) => {
        await scene.session!.refetchSession();
      });

      scene.game.socket.on("joinSession", async (payload) => {
        await scene.session!.refetchSession();
      });

      EventBridge.on("session.refetched", () => {
        this.rerenderPlayers();
      });

      scene.game.socket.once("questionnaireFinished", (payload) => {
        if (payload.actionId !== this.actionId) {
          return;
        }

        this.removeEventListerners();
        this.summary.markAllAsFinished();

        setTimeout(() => {
          this.onComplete();
        }, 2000);
      });
    }
  }

  private onInvalidAnswerSelected = () => {
    if (this.scene.sourceScene instanceof GameScene == false) {
      return;
    }

    const gameScene = this.scene.sourceScene as GameScene;
    if (!gameScene) {
      return;
    }

    gameScene.game.socketEmit("incorrectPlayerAnswer", {
      sessionId: gameScene.session!.id,
      playerId: gameScene.user!.id,
    });
  };

  private onValidAnswerSelected = () => {
    this.questionControl.disableInteractive();

    if (this.scene.env === "game") {
      const gameScene = this.scene.sourceScene as GameScene;

      (this.scene.sourceScene as GameScene).game.socketEmit("markUserAsDone", {
        sessionId: gameScene.session!.id,
        questionId: this.questions[this.currentQuestionIndex]._id,
        roomId: gameScene.session!.activeRoomId,
        roomObjectId: this.sourceObject._id,
        actionId: this.actionId,
      });
    }

    const timerEvent = this.scene.time.delayedCall(1500, () => {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
        this.displayActiveQuestion();
      } else {
        this.displayCompleteScreen();
      }
    });

    this.timerEvents.push(timerEvent);
  }

  private onStart = () => {
    this.introduction.destroy();

    this.add(this.questionControl);
    this.displayActiveQuestion();
  }

  private onComplete = () => {
    this.removeEventListerners();

    if (this.scene.env === "preview") {
      (this.sourceObject as PreviewRoomObject).incrementProgress();
      (this.sourceObject as PreviewRoomObject).completeAction();
    } else {
      //(this.sourceObject as GameRoomObject).incrementProgress();
      (this.sourceObject as GameRoomObject).completeAction(this.scene.sourceScene.user.id!);
    }

    this.scene.activeQuestionnaire = undefined;
    
    this.destroy();
  }

  private displayActiveQuestion() {
    this.questionControl.setQuestion(this.questions[this.currentQuestionIndex], this.currentQuestionIndex, this.questions.length)
  }

  private displayCompleteScreen() {
    this.questionControl.visible = false;
    this.summary.visible = true;

    if (this.scene.env === "game") {
      return;
    } else if (this.scene.env === "preview") {
      setTimeout(() => {
        this.summary.markAllAsFinished();
        this.onComplete();
      }, 1500);
      return;
    }
  }

  private markPlayerAsFinished(players: QuestionnairePlayer[], playerId: string) {
    // Mark all as finished for now
    const player = players.find((p) => p.id === playerId);
    if (!player) {
      console.error("Player not found");
      return;
    }

    player.markAsFinished();
  }

  private markAllAsUnfinished(players: QuestionnairePlayer[]) {
    players.forEach((player) => player.markAsUnfinished());
  }

  private removeEventListerners() {
    if (this.scene.sourceScene instanceof GameScene) {
      const gameScene = this.scene.sourceScene as GameScene;
      const game = gameScene.game as PhaserGameClass;
      
      game.socket.off("markUserAsDone");
      game.socket.off("incorrectPlayerAnswer");
      game.socket.off("nextQuestion");
      game.socket.off("questionnaireFinished");
      
      EventBridge.remove("session.refetched");
    }
  }

  private rerenderPlayers() {
    this.renderPlayers();
  }

  private renderPlayers() {
    this.summary.rerenderPlayers();
  }
}
import { Button, Modal, Panel, Steppers, Stepper, Badge, Notification, toaster } from "@appkit4/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./GameTemplate.module.scss";
import { useState, useEffect } from "react";
import { questions, durations } from "@/constants";

const GameTemplate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { stepperActiveIndex, selectedTheme, selectedQs, selectedDs, rooms, puzzles } = location.state;
    const [showGameCreationModal, setShowGameCreationModal] = useState(false);
    const [showAdvancedModeModal, setShowAdvancedModeModal] = useState(false);
    const [showDurationPanel, setShowDurationPanel] = useState(location.state?.showDurationPanel ?? false);
    const [firstPanelButtonsVisible, setFirstPanelButtonsVisible] = useState(location.state?.firstPanelButtonsVisible ?? true);
    const [selectedOptions, setSelectedOptions] = useState({
        question: selectedQs,
        duration: selectedDs,
        questionsCount: 0
    });

    useEffect(() => {
        setSelectedOptions({
            question: selectedQs,
            duration: selectedDs,
            questionsCount: 0
        });
    }, [selectedQs, selectedDs]);

    const noDurationButtonError = (
        <Notification
            title='Error: No Game Duration Selected'
            message={(
                <div>
                    You have not selected a game duration. <br />
                    Please select a game duration to continue.
                </div>
            )}
            status='error'
        />
    );

    const handleBack = () => {
        navigate(`/games/create`, {
            state: {
                ...location.state,
                stepperActiveIndex: 0,
                selectedTheme: selectedTheme,
                selectedQs: selectedOptions.question,
                selectedDs: selectedOptions.duration,
                selectedRooms: rooms,
                selectedPuzzles: puzzles,
                showDurationPanel: !!selectedOptions.question,
                firstPanelButtonsVisible: !selectedOptions.question
            }
        });
    };

    const handleClose = () => {
        console.log('Closed!')
    }

    const handleOptionClick = (optionType: 'question' | 'duration', optionName: string) => {
        setSelectedOptions(prevState => ({
            ...prevState,
            [optionType]: optionType === 'duration' && prevState[optionType] === optionName ? "" : optionName,
            questionsCount: optionType === 'question' ? (optionName === "I don't know" ? 0 : parseInt(optionName.split('-')[1])) : prevState.questionsCount
        }));
        setShowDurationPanel(true);
        setFirstPanelButtonsVisible(false);
    };

    const findRoomsAndPuzzles = (durationName: string) => {
        const duration = durations.find(d => d.name === durationName);
        if (duration) {
            const { rooms, puzzles } = duration;
            return { rooms, puzzles };
        }
        return { rooms: "", puzzles: "" };
    };

    return (
        <div className="ap-container">
            <div className="row">
                <h1 className="ap-typography-4 ap-font-weight-1 ap-mt-spacing-6 ap-mb-spacing-6">
                    Create a new game
                </h1>
                <div className={`col-2 ${styles.colorContainer}`}>
                    <Steppers
                        orientation="vertical"
                        readonly
                        space={40}
                        activeIndex={stepperActiveIndex}
                    >
                        <Stepper label="Theme" status="normal"></Stepper>
                        <Stepper label="Template set up" status="normal"></Stepper>
                        <Stepper label="Basic Information" status="normal"></Stepper>
                    </Steppers>
                </div>
                <div className="col-8">
                    <Panel className="ap-pt-spacing-3 ap-mb-spacing-6">
                        <div className="row ap-my-spacing-6">
                            <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">
                                How many training questions do you plan to ask?
                            </h1>
                            <h2 className="ap-typography ap-font-weight-1 ap-mt-spacing-4 ap-mb-spacing-6">
                                This will help us recommend a game length.
                            </h2>
                            <div>
                                <div className={styles.themeButtonsContainer}>
                                    {questions.map((question) => (
                                        <Button
                                            key={question.name}
                                            className={`${styles.themeButtons} ${
                                                question.name === selectedOptions.question ? "clicked" : ""
                                                }`}
                                            kind="secondary"
                                            onClick={() => handleOptionClick('question', question.name)}
                                            style={{
                                                boxShadow: "none",
                                                border:
                                                    question.name === selectedOptions.question
                                                        ? "1px solid #D04A02"
                                                        : "1px solid #DDDDDD",
                                                borderRight:
                                                    question.name === selectedOptions.question
                                                        ? "3px solid #D04A02"
                                                        : "1px solid #DDDDDD",
                                                color:
                                                    question.name === selectedOptions.question ? "#D04A02" : "#474747",
                                            }}
                                        >
                                            <span className={styles.name}>{question.name}</span>
                                            {question.name === selectedOptions.question && (
                                                <span className={`${styles.checkmarkIcon}`}>
                                                    <span
                                                        className="Appkit4-icon icon-circle-checkmark-fill ap-font-16"
                                                        aria-hidden="true"
                                                    ></span>
                                                </span>
                                            )}
                                            <br />
                                            <span style={{
                                                color:
                                                    question.description === selectedOptions.question ? "#D04A02" : "#474747"
                                            }}
                                                className={styles.description}>{question.description}
                                            </span>
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {firstPanelButtonsVisible && (
                                <Button
                                    kind="secondary"
                                    style={{ width: "100px" }}
                                    onClick={handleBack}
                                >
                                    Back
                                </Button>
                            )}
                        </div>
                    </Panel>
                    {showDurationPanel && (<Panel className="ap-pt-spacing-3 ap-mb-spacing-6">
                        <div className="row ap-my-spacing-6">
                            <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">
                                How long would you like the overall experience to be?
                            </h1>
                            <h2 className="ap-typography ap-font-weight-1 ap-mt-spacing-4 ap-mb-spacing-6">
                                Options are recommended based on the number of questions you are planning to ask.
                            </h2>
                            <div>
                                <div className={styles.themeButtonsContainer}>
                                    {durations.map((duration) => (
                                        <Button
                                            key={duration.name}
                                            className={`${styles.themeButtons} ${
                                                duration.name === selectedOptions.duration ? "clicked" : ""
                                                }`}
                                            kind="secondary"
                                            onClick={() => handleOptionClick('duration', duration.name)}
                                            style={{
                                                boxShadow: "none",
                                                border:
                                                    duration.name === selectedOptions.duration
                                                        ? "1px solid #D04A02"
                                                        : "1px solid #DDDDDD",
                                                borderRight:
                                                    duration.name === selectedOptions.duration
                                                        ? "4px solid #D04A02"
                                                        : "1px solid #DDDDDD",
                                                color:
                                                    duration.name === selectedOptions.duration ? "#D04A02" : "#474747",
                                            }}
                                        >
                                            <span className={styles.name}>{duration.name}</span>
                                            <span style={{
                                                color:
                                                    duration.rooms === selectedOptions.duration ? "#D04A02" : "#474747"
                                            }}
                                                className={styles.rooms}>{duration.rooms}</span>
                                            {duration.name === selectedOptions.duration && (
                                                <span className={`${styles.checkmarkIcon}`}>
                                                    <span
                                                        className="Appkit4-icon icon-circle-checkmark-fill ap-font-16"
                                                        aria-hidden="true"
                                                    ></span>
                                                </span>
                                            )}
                                            <br />
                                            <span style={{
                                                color: duration.description === selectedOptions.duration ? "#D04A02" : "#474747"
                                            }}
                                                className={styles.description}>{duration.description}</span>
                                            {selectedOptions.questionsCount >= duration.recommendedFor[0] && selectedOptions.questionsCount
                                                <= duration.recommendedFor[duration.recommendedFor.length - 1] && (
                                                    <div className={styles.badgeContainer}>
                                                        <Badge
                                                            className={styles.badge}
                                                            value={"Recommended"}
                                                            type={"primary-outlined"}
                                                        />
                                                    </div>
                                                )}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button
                                kind="secondary"
                                style={{ width: "100px" }}
                                onClick={handleBack}
                            >
                                Back
                            </Button>
                            <Button
                                kind="primary"
                                style={{ width: "100px" }}
                                className="col-6"
                                onClick={() => {
                                    if (selectedOptions.duration) {
                                        const { rooms, puzzles } = findRoomsAndPuzzles(selectedOptions.duration);
                                        navigate("/games/basicinformation", {
                                            state: {
                                                ...location.state,
                                                stepperActiveIndex: 2,
                                                selectedTheme: selectedTheme,
                                                selectedQs: selectedOptions.question,
                                                selectedDs: selectedOptions.duration,
                                                selectedRooms: rooms,
                                                selectedPuzzles: puzzles,
                                            }
                                        });
                                    } else {
                                        toaster.notify(noDurationButtonError, { position: 'center', onClose: handleClose })
                                    }
                                }}
                            >
                                Continue
                            </Button>
                        </div>
                    </Panel>)}
                </div>
            </div>
            <Modal
                visible={showAdvancedModeModal}
                title={"Switch to Advanced Mode?"}
                ariaLabel={"Switch to Advanced Mode?"}
                onCancel={() => setShowAdvancedModeModal(false)}
                modalStyle={{ width: "33.75rem" }}
                footerStyle={{
                    paddingTop: "8px",
                    marginTop: "-8px",
                    minHeight: "64px",
                }}
                header={""}
                icons={""}
                footer={
                    <>
                        <Button
                            onClick={() => {
                                setShowAdvancedModeModal(false);
                            }}
                            kind="secondary"
                        >
                            Go to Advanced Mode
                        </Button>
                        <Button
                            onClick={() => {
                                setShowAdvancedModeModal(false);
                            }}
                        >
                            Continue with template
                        </Button>
                    </>
                }
                bodyStyle={{ minHeight: "92px" }}
            >
                <p>
                    You are about to switch to Advanced Mode. This action is irreversible.
                    <br />
                    <br />
                    In Advanced Mode, you'll be responsible for creating and configuring
                    each room and puzzle manually.
                    <br />
                    <br />
                    Please be aware, you cannot return to the templated version once you
                    proceed. Ensure you are ready for this level of customisation and
                    control.
                    <br />
                </p>
            </Modal>
            <Modal
                visible={showGameCreationModal}
                title={"Are you sure you want to leave?"}
                ariaLabel={"Are you sure you want to leave?"}
                onCancel={() => setShowGameCreationModal(false)}
                modalStyle={{ width: "33.75rem" }}
                footerStyle={{
                    paddingTop: "8px",
                    marginTop: "-8px",
                    minHeight: "64px",
                }}
                header={""}
                icons={""}
                footer={
                    <>
                        <Button
                            onClick={() => {
                                navigate("/");
                                setShowGameCreationModal(false);
                            }}
                            kind="secondary"
                        >
                            Leave page
                        </Button>
                        <Button onClick={() => setShowGameCreationModal(false)}>
                            Stay and continue
                        </Button>
                    </>
                }
                bodyStyle={{ minHeight: "92px" }}
            >
                <p>
                    If you leave this page now, any selections you've made will be lost.
                    Would you like to stay on this page and continue your progress?
                </p>
            </Modal>
        </div>
    );
};

export default GameTemplate;

import UIScene from "@/phaser/scenes/UIScene";
import BaseRoomObject from "../../RoomObject/BaseRoomObject";
import CloseButton from "../../ui/CloseButton";
import TVScreen from "../../ui/TVScreen";
import PreviewRoomObject from "../../RoomObject/PreviewRoomObject";
import GameRoomObject from "../../RoomObject/GameRoomObject";
import HintBar from "../../ui/HintBar";
import EventBridge from "@/utils/EventBridge";


export default class Jigsaw extends Phaser.GameObjects.Container {
  public readonly scene: UIScene;
  private readonly sourceObject: BaseRoomObject;
  private readonly callback: () => void;
  private readonly cards: Array<Phaser.GameObjects.Plane> = []; 
  private readonly images: Array<Phaser.GameObjects.Image> = [];
  private readonly textObject: Phaser.GameObjects.Text;
  private readonly initialFrameNames: Array<string> = [];
  private readonly frameNames: Array<string> = [];
  private readonly arrowUp: Phaser.GameObjects.Sprite;
  private readonly arrowDown: Phaser.GameObjects.Sprite;
  private readonly arrowRight: Phaser.GameObjects.Sprite;
  private readonly arrowLeft: Phaser.GameObjects.Sprite;
  private readonly hintBar: HintBar;
  private readonly timerEvents: Phaser.Time.TimerEvent[] = [];

  private readonly HINT_MESSAGE: string = "Could it be the dark blue numbers?";
  
  private readonly gridConfiguration = {
    x: 0,
    y: 0,
    paddingX: 0,
    paddingY: 0,
    cardWidth: 159, 
    cardHeight: 200,
    numberOfItems: 8,
  }
  
  constructor(
    scene: UIScene,
    text: string,
    sourceObject: BaseRoomObject,
    callback: () => void = () => {}
  ) {
    super(scene, 0, 0);
    this.scene = scene;
    this.sourceObject = sourceObject;
    this.callback = callback;

    const tvScreen = new TVScreen(this.scene);
    this.add(tvScreen);

    this.hintBar = new HintBar(scene, this.HINT_MESSAGE);
    this.add(this.hintBar);

    const closeButton = new CloseButton(this.scene, () => this.closeModal());
    this.add(closeButton);

    this.textObject = new Phaser.GameObjects.Text(
      scene, 
      scene.scale.width / 2, 
      150, 
      "What's the code?", 
      {
        fontFamily: "Arial",
        fontSize: "26px",
        color: "#ffffff",
        align: "center",
      })
      .setLineSpacing(8)
      .setOrigin(0.5, 0.5);

    this.add(this.textObject);

    this.gridConfiguration.x = this.scene.scale.width / 2 - (this.gridConfiguration.cardWidth * 1.5);
    this.gridConfiguration.y = this.scene.scale.height / 2 - this.gridConfiguration.cardHeight / 2;
    this.initialFrameNames = scene.textures.get('jigsawAtlas').getFrameNames().sort();
    this.frameNames = this.getFramesInRandomOrder([...this.initialFrameNames]);

    this.arrowDown = new Phaser.GameObjects.Sprite(this.scene, 100, 100, 'arrow').setRotation(Phaser.Math.DegToRad(0));
    this.arrowUp = new Phaser.GameObjects.Sprite(this.scene, 200, 100, 'arrow').setRotation(Phaser.Math.DegToRad(180));
    this.arrowRight = new Phaser.GameObjects.Sprite(this.scene, 300, 100, 'arrow').setRotation(Phaser.Math.DegToRad(-90));
    this.arrowLeft = new Phaser.GameObjects.Sprite(this.scene, 400, 100, 'arrow').setRotation(Phaser.Math.DegToRad(90));
    
    this.initializeImages();
    this.initializeArrows();
    
    this.scene.add.existing(this);
  }

  private initializeImages() {
    for (let i = 0; i < this.gridConfiguration.numberOfItems; i++) {
      const x = this.gridConfiguration.x + (this.gridConfiguration.cardWidth + this.gridConfiguration.paddingX) * (i % 4);
      const y = this.gridConfiguration.y + (this.gridConfiguration.cardHeight + this.gridConfiguration.paddingY) * Math.floor(i / 4);

      const frameName = this.frameNames[i];
      
      const image = new Phaser.GameObjects.Image(
        this.scene, 
        x, 
        y, 
        'jigsawAtlas',
        frameName
      )
      .setName(frameName)
      .setInteractive();
  
      this.add(image);
      this.images.push(image);

      image.on(Phaser.Input.Events.POINTER_OVER, () => {
        this.arrowDown.setPosition(image.x, image.y + image.height / 3);
        this.arrowUp.setPosition(image.x, image.y - image.height / 3);
        this.arrowRight.setPosition(image.x + image.width / 3, image.y);
        this.arrowLeft.setPosition(image.x - image.width / 3, image.y);
        
        this.arrowDown.setVisible(true).setName(image.name);
        this.arrowUp.setVisible(true).setName(image.name);
        this.arrowRight.setVisible(true).setName(image.name);
        this.arrowLeft.setVisible(true).setName(image.name);

        const index = this.frameNames.indexOf(image.name);

        if (index < 4) {
          this.arrowUp.setVisible(false);
        }
        else {
          this.arrowDown.setVisible(false);
        }

        if (index === 0 || index === 4) {
          this.arrowLeft.setVisible(false);
        }

        if (index === 3 || index === 7) {
          this.arrowRight.setVisible(false);
        }
      });
    }
  }
  
  private initializeArrows() {  
    this.arrowDown.setVisible(false).setInteractive().setDepth(-10);
    this.arrowUp.setVisible(false).setInteractive().setDepth(-10);
    this.arrowLeft.setVisible(false).setInteractive().setDepth(10);
    this.arrowRight.setVisible(false).setInteractive().setDepth(10);

    this.arrowUp.on(Phaser.Input.Events.POINTER_DOWN, () => {
      const currentIndex = this.frameNames.indexOf(this.arrowUp.name);
      const newIndex = (currentIndex + 4) % this.gridConfiguration.numberOfItems;

      this.moveItems(currentIndex, newIndex);
    });

    this.arrowDown.on(Phaser.Input.Events.POINTER_DOWN, () => {
      const currentIndex = this.frameNames.indexOf(this.arrowUp.name);
      const newIndex = (currentIndex + 4) % this.gridConfiguration.numberOfItems;

      this.moveItems(currentIndex, newIndex);
    });

    this.arrowRight.on(Phaser.Input.Events.POINTER_DOWN, () => {
      const currentIndex = this.frameNames.indexOf(this.arrowUp.name);
      const newIndex = currentIndex + 1;

      this.moveItems(currentIndex, newIndex);
    });

    this.arrowLeft.on(Phaser.Input.Events.POINTER_DOWN, () => {
      const currentIndex = this.frameNames.indexOf(this.arrowUp.name);
      const newIndex = currentIndex - 1;

      this.moveItems(currentIndex, newIndex);
    });

    this.add(this.arrowDown);
    this.add(this.arrowUp);
    this.add(this.arrowLeft);
    this.add(this.arrowRight);
  }

  private moveItems(currentIndex: number, newIndex: number) {
    const temp = this.frameNames[currentIndex];
    this.frameNames[currentIndex] = this.frameNames[newIndex];
    this.frameNames[newIndex] = temp;

    this.refreshGrid();
  }

  private refreshGrid() {
    for (let i = 0; i < this.gridConfiguration.numberOfItems; i++) {
      const frameName = this.frameNames[i];
      const image = this.images.find(img => img.name === frameName);
      const x = this.gridConfiguration.x + (this.gridConfiguration.cardWidth + this.gridConfiguration.paddingX) * (i % 4);
      const y = this.gridConfiguration.y + (this.gridConfiguration.cardHeight + this.gridConfiguration.paddingY) * Math.floor(i / 4);
      image?.setPosition(x, y);
    }
    
    this.checkIfComplete();
  }
  
  private getFramesInRandomOrder(frames: string[]): string[] {
    return Phaser.Utils.Array.Shuffle(frames) 
  }

  private checkIfComplete() {
    for (let i = 0; i < this.initialFrameNames.length; i++) {
      if (this.initialFrameNames[i] !== this.frameNames[i]) {
          return; 
      }
    }
    
    this.textObject.setText("You've got it!"); 

    for (let i = 0; i < this.images.length; i++) {
      this.images[i].removeInteractive();
    }

    this.arrowUp.destroy();
    this.arrowDown.destroy();
    this.arrowRight.destroy();
    this.arrowLeft.destroy();

    this.complete();
  }

  private complete() {
    this.scene.hud.setHintButtonVisible(false);
    const timerEvent = this.scene.time.delayedCall(2000, this.completeAction);
    this.timerEvents.push(timerEvent);
  }

  private completeAction = () => {
    if (this.scene.env === "preview") {
      (this.sourceObject as PreviewRoomObject).incrementProgress();
      (this.sourceObject as PreviewRoomObject).completeAction();
    } else {
      //(this.sourceObject as GameRoomObject).incrementProgress();
      EventBridge.emit("game.puzzleEnded");
      (this.sourceObject as GameRoomObject).completeAction(this.scene.sourceScene.user.id!);
    }

    this.closeModal(false);
  }

  private closeModal(hideHintButton: boolean = true) {
    if (hideHintButton) {
      this.scene.hud.setHintButtonVisible(false);
    }

    this.scene.makeGameSceneInteractive();
    this.scene.time.removeEvent(this.timerEvents);
    this.cards.forEach(card => card.destroy());
    this.destroy();
  }
}

import React from "react";
import {
  Button,
  Tooltip,
  Modal,
  TextArea,
  Upload,
  Switch,
  Input,
  Notification,
  toaster,
} from "@appkit4/react-components";
import styles from "../game-mission/GameMission.module.scss";
import { useState, useEffect } from "react";
import { useModal } from "./UnsavedChangesContext";
import { Room1PlaceHolderContent } from "@/constants";
import { useGameStore } from "@/store/game-store";

type Props = {};

const Room1 = (props: Props) => {
  const theme = useGameStore((state) => state.game.theme);
  console.log("game state theme", theme);
  const thisRoom1 = Room1PlaceHolderContent.find(
    (content) => content.themeName === theme
  ) || Room1PlaceHolderContent[0]; //Temporary Fallback
  const formatListItem = (text) => {
    const [boldPart, ...rest] = text.split(":");
    return (
      <>
        <strong>{boldPart}:</strong>
        {rest.join(":")}
      </>
    );
  };
  const [headerValue, setHeaderValue] = useState(
    "Welcome to your interactive game!"
  );
  const [introValue, setIntroValue] = useState(
    "You're about to embark on an exciting journey filled with challenges testing your wits and your knowledge of the training program.\nGet ready to explore, solve puzzles, and collaborate with your team. Let's make this an unforgettable adventure!"
  );
  const modalContext = useModal();
  const isModalOpen = modalContext?.isModalOpen || false;
  const showModal = modalContext?.showModal || (() => {});
  const hideModal = modalContext?.hideModal || (() => {});
  const [isIntroActive, setIsIntroActive] = useState(true);

  const savedIntroActive = (
    <Notification
      title=""
      message={<div>Your introduction has been added into the game</div>}
      status="success"
    ></Notification>
  );

  const savedIntroInactive = (
    <Notification
      title=""
      message={<div>Your introduction has been removed from the game</div>}
      status="success"
    ></Notification>
  );

  const onUploadChange = (file: File, fileList: FileList): void => {
    console.log("onChange", file, fileList);
    modalContext?.setIsChanged(true);
    console.log("game editor is changed: ", modalContext?.isChanged);
  };
  const uploadFiles = (fileList: any) => {
    console.log(fileList);
  };

  const handleSavedClose = () => {
    console.log("Closed!");
  };

  const handleSaveClick = () => {
    if (isIntroActive) {
      toaster.notify(savedIntroActive, {
        position: "topHeader",
        onClose: handleSavedClose,
      });
    } else {
      toaster.notify(savedIntroInactive, {
        position: "topHeader",
        onClose: handleSavedClose,
      });
    }
  };

  const onHeaderChange = (value: any, event: any) => {
    setHeaderValue(value);
    modalContext?.setIsChanged(true);
    console.log("game editor is changed: ", modalContext?.isChanged);
  };

  const onIntroChange = (value: any, event: any) => {
    setIntroValue(value);
    modalContext?.setIsChanged(true);
  };

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={styles.headerContainer} style={{ width: "940px" }}>
          <h1 className="ap-typography ap-font-weight-2 ap-mt-spacing-4 ap-mb-spacing-4">
            {thisRoom1.title}
          </h1>
          <Button
            onClick={handleSaveClick}
            kind="primary"
            className={styles.saveButton}
          >
            Save changes
          </Button>
        </div>
        <div className="ap-typography-body" style={{ width: "940px" }}>
          <span>{`${thisRoom1.puzzles} puzzles`}</span>
          <span> • </span>
          <span>{`${thisRoom1.minutes} min`}</span>
          <span> • </span>
          <span>{`${thisRoom1.tQuestions} training questions`}</span>
        </div>
        <div className={styles.divider} style={{ width: "940px" }}></div>
        <p
          className="ap-typography-body"
          style={{ marginBottom: "20px", width: "940px" }}
        >
          {thisRoom1.Room1Description}
        </p>
        <ol className="ap-typography-body">
          {thisRoom1.Room1Bullets.map((bullet: any, index: any) =>
            <li key={index}>{formatListItem(bullet)}</li>
          )}
        </ol>
        <Tooltip
          trigger="hover"
          position="top-left"
          distance={4}
          id="tooltipDesc"
          appendAfterTarget={true}
          content="Toggle ON to include an intro for the context; OFF for a direct game start. Intro content is retained, simply hidden from view."
        ></Tooltip>
      </div>
    </>
  );
};

export default Room1;

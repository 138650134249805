import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import {useNavigate} from "react-router-dom";
import { Modal, Button } from "@appkit4/react-components";

interface ModalContextType {
    isModalOpen: boolean;
    showModal: () => void;
    hideModal: () => void;
    setConfirmedAction: (action: boolean) => void;
    confirmedAction: boolean;
    confirmedRoute: string; 
    setConfirmedRoute: React.Dispatch<React.SetStateAction<string>>; 
    isChanged: boolean; 
    setIsChanged: React.Dispatch<React.SetStateAction<boolean>>;
    
}

interface ModalProviderProps {
    children: ReactNode;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider = ({ children }: ModalProviderProps) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [confirmedAction, setConfirmedAction] = useState(false);
    const [confirmedRoute, setConfirmedRoute] = useState("");
    const [isChanged, setIsChanged] = useState(false); 
    const navigate = useNavigate();
    const showModal = () => {
        setModalOpen(true);
        setConfirmedAction(false);
    };

    const hideModal = () => {
        setModalOpen(false);
    };

    return (
        <ModalContext.Provider value={{ isModalOpen, showModal, hideModal, confirmedRoute, setConfirmedRoute, confirmedAction, setConfirmedAction, isChanged, setIsChanged }}>
            {children}
        
            <Modal
                visible={isModalOpen}
                title={"Unsaved changes"}
                ariaLabel={"Unsaved changes"}
                onCancel={hideModal}
                modalStyle={{ width: "33.75rem" }}
                footerStyle={{
                    paddingTop: "8px",
                    marginTop: "-8px",
                    minHeight: "64px",
                }}
                header={""}
                icons={""}
                footer={
                    <>
                        <Button
                            onClick={() => {
                                navigate(confirmedRoute);
                                hideModal();
                                setIsChanged(false); 
                            }}
                            kind="secondary"
                        >
                            Discard changes and exit
                        </Button>
                        <Button
                            onClick={() => {
                                navigate(confirmedRoute);
                                hideModal();
                                setIsChanged(false); 
                            }}
                        >
                            Save and exit
                        </Button>
                    </>
                }
                bodyStyle={{ minHeight: "92px" }}
            >
                <p>
                    You have unsaved changes in this section.
                    <br />
                    <br />
                    If you move to another section now, those changes will be lost.
                    <br />
                    <br />
                    Would you like to save your changes before leaving?
                    <br />
                </p>
            </Modal>
      
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext);

import {
  IMediaPayload,
  IModal,
  IQuestionnaire,
  IRoomObject,
  IRoomObjectPropertyUpdate,
  IStateBasedPayload,
} from "escape-rooms-types/types/game";
import PreviewRoomObject from "../RoomObject/PreviewRoomObject";
import EventBridge from "@/utils/EventBridge";
import PreviewScene from "@/phaser/scenes/PreviewScene";
import GameScene from "@/phaser/scenes/GameScene";
import GameRoomObject from "../RoomObject/GameRoomObject";

export default class BaseAction {
  scene: GameScene | PreviewScene;
  sourceObject: GameRoomObject | PreviewRoomObject;
  score: number;
  id: string;
  ref: string;
  trigger: "onClick" | "afterPrevious" | "onItemUse";
  type:
    | "changeSelf"
    | "changeOther"
    | "displayImage"
    | "displayImageByState"
    | "displayUnlockScreen"
    | "displayPuzzle"
    | "displayMessage"
    | "displayMessageTooltip"
    | "displayMedia"
    | "displayQuestionnaire"
    | "displayChallenge"
    | "addToInventory"
    | "endRoom"
    | "endGame";
  changeObjectPayload?: IRoomObjectPropertyUpdate;
  modalPayload?: IModal;
  questionnaire?: IQuestionnaire;
  triggerItemRef?: string;
  mediaPayload?: IMediaPayload;
  repeatable?: boolean;
  stateBasedPayload?: IStateBasedPayload

  constructor(
    scene: GameScene | PreviewScene,
    sourceObject: GameRoomObject | PreviewRoomObject,
    score: number,
    id: string,
    ref: string,
    trigger: "onClick" | "afterPrevious" | "onItemUse",
    type:
      | "changeSelf"
      | "changeOther"
      | "displayImage"
      | "displayImageByState"
      | "displayUnlockScreen"
      | "displayPuzzle"
      | "displayMessage"
      | "displayMessageTooltip"
      | "displayMedia"
      | "displayQuestionnaire"
      | "displayChallenge"
      | "addToInventory"
      | "endRoom"
      | "endGame",
    changeObjectPayload?: IRoomObjectPropertyUpdate,
    modalPayload?: IModal,
    questionnaire?: IQuestionnaire,
    triggerItemRef?: string,
    mediaPayload?: IMediaPayload,
    repeatable?: boolean,
    stateBasedPayload?: IStateBasedPayload,
  ) {
    this.scene = scene;
    this.sourceObject = sourceObject;
    this.id = id;
    this.ref = ref;
    this.score = score;
    this.trigger = trigger;
    this.type = type;
    this.changeObjectPayload = changeObjectPayload;
    this.modalPayload = modalPayload;
    this.questionnaire = questionnaire;
    this.triggerItemRef = triggerItemRef;
    this.mediaPayload = mediaPayload;
    this.repeatable = repeatable;
    this.stateBasedPayload = stateBasedPayload;
  }

  updateObjectProperties(targetObjectRef: string) {
    if (this.changeObjectPayload == null) {
      console.error("oops");
      return;
    }

    const targetObject = this.scene.roomObjects[targetObjectRef];
    if (targetObject == null) {
      console.error(
        `We cannot change the state of ${targetObjectRef} becuase it does not exist in the game anymore. Did you delete it?`
      );
      return;
    }

    const newObject: IRoomObject = {
      ...this.scene.roomObjects[targetObjectRef].gameObject,
    };

    if (this.changeObjectPayload.newProperties.currentState != null) {
      newObject.currentState =
        this.changeObjectPayload.newProperties.currentState;
    }
    if (this.changeObjectPayload.newProperties.isVisible != null) {
      newObject.isVisible = this.changeObjectPayload.newProperties.isVisible;
    }
    if (this.changeObjectPayload.newProperties.isDisabled != null) {
      newObject.isDisabled = this.changeObjectPayload.newProperties.isDisabled;
    }

    this.scene.roomObjects[
      this.changeObjectPayload?.targetObjectRef!
    ].updateObject(newObject as any);
  }
}
